import { Pagination, Popconfirm, Table, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { handleFieldError } from "../../../Utils/Common";
import { StyledButton } from "../../ContactUs/Components/ContactUsForm/ContactUsForm.styles";
import { FaRegEdit } from "react-icons/fa";
import { ExpandableRowContainer } from "../Dashboard.styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import AddFAQs from "../../../Common/Modals/AddFAQs/AddFAQs";
import moment from "moment";
import {
  useAddFaqMutation,
  useDeleteFaqMutation,
  useEditFaqMutation,
  useGetAllFaqMutation,
} from "../../../Services/Users";
import { DeleteOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

const FAQs = () => {
  const [form] = useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [faqData, setFAQData] = useState();
  const [paginate, setPaginate] = useState(false);
  const [editId, setEditId] = useState(null);
  const [addFaq] = useAddFaqMutation();
  const [editFaq] = useEditFaqMutation();
  const [deleteFaq] = useDeleteFaqMutation();
  const [getAllFaq] = useGetAllFaqMutation();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditId(null);
  };

  const addFAQsFn = async (values) => {
    try {
      const reqData = {
        id: editId,
        question: values?.question,
        answer: values?.answer,
      };
      await (editId ? editFaq : addFaq)(reqData).unwrap();
      message.success(
        editId ? "FAQ Updated Successfully" : "FAQ Added Successfully"
      );
      handleCancel();
      getAllFAQFn();
      form.resetFields();
    } catch (error) {
      handleFieldError(error);
    }
  };

  const deleteFaqFn = async (id) => {
    try {
      await deleteFaq(id).unwrap();
      message.success("FAQ Deleted Successfully");
      getAllFAQFn();
    } catch (error) {
      handleFieldError(error);
    }
  };
  const columns = [
    {
      title: "Date & Time",
      dataIndex: "creationDate",
      key: "name",
      align: "center",
      width: "20%",
      render: (date) => (
        <div className="name-container">
          {date ? moment(date).format("MM/DD/YYYY HH:MM:SS") : "-"}
        </div>
      ),
    },
    {
      title: "Question",
      dataIndex: "question",
      align: "left",
      render: (question) => (
        <div className="name-container">{question ?? "-"}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: ["countryCode", "status", "id", "description", "name"],
      width: "10%",
      align: "right",
      render: (_, record) => {
        return (
          <div className="action-container">
            <div
              title="Edit Question/Answer"
              className={`editLogo`}
              onClick={(e) => {
                e.stopPropagation();
                setEditId(record?.id);
                showModal();
              }}
            >
              <FaRegEdit fontSize={20} />
            </div>
            <Popconfirm
              title="Delete the faq"
              description="Are you sure to delete this faq?"
              onConfirm={() => deleteFaqFn(record?.id)}
              okText="Yes"
              cancelText="No"
            >
              <div title="Delete FAQ" className={`editLogo`}>
                <DeleteOutlined fontSize={20} />
              </div>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const getAllFAQFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: tableParams?.pagination?.pageSize,
        current: tableParams?.pagination?.current - 1,
      };
      const response = await getAllFaq(reqData).unwrap();
      const data = {
        data: response?.data?.data?.map((items, index) => {
          return { ...items, key: index + 1 };
        }),
        total: response?.data?.total,
      };
      setFAQData(data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response?.data?.total,
        },
      });
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllFaq, paginate]);

  useEffect(() => {
    getAllFAQFn();
  }, [getAllFAQFn]);

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
  };

  return (
    <>
      <div className="button-container">
        <StyledButton onClick={showModal}>Add FAQ</StyledButton>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.key}
        dataSource={faqData?.data}
        onChange={handleTableChange}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => <ExpandableRow data={record} />,
          rowExpandable: () => true,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <IoIosArrowUp
                onClick={(e) => onExpand(record, e)}
                className="expand-icon"
              />
            ) : (
              <IoIosArrowDown
                onClick={(e) => onExpand(record, e)}
                className="expand-icon"
              />
            ),
        }}
        expandRowByClick={false}
      />
      {faqData?.total > 10 && (
        <Pagination
          total={faqData?.total}
          showSizeChanger
          showTotal={(total) => `Total ${total} items`}
          current={tableParams?.pagination?.current}
          pageSize={tableParams?.pagination?.pageSize}
          onChange={(page, pageSize) => {
            setPaginate(!paginate);
            setTableParams({
              pagination: {
                current: page,
                pageSize: pageSize,
              },
            });
          }}
        />
      )}
      <AddFAQs
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={addFAQsFn}
        editId={editId}
        form={form}
      />
    </>
  );
};

const ExpandableRow = ({ data }) => {
  const columns = [
    {
      title: "Answer",
      dataIndex: "answer",
      key: "answer",
      align: "left",
      render: (answer) => <div>{answer}</div>,
    },
  ];
  const tableData = [
    {
      key: data?.key,
      answer: data?.answer,
    },
  ];
  return (
    <ExpandableRowContainer key={data?.key}>
      <Table pagination={false} columns={columns} dataSource={tableData} />
    </ExpandableRowContainer>
  );
};
export default FAQs;
