import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const AboutUsHeaderContainer = styled.div`
  background: url("../Images/pagetitle-bg.jpg") center center/cover;
  padding: 12px 120px;
  /* height: 350px; */
  color: ${(props) => props.theme.colors.white};
  @media (${deviceQuery.tabletM}) {
    padding: 12px 40px;
  }
  @media (${deviceQuery.laptopM}) {
    margin-top: 95px;
  }
  @media (${deviceQuery.mobileM}) {
    padding: 12px 20px;
  }
  .main-about-us-container {
    border-left: 2px solid ${(props) => props.theme.colors.blue};
    padding: 10px;
    h2 {
      font-size: 28px;
      opacity: 1;
      margin: 6px 0;
      @media (${deviceQuery.tabletM}) {
        font-size: 24px;
      }
      @media (${deviceQuery.mobileM}) {
        font-size: 20px;
      }
    }
    .ant-breadcrumb > ol > li > a,
    .ant-breadcrumb > ol > li {
      color: ${(props) => props.theme.colors.white};
      font-weight: 400;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 30px;
      cursor: pointer;
      @media (${deviceQuery.tabletM}) {
        font-size: 14px;
      }
      @media (${deviceQuery.mobileM}) {
        font-size: 12px;
      }
    }
    .ant-breadcrumb > ol > li:last-child {
      font-size: 16px;
      color: ${(props) => props.theme.colors.gray};
    }
  }
`;
