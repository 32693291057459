import React, { useState } from "react";
import { LoginContainer } from "./Login.styles";
import { Divider, Form, Input, message } from "antd";
import { StyledButton } from "../ContactUs/Components/ContactUsForm/ContactUsForm.styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Constants/Routes";
import { handleFieldError } from "../../Utils/Common";
import { setIsLogin, setToken, useLoginApiMutation } from "../../Services/Auth";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

const Login = () => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const [loginResponse, { isLoading }] = useLoginApiMutation();
  const loginFn = async (values) => {
    try {
      const response = await loginResponse(values).unwrap();
      message.success("Logged in successfully");
      dispatch(setToken(response?.data?.token));
      dispatch(setIsLogin(true));
      sessionStorage?.setItem("userName", response?.data?.userName);
      sessionStorage?.setItem("accessToken", response?.data?.token);
      sessionStorage?.setItem("userId", response?.data?.id);
      if (response?.data?.userType === "SUPER_ADMIN") {
        navigate(ROUTES.DASHBOARD);
      } else {
        navigate(ROUTES.ORDERS);
      }
    } catch (error) {
      handleFieldError(error);
    }
  };

  return (
    <LoginContainer>
      <div className="main-container">
        <div className="left-container">
          <div className="image-container">
            <img
              src="../Images/travel_pack.jpg"
              alt="travel"
              className="image"
            />
          </div>
        </div>
        <div className="right-container">
          <div className="card">
            <h3 className="heading">Welcome</h3>
            <h5 className="sub-heading">Sign in to your account</h5>
            <div className="google-login-container"></div>
            <Divider />
            <Form className="form" onFinish={loginFn}>
              <div className="input-container">
                <label>Email</label>
                <Form.Item
                  name="userName"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      message: "Must be valid Email",
                    },
                  ]}
                >
                  <Input type="text" className="input" />
                </Form.Item>
              </div>
              <div className="input-container">
                <label>Password</label>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input
                    type={showPassword ? "text" : "password"}
                    className="input"
                    suffix={
                      showPassword ? (
                        <EyeInvisibleOutlined
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <EyeOutlined
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      )
                    }
                  />
                </Form.Item>
              </div>

              <div
                className="forgot-password"
                onClick={() => navigate(ROUTES.FORGOTPASSWORD)}
              >
                Forgot Password?
              </div>
              <StyledButton htmlType="submit" loading={isLoading}>
                Login
              </StyledButton>
              <div className="no-account">
                Don't have an account?
                <span
                  className="sign-up"
                  onClick={() => navigate(ROUTES.SIGNUP)}
                >
                  Signup Now
                </span>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </LoginContainer>
  );
};

export default Login;
