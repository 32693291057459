import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const DetailedInstructionContainer = styled.div`
  .detailed-instruction-main-container {
    padding: 20px 0;
    display: flex;
    justify-content: space-around;
    gap: 20px;
    max-width: 1170px;
    margin: auto;
    @media (${deviceQuery.laptopM}) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .left-container {
      width: 30%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      @media (${deviceQuery.laptopM}) {
        width: 50%;
      }
      @media (${deviceQuery.mobileSM}) {
        width: 85%;
      }
      .button {
        max-width: 250px;
      }
      .left-container-heading {
        font-weight: 600;
        font-size: 26px;
        line-height: 32px;
        @media (${deviceQuery.mobileSM}) {
          font-size: 22px;
          line-height: 30px;
        }
        color: ${(props) => props.theme.colors.darkBlue2};
        .diff {
          color: ${(props) => props.theme.colors.blue};
        }
      }

      .content {
        text-align: justify;
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
        letter-spacing: 0px;
        color: ${(props) => props.theme.colors.gray};
        margin-bottom: 10px;
        @media (${deviceQuery.mobileSM}) {
          font-size: 14px;
          line-height: 20px;
        }
      }

      .image-container {
        height: 300px;
        width: 300px;
        .image {
          height: 100%;
          width: 100%;
        }
      }
    }
    .right-container {
      /* width: 70%; */
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      place-items: center;
      @media (${deviceQuery.tabletMS}) {
        grid-template-columns: repeat(1, 1fr);
      }
      .card {
        height: 280px;
        width: 320px;
        background: ${(props) => props.theme.colors.white};
        box-shadow: ${(props) => props.theme.shadow.cardShadow};
        border-radius: 14px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 10px;
        .image {
          height: 50px;
          width: 50px;
          margin-bottom: 20px;
          margin-left: 30px;
        }
        .card-heading {
          color: ${(props) => props.theme.colors.darkBlue2};
          font-size: 16px;
          font-weight: 600;
          padding-left: 22px;
        }
        ul {
          color: ${(props) => props.theme.colors.gray3};
          font-size: 14px;
          margin-top: 0px;
          li {
            margin-top: 8px;
          }
        }
      }
    }
  }
`;
