import { Input, Pagination, Select, Table, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { handleFieldError } from "../../../Utils/Common";
import {
  useAddCountryMutation,
  useEditCountryMutation,
  useGetAllCountriesMutation,
} from "../../../Services/Users";
import { StyledButton } from "../../ContactUs/Components/ContactUsForm/ContactUsForm.styles";
import AddCountry from "../../../Common/Modals/AddCountry/AddCountry";
import { getCountries } from "react-phone-number-input";
import en from "react-phone-number-input/locale/en.json";
import { FaRegEdit, FaSave } from "react-icons/fa";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { Country } from "country-state-city";

const Countries = () => {
  const [getAllCountries] = useGetAllCountriesMutation();
  const [countryData, setCountryData] = useState();
  const [editCountryId, setEditCountryId] = useState(null);
  const [paginate, setPaginate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [description, setDescription] = useState(null);
  const [countries, setCountries] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [addCountry] = useAddCountryMutation();
  const [editCountry] = useEditCountryMutation();
  const [uploadRes, setUploadRes] = useState();
  const isEditing = (record) => record.id === editingKey;
  const showModal = (record) => {
    setIsModalOpen(true);
    setEditCountryId(record);
  };
  const editStatus = (record) => {
    setEditingKey(record.id);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const addCountryFn = async (country, description) => {
    try {
      const countryCode = getCountries().find((c) => en[c] === country);
      const reqData = {
        name: country,
        countryCode: countryCode,
        description: description,
        documentId: uploadRes?.data?.data?.id,
      };
      await addCountry(reqData).unwrap();
      getAllCountriesFn();
      handleCancel();
    } catch (error) {
      handleFieldError(error);
    }
  };

  const editCountryFn = async (country, description) => {
    try {
      const countryCode = getCountries().find((c) => en[c] === country);
      const reqData = {
        id: editCountryId?.id,
        name: country,
        countryCode: countryCode,
        description: description,
        documentId: uploadRes?.data?.data?.id,
      };
      await editCountry(reqData).unwrap();
      getAllCountriesFn();
      handleCancel();
    } catch (error) {
      handleFieldError(error);
    }
  };

  const getAllCountriesFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: tableParams?.pagination?.pageSize,
        current: tableParams?.pagination?.current - 1,
      };
      const response = await getAllCountries(reqData).unwrap();
      setCountryData(response?.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response?.data?.total,
        },
      });
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllCountries, paginate]);

  useEffect(() => {
    getAllCountriesFn();
  }, [getAllCountriesFn]);

  const save = async (record) => {
    try {
      const countryCode = getCountries().find((c) => en[c] === value);
      const reqData = {
        id: record?.id,
        name: value ?? record?.name,
        countryCode: countryCode ?? record?.countryCode,
        description: description ?? record?.description,
      };
      await editCountry(reqData).unwrap();
      message.success("Country updated successfully");
      getAllCountriesFn();
      setEditingKey("");
    } catch (error) {
      handleFieldError(error);
      setEditingKey("");
    }
  };

  useEffect(() => {
    setCountries(
      Country.getAllCountries()?.map((item, i) => {
        return {
          key: i,
          label: item?.name,
          value: item?.name,
          isoCode: item?.isoCode,
        };
      })
    );
  }, []);

  const columns = [
    {
      title: "Country Code",
      dataIndex: "countryCode",
      render: (countryCode) => (
        <div className="name-container">{countryCode.toUpperCase() ?? "-"}</div>
      ),
    },
    {
      title: "Country",
      dataIndex: ["name", "id"],
      key: "name",
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Select
            className="country-select"
            showSearch
            placeholder="Select Country"
            filterOption={(input, option) =>
              (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={countries}
            onChange={(e) => setValue(e)}
            defaultValue={record?.name}
          />
        ) : (
          <div className="name-container">{record?.name ?? "-"}</div>
        );
      },
    },
    {
      title: "Description",
      dataIndex: ["description", "id"],
      key: "description",
      align: "center",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Input
            placeholder="Description"
            className="edit-input"
            onChange={(e) => setDescription(e?.target?.value)}
            defaultValue={record?.description}
          />
        ) : (
          <div className="name-container">{record?.description ?? "-"}</div>
        );
      },
    },
    // {
    //   title: "Document Id",
    //   key: "documentId",
    //   dataIndex: "documentId",
    //   render: (documentId) => <span>{documentId ?? "-"}</span>,
    //   align: "center",
    // },
    {
      title: "Action",
      dataIndex: ["countryCode", "status", "id", "description", "name"],
      width: "10%",
      align: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div className="actionContainer2">
            <div
              title="save"
              onClick={() => save(record)}
              style={{
                marginRight: 8,
              }}
            >
              <FaSave fontSize={20} />
            </div>
            <div title="cancel" onClick={() => setEditingKey("")}>
              <MdOutlineCancelPresentation fontSize={20} />
            </div>
          </div>
        ) : (
          <div
            title="Edit status"
            className={`editLogo`}
            onClick={() => showModal(record)}
          >
            <FaRegEdit fontSize={20} />
          </div>
        );
      },
    },
  ];

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setCountryData([]);
    }
  };

  return (
    <>
      <div className="button-container">
        <StyledButton onClick={showModal}>Add Country</StyledButton>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.login?.uuid}
        dataSource={countryData?.data}
        onChange={handleTableChange}
        pagination={false}
      />
      {countryData?.total > 10 && (
        <Pagination
          total={countryData?.total}
          showSizeChanger
          showTotal={(total) => `Total ${total} items`}
          current={tableParams?.pagination?.current}
          pageSize={tableParams?.pagination?.pageSize}
          onChange={(page, pageSize) => {
            setPaginate(!paginate);
            setTableParams({
              pagination: {
                current: page,
                pageSize: pageSize,
              },
            });
          }}
        />
      )}
      <AddCountry
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={editCountryId?.id ? editCountryFn : addCountryFn}
        setUploadRes={setUploadRes}
        editCountryId={editCountryId}
      />
    </>
  );
};

export default Countries;
