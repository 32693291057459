import React from "react";
import { TravelInsuranceContainer } from "./TravelInsurance.styles";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

const TravelInsurance = () => {
  return (
    <TravelInsuranceContainer>
      <div className="card">
        <h3 className="previous-price">
          <strike> US$ 299.00</strike>
        </h3>
        <h2 className="new-price">
          US$ <span className="price">119.00</span>
        </h2>
        <div className="text">INCLUDING COVID-19 MEDICAL EXPENSES</div>
      </div>
      <div className="content">
        <h5 className="sub-heading">
          Feel Peace of Mind While You're Away from Home
        </h5>
        <h2 className="content-heading">
          Travel <span className="diff">Insurance</span>
        </h2>
        <div className="list-item-container">
          <div className="list-item">
            <IoCheckmarkDoneSharp />
            <div className="item-content">
              Maximum Benefit up to US$ 50,000.00
            </div>
          </div>
          <div className="list-item">
            <IoCheckmarkDoneSharp />
            <div className="item-content">Medical Payments Coverage</div>
          </div>
          <div className="list-item">
            <IoCheckmarkDoneSharp />
            <div className="item-content">
              No worry about Trip Delay, Trip Interruption, Lost Baggage
            </div>
          </div>
          <div className="list-item">
            <IoCheckmarkDoneSharp />
            <div className="item-content">
              Protect Your Benefit from Covid-19 Pandemic
            </div>
          </div>
        </div>
      </div>
    </TravelInsuranceContainer>
  );
};

export default TravelInsurance;
