import React from "react";
import { WhyUsContainer } from "./WhyUs.styles";
import { RightOutlined } from "@ant-design/icons";

const WhyUs = () => {
  const cardData = [
    {
      key: "01",
      heading: "Save Time",
      content:
        "Just several days, even several hours. Much faster compared to you doing it yourself.",
      class: "odd-card",
      imageLink: "../Images/SaveTime.png",
    },
    {
      key: "02",
      heading: "Simple Method",
      content:
        "Easy to apply for a visa in some simple steps with our support 24/7.",
      class: "even-card",
      imageLink: "../Images/SimpleMethod.png",
    },
    {
      key: "03",
      heading: "Reliable Service",
      content:
        "If your application can not be accepted because of our process, you will be refunded 100%.",
      class: "odd-card",
      imageLink: "../Images/reliableService.png",
    },
    {
      key: "04",
      heading: "Experienced Team",
      content:
        "Our team assisted over 800,000 customers and dealt with many complex situations.",
      class: "even-card",
      imageLink: "../Images/ExperiencedTeam.png",
    },
    {
      key: "05",
      heading: "Transparent Fees",
      content:
        "Total fees always are published and you will have no hidden fee, no extra charges.",
      class: "odd-card",
      imageLink: "../Images/TransparentFee.png",
    },
  ];
  return (
    <WhyUsContainer>
      <div className="main-container">
        <h2 className="main-heading">Why Choose Us?</h2>
        <div className="card-container">
          {cardData?.map((item) => {
            return (
              <div className={`card ${item?.class}`}>
                <div className="logo-container">
                  <div className="logo">
                    <img src={item?.imageLink} alt="logo" className="image" />
                  </div>
                  <div className="divider" />
                </div>
                <div className="heading">{item?.heading}</div>
                <div className="content">{item?.content}</div>
                <div className="number-container">
                  <div className="arrow-logo">
                    <RightOutlined />
                  </div>
                  <div className="number">{item?.key}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </WhyUsContainer>
  );
};

export default WhyUs;
