import { Button } from "antd";
import styled from "styled-components";
import { deviceQuery } from "../../../Utils/MediaSizes";

export const HeaderContainer = styled.div`
  justify-content: space-between;
  background-color: ${(props) => props.theme.colors.white};
  position: ${(props) => props.scroll > 65 && "fixed"};
  top: 80px;
  left: 0;
  right: 0;
  z-index: 111;
  box-shadow: ${(props) => props.scroll > 65 && "0 4px 10px 0 rgba(0,0,0,.06)"};
  display: flex;
  justify-content: center;
  box-shadow: ${(props) => props.theme.shadow.cardShadow};
  @media (${deviceQuery.laptopM}) {
    position: fixed;
    top: 0;
  }
  .main-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: ${(props) => (props.scroll > 65 ? "12px 0px" : "0px")};
    width: 1170px;
    @media (${deviceQuery.laptopM}) {
      padding: 0;
    }
    .header-element-container {
      display: flex;
      gap: ${(props) => (props.scroll > 65 ? "30px" : "60px")};
      padding: 12px 4px;
      transition: all 0.3s ease 0s;
      @media (${deviceQuery.laptopM}) {
        display: none;
      }
      .active-element {
        color: ${(props) => props.theme.colors.blue} !important;
      }
      .header-element {
        cursor: pointer;
        font-size: 14px;
        line-height: 14px;
        font-weight: 600;
        color: ${(props) => props.theme.colors.blueDark};
        text-transform: uppercase;
        font-family: "Roboto", sans-serif;
        letter-spacing: 0.3px;
        &:hover {
          color: ${(props) => props.theme.colors.blue};
        }
      }
    }
    .search-container {
      padding: 12px 4px;
      @media (${deviceQuery.laptopM}) {
        display: none;
      }
      .input {
        border: 1px solid ${(props) => props.theme.colors.gray2};
        background-color: ${(props) => props.theme.colors.grayBackground};
        color: ${(props) => props.theme.colors.gray3};
        border-radius: 30px;
        min-width: 240px;
        padding: 7px 20px;
        margin-left: auto;
        ::placeholder {
          color: ${(props) => props.theme.colors.gray3};
          font-size: 12px;
          line-height: 25px;
        }
        svg {
          fill: ${(props) => props.theme.colors.gray3};
        }
      }
    }
  }

  .sub-menu-main-container {
    position: relative;
    .sub-menu-container {
      display: none;
      position: absolute;
      top: ${(props) => (props.scroll > 65 ? "52px" : "40px")};
      left: -20px;
      background: ${(props) => props.theme.colors.white};
      border-radius: 0px;
      width: 240px;
      padding: 0px;
      z-index: 3;
      box-shadow: ${(props) => props.theme.shadow.subMenuShadow};
      .sub-menu-item {
        z-index: 111;
        padding: 15px 20px;
        color: ${(props) => props.theme.colors.gray};
        font-size: 13px;
        line-height: 14px;
        font-weight: 500;
        border-top: 1px solid ${(props) => props.theme.colors.borderColor2};
        cursor: pointer;
        text-transform: uppercase;
        transition: all 500ms ease;
        &:hover {
          background-color: ${(props) => props.theme.colors.blue};
          color: ${(props) => props.theme.colors.white};
        }
      }
      &:hover {
        display: block;
      }
    }
  }
  .sub-menu-container::after {
    content: "";
    background-color: transparent;
    position: absolute;
    top: ${(props) => (props.scroll > 65 ? "-38px" : "-30px")};
    left: 0px;
    height: 40px;
    width: 100%;
    cursor: pointer;
  }

  .sub-menu-container:hover:after {
    .sub-menu-container {
      display: block;
    }
  }
  .sub-menu-main-container:hover {
    .sub-menu-container {
      display: block;
    }
  }

  .hamburger-menu-container {
    display: none;
    justify-content: space-between;
    padding: 12px 20px;
    width: 100%;
    @media (${deviceQuery.laptopM}) {
      display: flex;
    }

    .logo-container {
      width: 250px;
      height: 45px;
      .logo {
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .menuToggle {
      display: block;
      z-index: 1;
      align-items: center;
      -webkit-user-select: none;
      user-select: none;
      li {
        text-decoration: none;
        color: ${(props) => props.theme.colors.darkBlue};
        transition: all 0.3s ease;
        &:hover {
          color: ${(props) => props.theme.colors.blue};
        }
      }
      input {
        display: block;
        width: 40px;
        height: 32px;
        position: absolute;
        cursor: pointer;
        opacity: 0;
        z-index: 2;
        -webkit-touch-callout: none;
      }
      span {
        position: relative;
        display: block;
        width: 33px;
        height: 4px;
        margin-bottom: 5px;
        position: relative;
        background: ${(props) => props.theme.colors.darkBlue};
        border-radius: 3px;
        z-index: 1;
        transform-origin: 4px 0px;
        transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1),
          background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1), opacity 0.55s ease;
        &:first-child {
          transform-origin: 0% 0%;
        }
        &:nth-last-child(2) {
          transform-origin: 0% 100%;
        }
      }
    }

    .menuToggle {
      input {
        &:checked ~ span {
          opacity: 1;
          transform: rotate(45deg) translate(-2px, -1px);
          background: ${(props) => props.theme.colors.darkBlue};
          &:nth-last-child(3) {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
          }
          &:nth-last-child(2) {
            transform: rotate(-45deg) translate(0, -1px);
          }
        }
        &:checked ~ .menuItem {
          transform: none;
        }
      }
    }

    .menuItem {
      position: absolute;
      width: 98%;
      background: ${(props) => props.theme.colors.white};
      border-radius: 2px;
      list-style-type: none;
      top: -685px;
      -webkit-font-smoothing: antialiased;
      transform-origin: 0% 0%;
      margin: 0 auto;
      transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
      box-shadow: ${(props) => props.theme.shadow.cardShadow};
      padding: 10px;
      li {
        padding: 10px 0;
        font-size: 22px;
      }
    }

    .menu--right {
      display: flex;
      align-items: center;
      .menuItem {
        right: 0;
        left: 0;
        top: 80px;
        margin-top: 18px;
        transform: translate(0, -225%);
        li {
          cursor: pointer;
          font-size: 14px;
          line-height: 14px;
          font-weight: 600;
          color: ${(props) => props.theme.colors.darkBlue};
          text-transform: uppercase;
          font-family: "Roboto", sans-serif;
          letter-spacing: 0.3px;
          padding: 15px 0px;
          border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
          display: flex;
          flex-direction: column;
          &:last-child {
            border-bottom: none;
          }
          .menu-item-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
            background: transparent;
            height: 20px;
            svg {
              height: 25px;
              width: 25px;
            }
          }
          .sub-menu-item-content {
            display: none;
            padding-left: 20px;
            transition: all 0.6s ease;
            .sub-menu-item {
              color: ${(props) => props.theme.colors.darkBlue};
              padding: 15px 0px;
              border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
              &:last-child {
                border-bottom: none;
              }
              &:hover {
                color: ${(props) => props.theme.colors.blue};
                padding-left: 10px;
              }
            }
          }
          .sub-menu-open {
            display: block !important;
          }
          &:hover {
            color: ${(props) => props.theme.colors.blue};
            padding-left: 10px;
          }
        }
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  border: 1px solid ${(props) => props.theme.colors.blue};
  border-radius: 30px;
  color: ${(props) => props.theme.colors.blue};
  width: 130px;
  height: 45px;
  font-weight: 700;
  transition: 0.4s;
  &:hover {
    color: ${(props) => props.theme.colors.white} !important;
    background-color: ${(props) => props.theme.colors.blue} !important;
  }
`;
