import React, { useEffect } from "react";
import { FAQsContainer } from "./FAQs.styles";
import FAQsAccordion from "./Components/FAQsAccordion/FAQsAccordion";
import FAQsForm from "./Components/FAQsForm/FAQsForm";
import { scrollToTop } from "../../Utils/Common";

const FAQs = () => {
  useEffect(() => {
    scrollToTop(0);
  }, []);
  return (
    <FAQsContainer>
      <FAQsAccordion />
      <FAQsForm />
    </FAQsContainer>
  );
};

export default FAQs;
