import React from "react";
import { AuthHeaderContainer } from "./AuthHeader.styles";
import { ROUTES } from "../../../Constants/Routes";
import { useNavigate } from "react-router-dom";

const AuthHeader = () => {
  const navigate = useNavigate();
  return (
    <AuthHeaderContainer>
      <div className="logo-container" onClick={() => navigate(ROUTES.HOME)}>
        <img
          src="../Images/Global_Citizen_Portal_Logo.png"
          alt="logo"
          className="logo"
        />
      </div>
    </AuthHeaderContainer>
  );
};

export default AuthHeader;
