import styled from "styled-components";
import { deviceQuery } from "../../Utils/MediaSizes";

export const LoginContainer = styled.div`
  margin: 10px auto;
  .main-container {
    display: flex;
    width: 75%;
    margin: auto;
    padding: 20px 0px;
    @media (${deviceQuery.laptopL}) {
      width: 90%;
    }
    @media (${deviceQuery.laptopS}) {
      width: 95%;
    }
    @media (${deviceQuery.tabletL}) {
      flex-direction: column;
      align-items: center;
      gap: 30px;
      margin-top: 40px;
    }
    .left-container {
      .image-container {
        height: 500px;
        width: 600px;
        @media (${deviceQuery.mobileSM}) {
          height: 200px;
          width: 300px;
        }
        .image {
          height: 100%;
          width: 100%;
        }
      }
    }
    .right-container {
      width: 50%;
      display: flex;
      justify-content: center;
      @media (${deviceQuery.tabletL}) {
        width: 70%;
      }
      @media (${deviceQuery.mobileSM}) {
        width: 90%;
      }
      .card {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: ${(props) => props.theme.colors.lightBlue};
        box-shadow: ${(props) => props.theme.shadow.cardShadow2};
        border-radius: 8px;
        padding: 20px;
        @media (${deviceQuery.laptopS}) {
          width: 100%;
        }
        .heading {
          font-weight: 600;
          text-align: center;
          font-size: 24px;
          line-height: 24px;
          color: ${(props) => props.theme.colors.blue};
          text-transform: capitalize;
          margin-bottom: 0px;
        }
        .sub-heading {
          font-weight: 600;
          text-align: center;
          font-size: 18px;
          line-height: 18px;
          color: ${(props) => props.theme.colors.darkBlue};
          text-transform: capitalize;
          margin-top: 15px;
          margin-bottom: 15px;
        }
        .form {
          width: 100%;

          .input-container {
            margin-bottom: 12px !important;
            label {
              margin-bottom: 8px;
              font-weight: 500;
              font-size: 14px;
              color: ${(props) => props.theme.colors.darkBlue};
            }
          }
          .ant-form-item {
            margin: 0px;
            width: 100%;
            height: 90px;
          }
          .input {
            height: 50px;
            border-radius: 0px;
            background-color: ${(props) => props.theme.colors.white};
          }

          .ant-input {
            &::placeholder {
              font-size: 14px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.6);
              border-color: rgba(255, 255, 255, 0.06);
            }
          }
          .forgot-password {
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 14px;
            color: ${(props) => props.theme.colors.darkBlue};
            margin-top: -25px;
            text-align: right;
            cursor: pointer;
          }
          .ant-btn {
            margin: 20px 0px;
          }
          .no-account {
            text-align: center;
            margin-bottom: 8px;
            font-weight: 500;
            font-size: 16px;
            color: ${(props) => props.theme.colors.darkBlue};
            margin-top: -10px;
            .sign-up {
              color: ${(props) => props.theme.colors.blue};
              margin-left: 6px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
`;
