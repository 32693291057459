import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const FAQsAccordionContainer = styled.div`
  width: 70%;
  @media (${deviceQuery.tabletS}) {
    width: 90%;
  }
  .small-heading {
    color: ${(props) => props.theme.colors.blue};
    font-weight: 600;
    display: block;
    text-transform: uppercase;
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 1px;
    margin-bottom: 5px;
    @media (${deviceQuery.mobileSM}) {
      font-size: 12px;
      line-height: 12px;
    }
  }
  .container-heading {
    font-weight: 300;
    text-transform: capitalize;
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 25px;
    margin-top: 0;
    color: ${(props) => props.theme.colors.darkBlue};
    align-self: self-start;
    @media (${deviceQuery.mobileSM}) {
      font-size: 28px;
      line-height: 32px;
    }
    .diff {
      font-weight: 600;
      color: ${(props) => props.theme.colors.blue};
    }
  }
  .collapse-container {
    /* display: flex;
    flex-direction: column;
    gap: 25px; */
    width: 90%;
    @media (${deviceQuery.laptopML}) {
      width: 100%;
    }
    .ant-collapse {
      border-radius: 0px !important;
      border: none !important;
      background-color: transparent;
    }
    .ant-collapse-item {
      border-radius: 0px !important;
      border: none !important;
      margin-bottom: 25px;
    }
    .ant-collapse-extra > span {
      font-weight: 800 !important;
      font-size: 20px !important;
    }
    .ant-collapse-header {
      background-color: ${(props) => props.theme.colors.lightBlue};
      padding: 14px;
      span {
        color: ${(props) => props.theme.colors.darkBlue};
        text-transform: capitalize;
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        display: block;
        transition: color 0s ease-in-out;
      }
      &:hover {
        span {
          color: ${(props) => props.theme.colors.blue};
        }
      }
    }
    .ant-collapse-item-active > .ant-collapse-header {
      background-color: ${(props) => props.theme.colors.blue} !important;
      border-radius: 0px;
      span {
        color: ${(props) => props.theme.colors.white};
      }
    }
    .ant-collapse-content-box {
      background-color: ${(props) => props.theme.colors.white};
      color: ${(props) => props.theme.colors.gray};
      font-size: 16px;
      box-shadow: 0 0 15px 0 rgba(41, 61, 88, 0.1);
      padding: 20px;
      @media (${deviceQuery.mobileSM}) {
        font-size: 14px;
      }
    }
  }
`;
