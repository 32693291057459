import styled from "styled-components";
import { deviceQuery } from "../../../Utils/MediaSizes";

export const FooterContainer = styled.div`
  background: url("../Images/footer-bg.png") center center/cover;
  padding: 40px 0px 23px;
  background-color: ${(props) => props.theme.colors.darkBlue2};

  .main-container {
    color: ${(props) => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    max-width: 1170px;
    margin: 0 auto;
    .list-container {
      width: 100%;
      display: flex;
      justify-content: space-around;
      @media (${deviceQuery.tabletML}) {
        flex-direction: column;
        gap: 20px;
        justify-content: center;
      }
      .right-links-container {
        width: 70%;
        display: flex;
        justify-content: space-around;
        @media (${deviceQuery.tabletML}) {
          padding: 0px 20px;
          width: 100%;
          flex-wrap: wrap;
          gap: 20px;
        }
        @media (${deviceQuery.mobileS}) {
          padding: 0px 25%;
          justify-content: start;
        }
      }
      .about-us-container {
        @media (${deviceQuery.tabletML}) {
          width: 40%;
          margin: auto;
          .heading-container {
            align-items: center;
            .underline {
              width: 70px;
            }
          }
        }
        @media (${deviceQuery.mobileM}) {
          width: 80%;
        }
      }
      .heading-container {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
        gap: 8px;
        .list-heading {
          font-size: 19px;
          line-height: 16px;
          font-weight: 500;
          margin-bottom: 0px;
        }
        .underline {
          width: 18px;
          height: 2px;
          background-color: ${(props) => props.theme.colors.blue};
        }
      }
      .list-item-container {
        display: flex;
        flex-direction: column;
        gap: 10px;
        .list-item-content {
          max-width: 250px;
          font-weight: 400;
          font-size: 15px;
          line-height: 25px;
          letter-spacing: 0px;
          @media (${deviceQuery.tabletML}) {
            max-width: 100%;
            text-align: center;
          }
        }
        .list-item {
          display: flex;
          gap: 10px;
          align-items: center;
          .item-dash {
            width: 10px;
            height: 1px;
            background-color: ${(props) => props.theme.colors.gray};
          }
          .name {
            transition: all 0.3s ease 0s;
            font-size: 15px;
            line-height: 25px;
            letter-spacing: 0px;
            cursor: pointer;
            &:hover {
              color: ${(props) => props.theme.colors.blue};
            }
          }
        }
      }
      .divider {
        width: 100%;
        height: 1px;
        background-color: ${(props) => props.theme.colors.gray};
        margin: 10px 0px;
      }
      .social-list-container {
        display: flex;
        justify-content: space-between;
        max-width: 160px;
        .social-item {
          border: 1px solid ${(props) => props.theme.colors.blue};
          border-radius: 50%;
          padding: 6px 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            background-color: ${(props) => props.theme.colors.blue};
            svg {
              fill: ${(props) => props.theme.colors.white};
            }
          }
        }
        svg {
          fill: ${(props) => props.theme.colors.blue};
        }
        .google {
          height: 20px;
          width: 20px;
        }
        @media (${deviceQuery.tabletML}) {
          margin: auto;
          gap: 12px;
        }
      }
    }
    .contact-us-container {
      display: flex;
      width: 100%;
      .contact-card {
        width: 33.33%;
        padding: 22px 30px;
        background-color: ${(props) => props.theme.colors.darkBlue3};
        display: flex;
        align-items: center;
        gap: 12px;
        .contact {
          font-size: 16px;
          font-weight: 400;
          line-height: 23px;
          margin-bottom: 0;
          margin-top: 0;
        }
        .text {
          font-weight: 400;
          font-size: 15px;
          line-height: 25px;
          letter-spacing: 0px;
        }
        .icon {
          width: 46px;
          height: 46px;
          line-height: 46px;
          background-color: rgba(255, 255, 255, 0.07);
          box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.08);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .phone-container {
        padding: 25px 30px;
        background-color: ${(props) => props.theme.colors.blue};
      }
    }
    .bottom-footer-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      .pages-container {
        display: flex;
        gap: 12px;
        align-items: center;
        @media (${deviceQuery.mobileM}) {
          flex-wrap: wrap;
          justify-content: center;
          margin-bottom: 12px;
        }
        .page {
          transition: all 0.3s ease 0s;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
          cursor: pointer;
          &:hover {
            color: ${(props) => props.theme.colors.blue};
          }
        }
        .page-divider {
          height: 10px;
          width: 1px;
          background-color: ${(props) => props.theme.colors.gray};
        }
      }
      .copyright-container {
        font-size: 13px;
        line-height: 24px;
        letter-spacing: 0;
        font-weight: 400;
        .important {
          cursor: pointer;
          &:hover {
            color: ${(props) => props.theme.colors.blue};
          }
        }
      }
    }
  }
`;
