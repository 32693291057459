import React, { useEffect, useState } from "react";
import {
  OrderInformationContainer,
  StyledButton,
} from "./OrderInformation.styles";
import { Button, Checkbox, DatePicker, Form, Select, message } from "antd";
import { StyledInput } from "../../../../Utils/Global.styles";
import moment from "moment";
import { Country } from "country-state-city";
import { InfoCircleOutlined } from "@ant-design/icons";
import Step1 from "./Step1/Step1";
import {
  useApplyVisaMutation,
  useCheckoutMutation,
} from "../../../../Services/Visa";
import { handleFieldError, scrollToTop } from "../../../../Utils/Common";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../Constants/Routes";

const OrderInformation = ({
  form,
  setDestination,
  setStartDate,
  setEndDate,
  daysDifference,
  setFee,
  setNoOfTraveller,
  noOfTraveller,
  setStep2,
}) => {
  const [countries, setCountries] = useState([]);
  const [step1, setStep1] = useState(false);
  const [applyVisa, { isLoading }] = useApplyVisaMutation();
  const [visaId, setVisaId] = useState(null);
  const [travelCountryId, setTravelCountryId] = useState(null);
  const [checkout, { isLoading: checkoutLoading }] = useCheckoutMutation();
  const navigate = useNavigate();
  const onFinish = async (value) => {
    try {
      const reqData = {
        id: visaId,
        travellers: value.fields.map((traveller) => ({
          surName: traveller.label,
          givenName: traveller.value,
          passportNumber: traveller.passportNo,
          dateOfBirth: moment(traveller.dob.$d).format("YYYY-MM-DD"),
          gender: traveller.gender.toUpperCase(),
        })),
      };
      delete reqData.stayingTime;
      // const checkoutReqData = {
      //   VisaId: visaId,
      //   price: "12",
      // };
      await applyVisa(reqData).unwrap();
      setStep2(true);
      // const transactionResponse = await checkout({
      //   visaId: visaId,
      //   travelingCountryId: travelCountryId,
      // }).unwrap();
      // window.open(transactionResponse?.data, "_self");
      scrollToTop(0);
    } catch (error) {
      handleFieldError(error);
    }
  };

  const dobdisabledDate = (current) => {
    return current && current >= moment().endOf("day");
  };
  useEffect(() => {
    setCountries(
      Country.getAllCountries()?.map((item, i) => {
        return {
          key: i,
          label: item?.name,
          value: item?.name,
          isoCode: item?.isoCode,
        };
      })
    );
  }, []);
  return (
    <OrderInformationContainer>
      {step1 ? (
        <Form
          className="form"
          onFinish={onFinish}
          form={form}
          validateTrigger={"onBlur"}
        >
          <Form.List name="fields" initialValue={[{}]}>
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <div key={field.key}>
                    <div key={field.key} className="form-list">
                      <Form.Item
                        name={[field.name, "label"]}
                        fieldKey={[field.fieldKey, "label"]}
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            max: 50,
                            message: "Maximum 50 characters Allowed",
                          },
                          {
                            pattern: new RegExp(/^[A-Za-z\s]+$/),
                            message: "Must be a valid name",
                          },
                        ]}
                      >
                        <StyledInput placeholder="Surname" className="input" />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "value"]}
                        fieldKey={[field.fieldKey, "value"]}
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            max: 50,
                            message: "Maximum 50 characters Allowed",
                          },
                          {
                            pattern: new RegExp(/^[A-Za-z\s]+$/),
                            message: "Must be a valid name",
                          },
                        ]}
                      >
                        <StyledInput
                          placeholder="Given Name"
                          className="input"
                        />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "gender"]}
                        fieldKey={[field.fieldKey, "gender"]}
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Gender"
                          className="input"
                          options={[
                            { label: "Male", value: "Male" },
                            { label: "Female", value: "female" },
                            { label: "Other", value: "other" },
                          ]}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "nationality"]}
                        fieldKey={[field.fieldKey, "nationality"]}
                        style={{ width: "100%" }}
                      >
                        <Select
                          showSearch
                          placeholder="Nationality"
                          className="input"
                          filterOption={(input, option) =>
                            (option?.value ?? "")
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={countries}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "dob"]}
                        fieldKey={[field.fieldKey, "dob"]}
                        style={{ width: "100%" }}
                      >
                        <DatePicker
                          placeholder="Date of Birth"
                          className="input"
                          format="YYYY-MM-DD"
                          disabledDate={dobdisabledDate}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[field.name, "passportNo"]}
                        fieldKey={[field.fieldKey, "passportNo"]}
                        style={{ width: "100%" }}
                        rules={[
                          {
                            required: true,
                            message: "",
                          },
                          {
                            max: 50,
                            message: "Maximum 50 characters Allowed",
                          },
                        ]}
                      >
                        <StyledInput
                          type="text"
                          placeholder="Passport Number *"
                          className="input"
                          maxLength={50}
                        />
                      </Form.Item>
                      {/* <div className="upload-container">
                        <Dragger
                          maxCount={1}
                          fileList={fileList}
                          customRequest={(e) => uploadImageFn(e)}
                          onChange={(e) => handleOnChange(e)}
                          // accept="image/*"
                          onRemove={() => setFileList([])}
                        >
                          <div className="upload-div">
                            <span className="heading">Attach Files</span>
                            <span className="sub-heading">
                              Add or drop your file here.
                            </span>
                          </div>
                        </Dragger>
                      </div> */}
                    </div>
                    <div className="button-container">
                      {fields.length > 1 && (
                        <Button
                          className="remove-btn"
                          onClick={() => {
                            remove(field.name);
                            setNoOfTraveller(noOfTraveller - 1);
                          }}
                        >
                          Remove
                        </Button>
                      )}
                      {index === fields.length - 1 && (
                        <Form.Item>
                          <Button
                            type="dashed"
                            className="add-btn"
                            onClick={() => {
                              add();
                              setNoOfTraveller(noOfTraveller + 1);
                            }}
                          >
                            Add Traveller
                          </Button>
                        </Form.Item>
                      )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </Form.List>
          <div className="insurance-card">
            <h5 className="heading">Covid-19 Insurance</h5>
            <div className="details-card">
              <Checkbox>
                US$ 50,000.00 Covered Expenses <InfoCircleOutlined />
              </Checkbox>
              <p className="text">
                Advantages of getting visa approval by the Government. Without
                it, visa may be refused.
              </p>
            </div>
          </div>

          <StyledButton
            className="button"
            htmlType="submit"
            loading={isLoading || checkoutLoading}
          >
            Submit Request!
          </StyledButton>
        </Form>
      ) : (
        <Step1
          setStep1={setStep1}
          setDestination={setDestination}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          daysDifference={daysDifference}
          applyVisa={applyVisa}
          isLoading={isLoading}
          setVisaId={setVisaId}
          setTravelCountryId={setTravelCountryId}
          setFee={setFee}
        />
      )}
    </OrderInformationContainer>
  );
};

export default OrderInformation;
