import styled from "styled-components";
import { deviceQuery } from "../../../Utils/MediaSizes";

export const CommonVisaCardContainer = styled.div`
  width: 332px;
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.shadow.cardShadow2};
  transition: 0.5s;
  justify-self: center;
  @media (${deviceQuery.mobileMS}) {
    width: 240px;
  }
  &:hover {
    transform: translateY(-8px);
    .image-container > .image {
      clip-path: polygon(0 100%, 0 0, 100% 0, 100% 85%, 0 85%);
    }
  }
  .image-container {
    background-color: ${(props) => props.theme.colors.white};
    .image {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      min-height: 320px;
      clip-path: polygon(0 100%, 0 0, 100% 0, 100% 70%, 0 99%);
      transition: all 0.8s;
      cursor: pointer;
      max-height: 320px;
    }
  }
  .content-container {
    padding: 0px 10px 20px 10px;
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    .country-flag-container {
      transform: translateY(-65px);
      margin-bottom: -50px;
      transition: all 0.4s;
      text-align: center;
      .country-flag {
        height: 60px !important;
        width: 60px !important;
        border-radius: 50%;
        margin: 0 auto;
        border: 5px solid ${(props) => props.theme.colors.white};
        box-shadow: ${(props) => props.theme.shadow.cardShadow3};
        object-fit: cover;
      }
    }
    .country-name-container {
      h5 {
        margin: 0px;
        margin-bottom: 10px;
      }
      .country-name {
        color: ${(props) => props.theme.colors.darkBlue};
        transition: all 0.3s ease 0s;
        font-size: 15px;
        line-height: 25px;
        letter-spacing: 0px;
        font-weight: 400px;
        text-transform: capitalize;
        font-family: "Roboto", sans-serif;
        cursor: pointer;
        transition: all 0.3s ease 0s;
        margin: 0px;
        &:hover {
          color: ${(props) => props.theme.colors.blue};
        }
      }
    }
    .content {
      text-align: center;
      font-size: 15px;
      line-height: 25px;
      font-weight: 400px;
      letter-spacing: 0px;
      color: ${(props) => props.theme.colors.gray};
      margin-bottom: 10px;
    }
    .read-more {
      color: ${(props) => props.theme.colors.blue};
      font-size: 14px;
      font-weight: 500;
      font-family: "Roboto", sans-serif;
      transition: all 0.5s ease 0s;
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.colors.darkBlue2};
      }
    }
  }
`;
