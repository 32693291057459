import { Button, Modal } from "antd";
import { styled } from "styled-components";
import { deviceQuery } from "../../../Utils/MediaSizes";

export const AddCountryModal = styled(Modal)`
  .ant-modal-content {
    background-color: ${(props) =>
      props.theme.colors.backgroundColor}!important;
  }

  .ant-modal-close-x {
    color: ${(props) => props.theme.colors.descriptionColor};
  }
  .heading {
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    color: ${(props) => props.theme.colors.darkBlue};
    line-height: 25.2px;
    margin-left: 20px;
    /* padding-top: 10px; */
  }
  .main-container {
    /* height: 100px; */
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px;
    gap: 20px;
    .ant-form {
      width: 80%;
      .ant-input {
        margin-left: 0;
      }
      .button {
        width: 100%;
      }
    }
    .input {
      /* width: 95%; */
      height: 38px;
      border-radius: 6px;
      background-color: ${(props) => props.theme.colors.inputBakground};
      color: ${(props) => props.theme.colors.descriptionColor};
      border: 1px solid ${(props) => props.theme.colors.darkBlue};
    }
    .ant-input {
      /* background: transparent; */
      /* margin-left: 6px; */
      &::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: ${(props) => props.theme.colors.gray1};
      }
    }
    .ant-select {
      width: 100%;
    }
    .ant-select-selection-item {
      font-weight: 400;
      font-size: 14px;
      color: ${(props) => props.theme.colors.inputColor};
    }
    .ant-select-arrow {
      color: ${(props) => props.theme.colors.descriptionColor};
    }
    .ant-select > .ant-select-selector {
      border-radius: 6px;
      height: 38px;
      color: ${(props) => props.theme.colors.greyTitle};
      background: ${(props) => props.theme.colors.white};
      border-color: ${(props) => props.theme.colors.darkBlue} !important;
      &:focus {
        border: none !important;
      }
    }
    .ant-select-selector > .ant-select-selection-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: ${(props) => props.theme.colors.gray1};
    }

    .ant-upload-list-item-name {
      max-width: 250px !important;
    }

    .select {
      height: 38px;
      border-radius: 6px;
      border: 1px solid ${(props) => props.theme.colors.darkBlue};
      box-shadow: ${(props) => props.theme.shadow.cardShadow};
      @media (${deviceQuery.mobileSM}) {
        width: 190px;
      }
      @media (${deviceQuery.mobileMS}) {
        width: 150px;
      }
      @media (${deviceQuery.mobileS}) {
        width: 120px;
      }
      &:focus {
        border: none !important;
      }
    }
    .button {
      margin: 0;
    }
  }
`;
