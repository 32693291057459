import { styled } from "styled-components";
import { deviceQuery } from "../../Utils/MediaSizes";

export const NotFoundContainer = styled.div`
  background-color: white;
  /* height: 100vh; */
  margin: 40px 0px;
  font-family: "Montserrat", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .notfound-404 {
    height: 280px;
    position: relative;
    margin-bottom: 22px;
    /* z-index: -1; */
    @media (${deviceQuery.mobileL}) {
      height: 200px;
    }
    @media (${deviceQuery.mobileS}) {
      height: 130px;
    }
    h1 {
      z-index: 1;
      font-size: 230px;
      font-weight: 900;
      margin: 0;
      position: absolute;
      left: 50%;
      -webkit-transform: translateX(-50%);
      -ms-transform: translateX(-50%);
      transform: translateX(-50%);
      background: url("../Images/bg.jpg") no-repeat;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-size: cover;
      background-position: center;

      @media (${deviceQuery.mobileL}) {
        font-size: 140px;
      }
      @media (${deviceQuery.mobileS}) {
        font-size: 100px;
      }
    }
  }
  h2 {
    color: #000;
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    color: ${(props) => props.theme.colors.darkBlue};
    @media (${deviceQuery.mobileS}) {
      font-size: 16px;
    }
  }
  p {
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 0;
    width: 380px;
    text-align: center;
    font-weight: 600;
    color: ${(props) => props.theme.colors.darkBlue};
    @media (${deviceQuery.mobileS}) {
      width: 250px;
      font-size: 12px;
    }
  }

  .button {
    /* border: none; */
    -webkit-box-shadow: 0 4px 15px -5px #0046d5;
    box-shadow: 0 4px 15px -5px #0046d5;
    border-radius: 40px;
    background: #0046d5;
    height: 50px;
    padding: 0px 30px;
    span {
      font-size: 14px;
      text-decoration: none;
      text-transform: uppercase;
      display: inline-block;
      color: #fff;
      font-weight: 700;
    }
  }
`;
