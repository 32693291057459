import React, { useEffect, useState } from "react";
import { LandingThanksContainer } from "./LandingThanks.styles";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Constants/Routes";
import TagManager from "react-gtm-module";
import { InlineWidget } from "react-calendly";

const LandingThanks = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-NWDJG6ZP",
    };
    TagManager.initialize(tagManagerArgs);

    if (window.fbq) {
      window.fbq("init", "2544791289127584");
      window.fbq("track", "PageView");
    }
  }, []);

  return (
    <LandingThanksContainer>
      <div className="thankyou_page_content">
        <div className="left-section">
          <h1>
            Thank you for contacting us. <br />
            We will get back soon.
          </h1>
          <div className="btn-holder">
            <Button className="btn" onClick={() => navigate(ROUTES.HOME)}>
              Go To Home
            </Button>
          </div>
        </div>
        <div className="right-section">
          <InlineWidget url="https://calendly.com/globalcitizenportal-info/book-free-consultation-call" />
        </div>
      </div>
    </LandingThanksContainer>
  );
};

export default LandingThanks;
