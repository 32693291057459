import React, { useEffect, useState } from "react";
import { CheckStatusContainer } from "./CheckStatus.styles";
import CheckStatusHeader from "./Components/CheckStatusHeader/CheckStatusHeader";
import CheckStatusForm from "./Components/CheckStatusForm/CheckStatusForm";
import { scrollToTop } from "../../Utils/Common";
import { StyledButton } from "./Components/CheckStatusForm/CheckStatusForm.styles";
import { ROUTES } from "../../Constants/Routes";
import { useNavigate } from "react-router-dom";

const CheckStatus = () => {
  const [status, setStatus] = useState(false);
  const [responseData, setResponseData] = useState();
  useEffect(() => {
    scrollToTop(0);
  }, []);
  const navigate = useNavigate();
  return (
    <CheckStatusContainer>
      <CheckStatusHeader />
      <div className="content-container">
        <div className="left-container">
          <h2 className="container-heading">
            See Where Your <span className="diff">Visa</span> Going
          </h2>
          <div className="content">
            <p>
              Global Citizen Portal provides this function to help travelers
              across the globe easily track the progress of your visa allowing
              you to take necessary actions to maintain compliance and avoid any
              potential problems.
            </p>
            <p>
              After you've submitted your visa application, you can verify its
              status through an online platform. Just fill your information as
              requested in the form, you will know your visa status in 30
              minutes via email.
            </p>
          </div>
          {/* <div className="image-container">
            <img
              src="../Images/Visa-Approved.jpg"
              alt="approved"
              className="image"
            />
          </div> */}
        </div>
        <div className="right-container">
          {status ? (
            <div className="status-container active-status">
              <h5>Status Of Your Visa:</h5>
              <h3>{responseData?.data?.status ?? "-"}</h3>
              <StyledButton
                onClick={() => setStatus(false)}
                className="check-button"
              >
                Check Another Visa Status
              </StyledButton>
              <div className="not-recieve">
                Didn't recieve mail?&nbsp;
                <span
                  className="contact"
                  onClick={() => navigate(ROUTES.CONTACTUS)}
                >
                  Contact Us
                </span>
              </div>
            </div>
          ) : (
            <CheckStatusForm
              setStatus={setStatus}
              setResponseData={setResponseData}
            />
          )}
        </div>
      </div>
    </CheckStatusContainer>
  );
};

export default CheckStatus;
