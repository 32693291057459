import { useNavigate } from "react-router-dom";
import { CommonVisasCardContainer } from "./CommonVisasCard.styles";
import { ROUTES } from "../../../Constants/Routes";
import ReactCountryFlag from "react-country-flag";

const CommonVisasCard = ({ item }) => {
  const navigate = useNavigate();
  return (
    <CommonVisasCardContainer
      onClick={() =>
        navigate(`${ROUTES.COUNTRYDETAILS}/${item?.name}/${item?.id}`)
      }
    >
      <div className="image-container">
        <ReactCountryFlag
          countryCode={item?.countryCode}
          svg
          className="country-flag card-image"
        />
      </div>
      <div className="card-text">{item?.name} Visa</div>
    </CommonVisasCardContainer>
  );
};

export default CommonVisasCard;
