import React, { useEffect, useState } from "react";
import { FAQsFormContainer, StyledButton } from "./FAQsForm.styles";
import { useForm } from "antd/es/form/Form";
import { Form, Select, message } from "antd";
import { StyledInput } from "../../../../Utils/Global.styles";
import CountrySelect from "../../../../Common/Components/CountrySelect/CountrySelect";
import TextArea from "antd/es/input/TextArea";
import en from "react-phone-number-input/locale/en.json";
import { handleFieldError } from "../../../../Utils/Common";
import { useContactUsMutation } from "../../../../Services/Users";
import { Country } from "country-state-city";

const FAQsForm = () => {
  const [form] = useForm();
  const [contactUs] = useContactUsMutation();
  const [countries, setCountries] = useState([]);
  const onFinish = async (value) => {
    try {
      await contactUs(value).unwrap();
      message.success("Query Submitted Successfully");
    } catch (error) {
      handleFieldError(error);
    }
  };

  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 15) {
      form.setFieldValue("mobileNo", inputValue);
    } else {
      const truncatedInput = inputValue.slice(0, 15);
      form.setFieldValue("mobileNo", truncatedInput);
    }
  };

  const restrictedKeys = ["+", "-", "e", "."];

  useEffect(() => {
    setCountries(
      Country.getAllCountries()?.map((item, i) => {
        return {
          key: i,
          label: item?.name,
          value: item?.name,
          isoCode: item?.isoCode,
        };
      })
    );
  }, []);

  return (
    <FAQsFormContainer>
      <Form
        className="form"
        onFinish={onFinish}
        form={form}
        validateTrigger={"onBlur"}
      >
        <h3 className="heading">
          Can't <span className="diff">Find</span> The
          <span className="diff"> Answers ?</span>
        </h3>
        <div className="input-select-container">
          <Form.Item
            name="firstName"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
              {
                max: 50,
                message: "Maximum 50 characters Allowed",
              },
              {
                pattern: new RegExp(/^[A-Za-z\s]+$/),
                message: "Must be a valid name",
              },
            ]}
          >
            <StyledInput
              type="text"
              placeholder="First Name *"
              className="input"
              maxLength={50}
            />
          </Form.Item>
          <Form.Item
            name="lastName"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please input your name!",
              },
              {
                max: 50,
                message: "Maximum 50 characters Allowed",
              },
              {
                pattern: new RegExp(/^[A-Za-z\s]+$/),
                message: "Must be a valid name",
              },
            ]}
          >
            <StyledInput
              type="text"
              placeholder="Last Name *"
              className="input"
              maxLength={50}
            />
          </Form.Item>
          <Form.Item
            name="email"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please input your email!",
              },
              {
                type: "email",
                message: "Must be valid Email",
              },
            ]}
          >
            <StyledInput
              type="text"
              placeholder="Email Address *"
              className="input"
            />
          </Form.Item>

          <Form.Item name="country" style={{ width: "100%" }}>
            <Select
              showSearch
              type="text"
              placeholder="Country"
              className="input"
              filterOption={(input, option) =>
                (option?.value ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={countries}
              // onChange={(e) => setDestination(e)}
            />
          </Form.Item>

          <div className="phone-country-select">
            <div className="country-select">
              <CountrySelect size="large" labels={en} isRequired={true} />
            </div>
            <Form.Item
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter phone number!",
                },
                {
                  min: 6,
                  message: "Minimum 6 digits required",
                },
                {
                  max: 15,
                  message: "Phone Number Can't be more than 15 digits",
                },
              ]}
              name="mobileNo"
            >
              <StyledInput
                type="number"
                placeholder="Mobile Number *"
                className="input"
                onKeyDown={(e) => {
                  if (restrictedKeys.includes(e?.key)) {
                    e.preventDefault();
                    return;
                  }
                }}
                value={form.getFieldValue("mobileNo")}
                onChange={handlePhoneInputChange}
                min={0}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="query"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "Please input your message!",
              },
              {
                max: 10000,
                message: "Maximum 10000 characters Allowed",
              },
            ]}
            className="your-request"
          >
            <TextArea
              placeholder="Message *"
              className="text-area"
              rows={6}
              maxLength={10000}
            />
          </Form.Item>
        </div>
        <StyledButton className="button" htmlType="submit">
          Submit Request
        </StyledButton>
      </Form>
    </FAQsFormContainer>
  );
};

export default FAQsForm;
