import React, { useEffect, useState } from "react";
import { SignUp2Container } from "./Signup2.styles";
import { Divider, Form, Input, Select, message } from "antd";
import { StyledButton } from "../ContactUs/Components/ContactUsForm/ContactUsForm.styles";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Constants/Routes";
import { StyledInput } from "../../Utils/Global.styles";
import { handleFieldError, restrictedKeys } from "../../Utils/Common";
import { useForm } from "antd/es/form/Form";
import CountrySelect from "../../Common/Components/CountrySelect/CountrySelect";
import en from "react-phone-number-input/locale/en.json";
import { Country } from "country-state-city";
import { useRegisterMutation } from "../../Services/Auth";

const Signup2 = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [countries, setCountries] = useState([]);
  const navigate = useNavigate();
  const [form] = useForm();
  const [register, { isLoading }] = useRegisterMutation();

  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 15) {
      form.setFieldValue("mobileNo", inputValue);
    } else {
      const truncatedInput = inputValue.slice(0, 15);
      form.setFieldValue("mobileNo", truncatedInput);
    }
  };

  useEffect(() => {
    setCountries(
      Country.getAllCountries()?.map((item, i) => {
        return {
          key: i,
          label: item?.name,
          value: item?.name,
          isoCode: item?.isoCode,
        };
      })
    );
  }, []);

  const signUpFn = async (values) => {
    try {
      const data = {
        ...values,
        userName: values?.email,
      };
      await register(data).unwrap();
      message.success("Signed up Successfully");
      navigate(ROUTES.LOGIN);
    } catch (error) {
      handleFieldError(error);
    }
  };
  return (
    <SignUp2Container>
      <div className="main-container">
        {/* <Divider /> */}
        <div className="left-container">
          <Form className="form" onFinish={signUpFn}>
            <h3 className="heading">Register</h3>
            <div className="input-select-container">
              <div className="input-container">
                <label>First Name</label>
                <Form.Item
                  name="firstName"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    {
                      max: 50,
                      message: "Maximum 50 characters Allowed",
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z\s]+$/),
                      message: "Must be a valid name",
                    },
                  ]}
                >
                  <Input type="text" className="input" />
                </Form.Item>
              </div>
              <div className="input-container">
                <label>Last Name</label>
                <Form.Item
                  name="lastName"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    {
                      max: 50,
                      message: "Maximum 50 characters Allowed",
                    },
                    {
                      pattern: new RegExp(/^[A-Za-z\s]+$/),
                      message: "Must be a valid name",
                    },
                  ]}
                >
                  <Input type="text" className="input" />
                </Form.Item>
              </div>
              <div className="input-container">
                <label>Email</label>
                <Form.Item
                  name="email"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    {
                      type: "email",
                      message: "Must be valid Email",
                    },
                  ]}
                >
                  <Input type="text" className="input" />
                </Form.Item>
              </div>
              <div className="input-container">
                <label>Mobile Number</label>
                <div className="phone-country-select">
                  <div className="country-select">
                    <CountrySelect size="large" labels={en} isRequired={true} />
                  </div>
                  <Form.Item
                    style={{ width: "100%" }}
                    rules={[
                      {
                        required: true,
                        message: "",
                      },
                      {
                        min: 6,
                        message: "Minimum 6 digits required",
                      },
                      {
                        max: 15,
                        message: "Phone Number Can't be more than 15 digits",
                      },
                    ]}
                    name="mobileNo"
                  >
                    <StyledInput
                      type="number"
                      placeholder="Mobile Number *"
                      className="input"
                      // prefix={<MdCall />}
                      onKeyDown={(e) => {
                        if (restrictedKeys.includes(e?.key)) {
                          e.preventDefault();
                          return;
                        }
                      }}
                      value={form.getFieldValue("mobileNo")}
                      onChange={handlePhoneInputChange}
                      min={0}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="input-container">
                <label>Country</label>
                <Form.Item name="country">
                  <Select
                    showSearch
                    className="input"
                    filterOption={(input, option) =>
                      (option?.value ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={countries}
                  />
                </Form.Item>
              </div>
              <div className="input-container">
                <label>Address</label>
                <Form.Item
                  name="address"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Input type="text" className="input" />
                </Form.Item>
              </div>

              <div className="input-container">
                <label>Password</label>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                  ]}
                >
                  <Input
                    type={showPassword ? "text" : "password"}
                    className="input"
                    suffix={
                      showPassword ? (
                        <EyeInvisibleOutlined
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <EyeOutlined
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      )
                    }
                  />
                </Form.Item>
              </div>

              <div className="input-container">
                <label>Confirm Password</label>
                <Form.Item
                  name="confirmPassword"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Password doesn't match")
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    className="input"
                    suffix={
                      showConfirmPassword ? (
                        <EyeInvisibleOutlined
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <EyeOutlined
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      )
                    }
                  />
                </Form.Item>
              </div>
            </div>
            <StyledButton htmlType="submit" loading={isLoading}>
              Sign Up
            </StyledButton>
            <div className="no-account">
              Already have an account?
              <span className="sign-up" onClick={() => navigate(ROUTES.LOGIN)}>
                Login Now
              </span>
            </div>
          </Form>
        </div>
        <div className="right-container">
          <div className="card-container">
            <img src="../Images/travel_pack.jpg" alt="register" />
          </div>
        </div>
      </div>
    </SignUp2Container>
  );
};

export default Signup2;
