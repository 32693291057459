import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import global, { authApi } from "../Services/Auth";
import themeReducer from "../Services/themeSlice";
import { visaApi } from "../Services/Visa";
import { userApi } from "../Services/Users";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    commonRed: global,
    theme: themeReducer,
    [authApi.reducerPath]: authApi.reducer,
    [visaApi.reducerPath]: visaApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
      .concat(authApi.middleware)
      .concat(visaApi.middleware)
      .concat(userApi.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
