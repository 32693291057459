import React from "react";
import { VisaTypeContainer } from "./VisaType.styles";
import { Tabs } from "antd";

const VisaType = ({ country, visaCategories }) => {
  const onChange = (key) => {
    console.log(key);
  };
  const { innerWidth } = window;

  const items =
    visaCategories?.visaCategories?.length > 0
      ? visaCategories.visaCategories.map((item, i) => ({
          key: i.toString(),
          label: (
            <div className="label">
              <h2 className="label-heading">{item?.name}</h2>
              <div className="description">
                Visa valid for {item?.validity} days
              </div>
            </div>
          ),
          children: (
            <div className="content-container">
              <div className="content">{item?.description}</div>
              <h3 className="content-heading">Required Documents</h3>
              <ul>
                {item?.requiredDocument?.map((item, i) => {
                  return <li>{item}</li>;
                })}
              </ul>
              <h3 className="content-heading">
                How to Apply for an {country} tourist e-visa?
              </h3>
              <div className="content">
                Fantastic! {country} allows you to apply for an e-Visa
                (Electronic Visa).
                {/* and now, you can easily apply for this eVisa
                through our website following 3 simple steps below: */}
              </div>
              {/* <div className="card-container">
                <div className="card">
                  <img
                    src="/Images/fill-form.png"
                    alt="fill form"
                    className="image"
                  />
                  <p className="card-heading">Apply Online</p>
                  <div className="sub-content">
                    Fill in the online form on our website.
                  </div>
                </div>
                <div className="card">
                  <img
                    src="/Images/FeesIcon.png"
                    alt="fill form"
                    className="image"
                  />
                  <p className="card-heading">Pay The visa Fees Online</p>
                  <div className="sub-content">
                    You can pay by credit card or debit card, Paypal or bank
                    transfer.
                  </div>
                </div>
                <div className="card">
                  <img
                    src="/Images/ResultIcon.png"
                    alt="fill form"
                    className="image"
                  />
                  <p className="card-heading">Get Your e-Visa</p>
                  <div className="sub-content">
                    e-Visa will be sent to your email.
                  </div>
                </div>
              </div> */}
              <h3 className="content-heading">Eligibility Criteria</h3>
              <ul>
                {item?.eligibilityCriteria?.map((item, i) => {
                  return <li>{item}</li>;
                })}
              </ul>
            </div>
          ),
        }))
      : [];

  return (
    <VisaTypeContainer>
      <h2 className="heading">{country} Visa Type</h2>
      <div className="tab-container">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          type="card"
          tabPosition={innerWidth >= 575 ? "left" : "top"}
        />
      </div>
    </VisaTypeContainer>
  );
};

export default VisaType;
