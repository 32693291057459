import { createGlobalStyle } from "styled-components";
import { deviceQuery } from "./Utils/MediaSizes";

export const GlobalStyles = createGlobalStyle`

 .button{
  font-family: 'Roboto', sans-serif;
  transition: all 0.5s ease 0s;
 }

 .ant-tooltip > .ant-tooltip-arrow::before{
    background-color: ${(props) => props.theme.colors.blue};
 }
 .ant-tooltip > .ant-tooltip-content > .ant-tooltip-inner {
          padding: 6px 25px !important;
    background-color: ${(props) => props.theme.colors.blue};
  }

  .ant-pagination > .ant-pagination-item-active {
    border-color: ${(props) => props.theme.colors.blue};
  }

.ant-pagination > .ant-pagination-item-active :hover {
  color: ${(props) => props.theme.colors.blue} !important;
  border-color: ${(props) => props.theme.colors.blue} !important;
}
.ant-pagination > .ant-pagination-item-active > a {
  color: ${(props) => props.theme.colors.blue};
}
.ant-pagination > .ant-pagination-item-active > a :hover {
  color: ${(props) => props.theme.colors.blue} !important;
  border-color: ${(props) => props.theme.colors.blue} !important;
}

.ant-pagination-item-active {
    background: ${(props) => props.theme.colors.bodyColor};
  }

  .ant-pagination-item-active:hover,
  .ant-pagination-item-container .ant-pagination-item-link-icon {
    border-color: ${(props) => props.theme.colors.violet};
  }

  .ant-pagination .ant-pagination-item a,
  .ant-pagination
    .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination
    .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-ellipsis,
  .ant-pagination
    .ant-pagination-jump-next
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination
    .ant-pagination-jump-prev
    .ant-pagination-item-container
    .ant-pagination-item-link-icon,
  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination .ant-pagination-next .ant-pagination-item-link,
  .ant-table-column-sorter,
  .ant-pagination-total-text {
    color: ${(props) => props.theme.colors.descriptionColor};
  }

    /* header Notification style */
    .notification-dropdown-container{
    max-height: 70vh;
    overflow: auto;
   }
   .read {
    background-color: rgba(0, 0, 0, 0.06) !important;
    opacity: 0.8 !important;
  }
   .ant-menu-submenu > .ant-menu > .notification-dropdown > .notification-dropdown-container> .ant-menu-item {
    max-width: 400px !important;
    height: auto ;
  }
  .notification-header{
    display: flex;
    gap: 10px;
    .logo{
      border: 1px solid ${(props) => props.theme.colors.violet};
      border-radius: 50%;
      margin-top: 2px;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
      padding:4px;
      height: 28px;
    }
   .logo path {
    fill: ${(props) => props.theme.colors.greyTitle};
  }
    .heading{
      color: ${(props) => props.theme.colors.violet};
    font-weight: 600;
    font-size: 18px;
    }
  }
  .mark-all{
    display: flex !important;
    justify-content: center;
    color: ${(props) => props.theme.colors.violet};
    font-weight: 500;
    span{
      margin-right: 4px;
    }
  }
  .description-container{
    display: flex;
    .divider-container{
      display: flex;
      align-items: center;
      .ant-divider{
        height: 90%;
        width: 1px;
        border-radius: 20px;
        background-color: ${(props) => props.theme.colors.gray};
      }
    }
    .notification-description-text{
      line-height: 21px;
    }
  }
  /* ------------end-------- */

@media (${deviceQuery.tabletS}) {
  .ant-picker-panel-container
      > .ant-picker-panel-layout
      > div
      > .ant-picker-panels {
      flex-direction: column !important;
    }
  }
`;
