import React from "react";
import { CommonVisaCardContainer } from "./CommonVisaCard.styles";
import ReactCountryFlag from "react-country-flag";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Constants/Routes";

const CommonVisaCard = ({ item, documentData }) => {
  const navigate = useNavigate();
  return (
    <CommonVisaCardContainer
      onClick={() =>
        navigate(`${ROUTES.COUNTRYDETAILS}/${item?.name}/${item?.id}`)
      }
      >
      <div className="image-container">
        <img src={documentData?.path} alt={item?.name} className="image"></img>
      </div>
      <div className="content-container">
        <div className="country-flag-container">
          <ReactCountryFlag
            countryCode={item?.countryCode}
            svg
            className="country-flag"
          />
        </div>
        <div className="country-name-container">
          <h5>
            <div className="country-name">{item?.name}</div>
          </h5>
        </div>
        {/* <div className="content">{item?.description}</div>
        <div
          className="read-more"
          onClick={() =>
            navigate(`${ROUTES.COUNTRYDETAILS}/${item?.name}/${item?.id}`)
          }
        >
          Read More
        </div> */}
      </div>
    </CommonVisaCardContainer>
  );
};

export default CommonVisaCard;
