export const theme = {
  dark: {
    colors: {
      black: "#000",
      black2: "#181d19",
      white: "#fff",
      lightGray: "#343a46",
      gray: "#898a9c",
      gray2: "#eaeff5",
      gray3: "#8093a8",
      gray4: "#66A4F4",
      gray5: "#e0e0e0",
      blue: "#0067ed",
      blueLighter: "rgba(0, 111, 237,0.6)",
      lightBlue: "#f5faff",
      darkBlue: "#1d2143",
      darkBlue2: "#1f2437",
      darkBlue3: "#1a1f31",
      buttonBorderColor: "rgba(0, 43, 92, 0.08)",
      borderColor2: "rgba(0, 43, 92, 0.04)",
      borderColor3: "rgba(255,255,255,.08)",
      cardBackground: "rgba(255,255,255,.04)",
      whiteFade: "rgba(255,255,255,.90)",
      grayBackground: "#f5faff",
      red: "red",
      green: "green",
    },
    shadow: {
      cardShadow: "0px 4px 20px 0px rgb(238 238 238 / 5)",
      cardShadow2: "0 11px 13px 0 rgba(18, 29, 39, 0.5)",
      cardShadow3: "0 0px 10px 0 rgba(18, 29, 39, 0.12);",
      subMenuShadow: "0 0 10px 0 rgba(0, 43, 92, 0.08)",
    },
  },
  light: {
    colors: {
      black: "#000",
      black2: "#181d19",
      white: "#fff",
      lightGray: "#343a46",
      gray: "#898a9c",
      gray2: "#eaeff5",
      gray3: "#8093a8",
      gray4: "#66A4F4",
      gray5: "#e0e0e0",
      blue: "#0067ed",
      blueLighter: "rgba(0, 111, 237,0.6)",
      lightBlue: "#f5faff",
      darkBlue: "#1d2143",
      darkBlue2: "#1f2437",
      darkBlue3: "#1a1f31",
      buttonBorderColor: "rgba(0, 43, 92, 0.08)",
      borderColor2: "rgba(0, 43, 92, 0.04)",
      borderColor3: "rgba(255,255,255,.08)",
      cardBackground: "rgba(255,255,255,.04)",
      whiteFade: "rgba(255,255,255,.90)",
      grayBackground: "#f5faff",
      red: "red",
      green: "green",
    },
    shadow: {
      cardShadow: "0px 4px 20px 0px rgb(238 238 238 / 5)",
      cardShadow2: "0 11px 13px 0 rgba(18, 29, 39, 0.5)",
      cardShadow3: "0 0px 10px 0 rgba(18, 29, 39, 0.12);",
      subMenuShadow: "0 0 10px 0 rgba(0, 43, 92, 0.08)",
    },
  },
};
