import React from "react";
import { AboutUsHeaderContainer } from "./AboutUsHeader.styles";
import { Breadcrumb } from "antd";
import { ROUTES } from "../../../../Constants/Routes";

const AboutUsHeader = () => {
  const breadCrumbItems = [
    {
      title: "Home",
      href: `${ROUTES.HOME}`,
    },
    {
      title: "About Us",
    },
  ];
  return (
    <AboutUsHeaderContainer>
      <div className="main-about-us-container">
        <h2>About Us</h2>
        <Breadcrumb items={breadCrumbItems} />
      </div>
    </AboutUsHeaderContainer>
  );
};

export default AboutUsHeader;
