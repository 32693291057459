import "./App.css";
import { ThemeProvider } from "styled-components";
import AppRoutes from "./Routes/AppRoutes";
import { useSelector } from "react-redux";
import { selectIsDarkMode } from "./Services/themeSlice";
import { GlobalStyles } from "./App.styles";
import { theme } from "./Utils/Theme";
import WidgetHeader from "./Common/Components/WidgetHeader/WidgetHeader";
import Header from "./Common/Components/Header/Header";
import Footer from "./Common/Components/Footer/Footer";
import { useLocation } from "react-router-dom";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  BarElement,
  ArcElement,
} from "chart.js";
import AuthHeader from "./Common/Components/AuthHeader/AuthHeader";
import { ROUTES } from "./Constants/Routes";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

function App() {
  const isDarkMode = useSelector(selectIsDarkMode);
  const params = useLocation();

  ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ArcElement
  );

  const currentRoute = params.pathname;
  const routesWithHeadersAndFooters = [
    ROUTES.HOME,
    ROUTES.ALLVISA,
    ROUTES.CHECKSTATUS,
    ROUTES.VISAPROCESS,
    ROUTES.FAQ,
    ROUTES.ABOUTUS,
    ROUTES.CONTACTUS,
    ROUTES.COUNTRYDETAILS,
    ROUTES.APPLICATIONFORM,
  ];
  const shouldDisplayHeaderAndFooter =
    routesWithHeadersAndFooters.includes(currentRoute) ||
    currentRoute.includes(ROUTES.COUNTRYDETAILS);

  return (
    <div className="App">
      <ThemeProvider theme={isDarkMode === false ? theme.light : theme.dark}>
        <GlobalStyles />
        {!params.pathname.includes("landing-page") && (
          <div className="chat-widget">
            <TawkMessengerReact
              propertyId="65e1fb189131ed19d973a2dd"
              widgetId="1hntbpme8"
            />
          </div>
        )}
        {params.pathname.includes("auth") && <AuthHeader />}
        {shouldDisplayHeaderAndFooter && <WidgetHeader />}
        {shouldDisplayHeaderAndFooter && <Header />}
        <AppRoutes />
        {shouldDisplayHeaderAndFooter && <Footer />}
      </ThemeProvider>
    </div>
  );
}

export default App;
