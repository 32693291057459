import React, { useEffect, useState } from "react";
import { StyledButton, WidgetHeaderContainer } from "./WidgetHeader.styles";
import { FaPhoneAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Constants/Routes";
const WidgetHeader = () => {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(window.scrollY);
  const handleScroll = () => setScroll(window.scrollY);
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);
  return (
    <WidgetHeaderContainer scroll={scroll}>
      <div className="main-widget-container">
        <div className="cont">
          <div className="logo-container">
            <img
              src="/Images/Global_Citizen_Portal_Logo.png"
              alt="logo"
              className="logo"
            />
          </div>
          <div className="divider" />
          <div className="text">Visa & Immigration Consultants</div>
        </div>
        {/* <div className="divider" /> */}
        <div className="social-container">
          {/* <div className="social-icons">
            <FaFacebookF />
            <FaTwitter />
            <TiSocialGooglePlus className="google" />
            <FaLinkedinIn />
          </div> */}
          <StyledButton
            className="button shine"
            onClick={() => navigate(ROUTES.CHECKSTATUS)}
          >
            Check Visa Status
          </StyledButton>
          <StyledButton
            className="button shine"
            onClick={() => navigate(ROUTES.LOGIN)}
          >
            Login
          </StyledButton>
        </div>
        {/* <div className="divider" />
        <div className="phone">
          <FaPhoneAlt /> +44 7961773985
        </div> */}
      </div>
    </WidgetHeaderContainer>
  );
};

export default WidgetHeader;
