import { message } from "antd";
import * as FileSaver from "file-saver";
import { isJwtExpired } from "jwt-check-expiration";
import * as XLSX from "xlsx";

export const scrollToTop = (value) => {
  window.scrollTo({
    top: value,
    behavior: "instant",
  });
};

export const checkIfLogin = () => {
  const token = sessionStorage.getItem("accessToken");
  return !!token && !isJwtExpired(token);
};

export const restrictedKeys = ["+", "-", "e", "."];

export const handleFieldError = (error) => {
  if (error?.data?.message) {
    if (error?.data?.message.includes("<EOL>")) {
      const cleanedMessage = error.data.message.replace(/<EOL>/g, "\n");
      const startIndex = cleanedMessage.indexOf("{");
      const endIndex = cleanedMessage.lastIndexOf("}");
      const errorMessageString = cleanedMessage.substring(
        startIndex,
        endIndex + 1
      );
      const errorObject = JSON.parse(errorMessageString);
      if (errorObject && errorObject.errors && errorObject.errors.length > 0) {
        errorObject.errors.forEach((error) => {
          message.error(`${error.fieldError}`);
        });
      }
    } else {
      message.error(error?.data?.message);
    }
  } else {
    message.error("Something Went wrong");
  }
};

export const truncateText = (text, length) => {
  if (text.length > length) {
    return text.slice(0, length) + "...";
  }
  return text;
};

export const textTransform = (str) => {
  return str
    ?.toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const createSpreadsheet = (data) => {
  console.log(data, "check data in xlsx");
  const worksheet = XLSX.utils.json_to_sheet([data]);
  console.log(worksheet, "check worksheet");
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  return new Blob([excelBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
};

export const exportToExcel = async (
  excelData,
  fileType,
  fileName,
  fileExtension
) => {
  console.log(excelData, fileType, fileName, fileExtension, "check input Data");
  const ws = XLSX.utils.json_to_sheet([excelData]);
  console.log(ws, "check ws");
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
  console.log(data, "final data check");
};
