import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const AboutCompanyContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  background-color: ${(props) => props.theme.colors.lightBlue};
  @media (${deviceQuery.mobileSM}) {
    padding: 20px 10px;
  }
  .main-container {
    max-width: 1170px;
    display: flex;
    gap: 40px;
    @media (${deviceQuery.laptopML}) {
      flex-direction: column;
      align-items: center;
    }
    .image-container {
      position: relative;
      width: 50%;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      @media (${deviceQuery.laptopML}) {
        width: 60%;
      }
      @media (${deviceQuery.mobileSM}) {
        width: 90%;
        min-height: 300px;
        max-height: 350px;
      }
      .image-1 {
        grid-row: span 2;
      }
      .image {
        width: 100%;
        height: auto;
        @media (${deviceQuery.mobileSM}) {
          height: 100%;
          max-height: 350px;
        }
      }
      .video-button {
        position: absolute;
        bottom: 20%;
        right: 30%;
        font-family: "Roboto", sans-serif;
        background-color: ${(props) => props.theme.colors.blue};
        color: ${(props) => props.theme.colors.white};
        transition: all 0.3s ease 0s;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0px;
        padding: 15px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        cursor: pointer;
        @media (${deviceQuery.tabletM}) {
          padding: 15px;
          right: 20%;
        }
        @media (${deviceQuery.mobileSM}) {
          font-size: 14px;
          line-height: 24px;
          right: 30%;
        }
        @media (${deviceQuery.mobileS}) {
          padding: 10px;
          right: 15%;
        }
      }
    }
    .content-container {
      width: 50%;
      @media (${deviceQuery.laptopML}) {
        width: 95%;
      }
      .heading-section {
        font-family: "Roboto", sans-serif;
        .heading > h5 {
          text-transform: uppercase;
          color: ${(props) => props.theme.colors.blue};
          text-transform: uppercase;
          font-size: 13px;
          line-height: 13px;
          letter-spacing: 1px;
          font-weight: 500;
          margin-bottom: 5px;
        }
        .sub-heading {
          font-weight: 300;
          text-transform: capitalize;
          font-size: 40px;
          line-height: 50px;
          margin-bottom: 0;
          color: ${(props) => props.theme.colors.darkBlue};
          .experienced-agent {
            font-weight: 600;
          }
          @media (${deviceQuery.mobileSM}) {
            font-size: 24px;
            line-height: 30px;
          }
        }
      }
      .content {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.2px;
        color: ${(props) => props.theme.colors.gray};
        font-weight: 500;
        margin-top: 15px;
        padding-bottom: 5px;
        text-align: justify;
        @media (${deviceQuery.mobileSM}) {
          font-size: 14px;
          line-height: 24px;
        }
      }
      .sub-content {
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        letter-spacing: 0px;
        color: ${(props) => props.theme.colors.gray};
      }
    }
  }
`;
