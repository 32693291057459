import OTPInput from "react-otp-input";
import styled from "styled-components";
import { deviceQuery } from "../../Utils/MediaSizes";

export const VerifyOtpContainer = styled.div`
  margin: 30px auto;
  .main-container {
    display: flex;
    justify-content: center;
    padding: 20px 0px;

    @media (${deviceQuery.tabletL}) {
      margin-top: 95px;
    }

    .right-container {
      width: 50%;
      display: flex;
      justify-content: center;
      @media (${deviceQuery.tabletL}) {
        width: 70%;
      }
      @media (${deviceQuery.mobileSM}) {
        width: 90%;
      }
      .card {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
        background-color: ${(props) => props.theme.colors.lightBlue};
        box-shadow: ${(props) => props.theme.shadow.cardShadow2};
        border-radius: 8px;
        padding: 20px;
        @media (${deviceQuery.laptopS}) {
          width: 100%;
        }
        .heading {
          font-weight: 600;
          text-align: center;
          font-size: 24px;
          line-height: 24px;
          color: ${(props) => props.theme.colors.blue};
          text-transform: capitalize;
          margin-bottom: 0px;
        }
        .sub-heading {
          font-weight: 600;
          text-align: center;
          font-size: 18px;
          line-height: 18px;
          color: ${(props) => props.theme.colors.darkBlue};
          text-transform: capitalize;
          margin-top: 15px;
          margin-bottom: 15px;
        }
        .button {
          margin: 20px 0px;
        }
      }
    }
  }
  .otpContainer {
    max-width: 400px;
    margin-top: 40px;
    @media (${deviceQuery.mobileMS}) {
      max-width: 220px;
      div {
        gap: 12px !important;
      }
    }
  }
  .resend-text {
    width: 100%;
    text-align: right;
    padding: 10px 0px 4px 0px;
    color: ${(props) => props.theme.colors.violet};
    .resend {
      margin-right: 4px;
    }
    .send-otp {
      border: none;
      color: ${(props) => props.theme.colors.violet};
      background-color: ${(props) => props.theme.colors.backgroundColor};
      padding: 0px;
      height: 10px;
      &:hover {
        color: ${(props) => props.theme.colors.blue};
      }
    }
    .timer {
      color: ${(props) => props.theme.colors.descriptionColor};
    }
  }
`;

export const StyledOtpInput = styled(OTPInput)`
  input {
    color: ${(props) => props.theme.colors.greyTitle4};
    border: none;
    border-bottom: 1px solid ${(props) => props.theme.colors.greyTitle4};
    border-radius: 0;
  }
`;
