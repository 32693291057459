import styled from "styled-components";
import { deviceQuery } from "../../../Utils/MediaSizes";

export const ApplyingStepsContainer = styled.div`
  position: relative;
  background-color: #dbedf2;
  width: 100%;
  display: flex;
  justify-content: center;
  .heading-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .sub-heading {
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.blue};
      text-transform: uppercase;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 1px;
      font-weight: 500;
      margin-bottom: 5px;
    }
    .heading {
      text-align: center;
      color: ${(props) => props.theme.colors.darkBlue2};
      font-weight: 600;
      font-size: 28px;
      line-height: 46px;
      font-family: "Roboto", sans-serif;
      margin-bottom: 0px;
      .diff {
        color: ${(props) => props.theme.colors.blue};
      }
      @media (${deviceQuery.tabletMS}) {
        font-size: 18px;
        line-height: 12px;
      }
      @media (${deviceQuery.mobileS}) {
        font-size: 16px;
        line-height: 6px;
      }
    }
  }
  .image {
    @media (${deviceQuery.laptopML}) {
      width: 100%;
      height: 100%;
    }
  }
  /* .image{
        height: 800px;
        width: 100vw;
    } */
`;
