import styled from "styled-components";
import { deviceQuery } from "../../Utils/MediaSizes";

export const ApplicationFormContainer = styled.div`
  padding: 40px 0px;
  max-width: 1170px;
  margin: auto;
  .message-animate {
    position: relative;
    animation-name: opac;
    animation-duration: 1s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease;
    @keyframes opac {
      0% {
        left: 200px;
        top: 0px;
      }
      100% {
        left: 0px;
        top: 0px;
      }
    }
  }
  .message {
    width: 100%;
    height: 100%;
    min-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
    @media (${deviceQuery.laptopM}) {
      min-width: 400px;
    }
    @media (${deviceQuery.laptopM}) {
      min-width: 100%;
    }
    .check-button {
      max-width: 220px !important;
    }
    .text {
      margin-bottom: 0;
      color: ${(props) => props.theme.colors.darkBlue};
      font-size: 28px;
      font-weight: 700;
      text-align: center;
      @media (${deviceQuery.mobileSM}) {
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
  .heading {
    font-weight: 700;
    text-align: center;
    font-size: 28px;
    line-height: 28px;
    color: ${(props) => props.theme.colors.darkBlue};
    text-transform: uppercase;
    margin-bottom: 0px;
    .important {
      color: ${(props) => props.theme.colors.blue};
    }
    @media (${deviceQuery.laptopM}) {
      margin-top: 75px;
    }
  }
  .main-container {
    background-color: ${(props) => props.theme.colors.lightBlue};
    box-shadow: 0px 4px 20px 0px rgb(238 238 238 / 5);
    margin: 40px auto;
    max-width: 1170px;
    padding: 20px 60px;
    display: flex;
    gap: 40px;
    justify-content: center;
    @media (${deviceQuery.laptopS}) {
      flex-direction: column;
    }
    @media (${deviceQuery.mobileSM}) {
      padding: 20px 12px;
    }

    .right-container {
      .summary-card-container {
        .card-heading {
        }
        .visa-details-container {
          display: flex;
          flex-direction: column;
          gap: 5px;
          .same-line-content {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }
          .details-container {
            .end-text {
              text-align: right;
            }
            .details-heading {
              margin-top: 0;
              margin-bottom: 5px;
              font-size: 16px;
              @media (${deviceQuery.mobileSM}) {
                font-size: 14px;
              }
            }
            .details {
              display: flex;
              flex-direction: column;
              font-size: 14px;
              gap: 5px;
              @media (${deviceQuery.mobileSM}) {
                font-size: 12px;
              }
            }
          }
        }
        .visa-fee-container {
          .details-heading {
            font-size: 16px;
            margin-bottom: 0;
            @media (${deviceQuery.mobileSM}) {
              font-size: 14px;
            }
          }
          .details {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            gap: 20px;
            @media (${deviceQuery.mobileSM}) {
              font-size: 12px;
            }
          }
        }
        .total-fee-container {
          .details {
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            font-weight: 600;
            color: ${(props) => props.theme.colors.blue};
            @media (${deviceQuery.mobileSM}) {
              font-size: 14px;
            }
          }
        }
        .grand-total-container {
          display: flex;
          justify-content: space-between;
          h5 {
            font-size: 16px;
            font-weight: 600;
            color: ${(props) => props.theme.colors.blue};
            @media (${deviceQuery.mobileSM}) {
              font-size: 14px;
            }
          }
        }
        .sub-container {
          border-bottom: 1px solid gray;
          padding: 8px 0;
        }
        .promo-code-container {
          display: none;
          .input {
            background-color: ${(props) => props.theme.colors.white};
            height: 50px;
            border-radius: 0;
            border: 1px solid ${(props) => props.theme.colors.darkBlue};
            border-right: none;
            font-weight: 500;
            font-size: 16px;
            text-transform: uppercase;
            @media (${deviceQuery.mobileSM}) {
              font-size: 14px;
            }
            &::placeholder {
              font-size: 14px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.6);
              border-color: rgba(255, 255, 255, 0.06);
              @media (${deviceQuery.mobileSM}) {
                font-size: 12px;
              }
            }
          }
          .promo-btn {
            border-radius: 0px !important;
            height: 50px;
            background-color: ${(props) => props.theme.colors.grayBackground};
            border: 1px solid ${(props) => props.theme.colors.darkBlue};
            border-radius: 0px;
            color: ${(props) => props.theme.colors.darkBlue};
            transition: all 0.5s ease 0s;
            width: 150px;
            span {
              font-size: 16px;
              font-weight: 500;
              font-family: "Roboto", sans-serif;
              @media (${deviceQuery.mobileSM}) {
                font-size: 14px;
              }
            }
            &:hover {
              color: ${(props) => props.theme.colors.white} !important;
              background-color: ${(props) => props.theme.colors.darkBlue};
            }
          }
        }
      }
    }
  }
`;
