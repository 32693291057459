import styled from "styled-components";

export const AuthHeaderContainer = styled.div`
  box-shadow: ${(props) => props.theme.shadow.cardShadow};
  padding: 12px 40px;
  z-index: 10;
  .logo-container {
    width: 250px;
    height: 45px;
    cursor: pointer;
    .logo {
      width: 100%;
      height: 100%;
    }
  }
`;
