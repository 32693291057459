import React from "react";

export const Plane = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23.688"
      height="23.685"
      viewBox="0 0 23.688 23.685"
    >
      <path
        id="ec3794ac5298f1e4ced5f77fa21b00dc"
        d="M22.621,1.5a2.762,2.762,0,0,0-1.963.818l-4.57,4.562L4.99,4.9,2.372,7.521l9.776,3.3L6.518,16.479,4.329,16.02,1.711,18.638l4.607,1.937,1.939,4.607,2.622-2.618-.448-2.17,5.656-5.656,3.324,9.758,2.618-2.618L20.064,10.761l4.52-4.52A2.779,2.779,0,0,0,22.621,1.5Z"
        transform="translate(-1.711 -1.496)"
        fill="#0067ed"
      />
    </svg>
  );
};
