import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../Utils/BaseQuery";

export const visaApi = createApi({
  reducerPath: "visaApi",
  baseQuery: rtkBaseQuery,
  tagTypes: ["visaCategory", "visaInsurance"],
  endpoints: (builder) => ({
    applyVisa: builder.mutation({
      query: (data) => {
        return { url: `api/v1/applyNow`, body: data, method: "POST" };
      },
    }),
    getAllVisa: builder.mutation({
      query: (data) => {
        return { url: `api/v1/visa/all`, body: data, method: "POST" };
      },
    }),
    getUserVisa: builder.query({
      query: (userId) => {
        return { url: `api/v1/visa/${userId}` };
      },
    }),
    changeVisaStatus: builder.mutation({
      query: (data) => {
        return { url: `api/v1/status`, params: data, method: "PUT" };
      },
    }),
    getDashboardData: builder.query({
      query: () => {
        return { url: `api/v1/dashboard` };
      },
    }),
    getVisaStatusByMobileNo: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/visaStatusByMobileNo`,
          body: data,
          method: "POST",
        };
      },
    }),
    checkout: builder.mutation({
      query: (data) => {
        return {
          url: `/api/v1/checkout`,
          params: data,
          method: "POST",
        };
      },
    }),
    transactionSuccess: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/success/${data}`,
          method: "POST",
        };
      },
    }),

    transactionCancel: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/cancel/${data}`,
          method: "POST",
        };
      },
    }),
    getVisaDetailAll: builder.query({
      query: () => {
        return { url: `api/v1/visaDetail/all` };
      },
      providesTags: ["visaCategory"],
    }),
    getCountryVisaDetails: builder.query({
      query: (data) => {
        return { url: `api/v1/visaDetail/${data}` };
      },
    }),
    addVisaDetail: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/visaDetail/`,
          body: data,
          method: "POST",
        };
      },
      invalidatesTags: ["visaCategory"],
    }),
    editVisaDetail: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/visaDetail/`,
          body: data,
          method: "PUT",
        };
      },
      invalidatesTags: ["visaCategory"],
    }),
    getVisaCategoryDetails: builder.query({
      query: (data) => {
        return { url: `api/v1/visaDetail/config/${data}` };
      },
      providesTags: ["visaCategory"],
    }),
    addInsuranceDetail: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/travel/`,
          body: data,
          method: "POST",
        };
      },
    }),
    editInsuranceDetail: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/travel/`,
          body: data,
          method: "PUT",
        };
      },
      invalidatesTags: ["visaInsurance"],
    }),
    getAllInsurance: builder.mutation({
      query: (data) => {
        return { url: `api/v1/travel/all`, body: data, method: "POST" };
      },
    }),
    getVisaInsuranceDetails: builder.query({
      query: (data) => {
        return { url: `api/v1/travel/${data}` };
      },
      providesTags: ["visaInsurance"],
    }),
    getDocuments: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/document`,
          body: data,
          method: "POST",
        };
      },
    }),
  }),
});

export const {
  useApplyVisaMutation,
  useGetAllVisaMutation,
  useGetUserVisaQuery,
  useChangeVisaStatusMutation,
  useGetDashboardDataQuery,
  useGetVisaStatusByMobileNoMutation,
  useCheckoutMutation,
  useTransactionCancelMutation,
  useTransactionSuccessMutation,
  useGetVisaDetailAllQuery,
  useGetCountryVisaDetailsQuery,
  useAddVisaDetailMutation,
  useEditVisaDetailMutation,
  useGetVisaCategoryDetailsQuery,
  useAddInsuranceDetailMutation,
  useEditInsuranceDetailMutation,
  useGetAllInsuranceMutation,
  useGetVisaInsuranceDetailsQuery,
  useGetDocumentsMutation,
} = visaApi;
