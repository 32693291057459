import styled from "styled-components";
import { deviceQuery } from "../../Utils/MediaSizes";

export const SignUp2Container = styled.div`
  background: rgb(199, 236, 255);
  background: linear-gradient(
    90deg,
    rgba(199, 236, 255, 1) 0%,
    rgba(214, 242, 254, 1) 36%,
    rgba(235, 248, 254, 1) 100%
  );
  padding: 10px 0px;
  display: flex;
  align-items: center;
  min-height: 87vh;
  .main-container {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    background-color: ${(props) => props.theme.colors.white};
    box-shadow: ${(props) => props.theme.shadow.cardShadow2};
    border-radius: 8px;
    padding: 10px;
    margin: 0 auto;
    @media (${deviceQuery.laptopL}) {
      width: 85%;
    }
    @media (${deviceQuery.laptopM}) {
      width: 95%;
      gap: 20px;
    }
    @media (${deviceQuery.tabletM}) {
      flex-direction: column;
      width: 80%;
    }
    @media (${deviceQuery.mobileSM}) {
      padding: 10px;
      width: 90%;
    }
    .left-container {
      width: 55%;
      @media (${deviceQuery.tabletM}) {
        width: 100%;
        z-index: 1;
      }
      .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 20px;
        @media (${deviceQuery.mobileSM}) {
          padding: 10px;
        }

        .heading {
          width: 100%;
          font-weight: 500;
          text-align: center;
          font-size: 28px;
          line-height: 28px;
          color: ${(props) => props.theme.colors.darkBlue};
          text-transform: capitalize;
        }

        .input-select-container {
          display: grid;
          gap: 40px;
          row-gap: 0;
          grid-template-columns: repeat(2, 1fr);
          width: 100%;
          @media (${deviceQuery.mobileSM}) {
            grid-template-columns: 1fr;
          }
        }

        .phone-country-select {
          display: flex;
          align-items: start;
          width: 100%;
          .country-select {
            width: 35%;
            .ant-select {
              height: 38px !important;
            }
            .ant-select-selector
              > .ant-select-selection-search
              > .ant-select-selection-search-input {
              height: 38px !important;
            }
            .ant-form-item {
              .ant-form-item-explain > .ant-form-item-explain-error {
                margin-top: 10px !important;
                padding: 2px !important;
                font-size: 13px !important;
              }
            }
          }
        }
        .input-container {
          label {
            margin-bottom: 8px;
            /* font-weight: 500; */
            font-size: 14px;
            color: ${(props) => props.theme.colors.darkBlue};
          }
        }

        .ant-form-item {
          margin: 0px;
          width: 100%;
          height: 60px;
        }
        .input {
          height: 38px;
          border-radius: 0px;
          background-color: ${(props) => props.theme.colors.white};
        }

        .ant-input {
          &::placeholder {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
            border-color: rgba(255, 255, 255, 0.06);
          }
        }
        .forgot-password {
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 14px;
          color: ${(props) => props.theme.colors.darkBlue};
          margin-top: -25px;
          text-align: right;
        }
        .ant-btn {
          margin: 20px 0px;
          max-width: 100% !important;
        }
        .no-account {
          width: 100%;
          text-align: center;
          margin-bottom: 8px;
          font-weight: 500;
          font-size: 14px;
          color: ${(props) => props.theme.colors.darkBlue};
          margin-top: -10px;
          .sign-up {
            color: ${(props) => props.theme.colors.blue};
            margin-left: 6px;
            cursor: pointer;
          }
        }
      }
    }
    .right-container {
      width: 45%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 12px;
      @media (${deviceQuery.tabletM}) {
        display: none;
      }
      .card-container {
        width: 100%;
        min-height: 450px;
        /* background-color: #bce1fb; */
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        /* @media (${deviceQuery.tabletM}) {
          height: 95%;
        } */
        img {
          height: 80%;
          width: 95%;
          /* @media (${deviceQuery.tabletM}) {
            width: 60%;
            height: 50%;
          } */
        }
      }
    }
  }
`;
