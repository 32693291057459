import React, { useEffect, useState } from "react";
import TextArea from "antd/es/input/TextArea";
import { Form, Select, Tooltip, message } from "antd";
import { StyledInput } from "../../../../Utils/Global.styles";
import CountrySelect from "../../../../Common/Components/CountrySelect/CountrySelect";
import { useForm } from "antd/es/form/Form";
import en from "react-phone-number-input/locale/en.json";
import { ContactUsFormContainer, StyledButton } from "./ContactUsForm.styles";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
import { TiSocialGooglePlus } from "react-icons/ti";
import Dragger from "antd/es/upload/Dragger";
import { handleFieldError, restrictedKeys } from "../../../../Utils/Common";
import { useContactUsMutation } from "../../../../Services/Users";
import { Country } from "country-state-city";
import axios from "axios";
import { config } from "../../../../Utils/Config";

const ContactUsForm = () => {
  const [form] = useForm();
  const [fileList, setFileList] = useState([]);
  const [contactUs, { isLoading }] = useContactUsMutation();
  const [countries, setCountries] = useState([]);
  const handleOnChange = (e, index) => {
    if (e.file.status === "uploading" || e.file.status === "done") {
      setFileList(e.fileList);
      if (e.file.status === "done") {
        message.success(`${e.file.name} file uploaded successfully`);
      }
    } else if (e.file.status === "error") {
      message.error(`${e.file.name} file upload failed.`);
    }
  };

  const uploadImageFn = async (options, i) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const configg = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: sessionStorage.getItem("token"),
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("file", file);
    try {
      const res = await axios.put(
        `${
          config.BASE_URL
        }api/v1/contact/uploadFile?documentName=${"contact document"}`,
        fmData,
        configg
      );
      onSuccess("Ok");
    } catch (err) {
      message.error(err?.data?.message);
      onError({ err });
    }
  };
  const onFinish = async (values) => {
    try {
      await contactUs(values).unwrap();
      message.success("Request Submitted Successfully");
    } catch (error) {
      handleFieldError(error);
    }
  };

  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 15) {
      form.setFieldValue("mobileNo", inputValue);
    } else {
      const truncatedInput = inputValue.slice(0, 15);
      form.setFieldValue("mobileNo", truncatedInput);
    }
  };

  useEffect(() => {
    setCountries(
      Country.getAllCountries()?.map((item, i) => {
        return {
          key: i,
          label: item?.name,
          value: item?.name,
          isoCode: item?.isoCode,
        };
      })
    );
  }, []);

  return (
    <ContactUsFormContainer>
      <div className="left-container">
        <h5 className="small-heading">WHY CHOOSE US</h5>
        <h2 className="container-heading">
          Have Be Any Question?{" "}
          <span className="diff">Feel Free To Contact With Us</span>
        </h2>
        <div className="content">
          <p>
            The Most Eminent Visas and Immigration Consultant service provider.
          </p>
          <p className="small-text">
            Established in 2019! our foundation began with a modest, yet
            impactful idea conceived by our founders. Skillfully executing this
            vision, we've grown to embody excellence in our endeavors.
          </p>
        </div>
        <div className="social-list-container">
          <Tooltip title="Facebook">
            <div className="social-item">
              <FaFacebookF />
            </div>
          </Tooltip>
          <Tooltip title="Twitter">
            <div className="social-item">
              <FaXTwitter />
            </div>
          </Tooltip>
          <Tooltip title="Google +">
            <div className="social-item">
              <TiSocialGooglePlus className="google" />
            </div>
          </Tooltip>
          <Tooltip title="Linkedin">
            <div className="social-item">
              <FaLinkedinIn />
            </div>
          </Tooltip>
        </div>
      </div>
      <div className="right-container">
        <Form
          className="form"
          onFinish={onFinish}
          form={form}
          validateTrigger={"onBlur"}
        >
          {/* <h3 className="heading">Check Status Online Form</h3> */}
          <div className="input-select-container">
            <Form.Item
              name="firstName"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  max: 50,
                  message: "Maximum 50 characters Allowed",
                },
                {
                  pattern: new RegExp(/^[A-Za-z\s]+$/),
                  message: "Must be a valid name",
                },
              ]}
            >
              <StyledInput
                type="text"
                placeholder="Your First Name *"
                className="input"
                maxLength={50}
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  max: 50,
                  message: "Maximum 50 characters Allowed",
                },
                {
                  pattern: new RegExp(/^[A-Za-z\s]+$/),
                  message: "Must be a valid name",
                },
              ]}
            >
              <StyledInput
                type="text"
                placeholder="Your Last Name *"
                className="input"
                maxLength={50}
              />
            </Form.Item>
            <Form.Item
              name="email"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  type: "email",
                  message: "Must be valid Email",
                },
              ]}
            >
              <StyledInput
                type="text"
                placeholder="Email Address *"
                className="input"
              />
            </Form.Item>{" "}
            <div className="phone-country-select">
              <div className="country-select">
                <CountrySelect size="large" labels={en} isRequired={true} />
              </div>
              <Form.Item
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    min: 6,
                    message: "Minimum 6 digits required",
                  },
                  {
                    max: 15,
                    message: "Phone Number Can't be more than 15 digits",
                  },
                ]}
                name="mobileNo"
              >
                <StyledInput
                  type="number"
                  placeholder="Mobile Number *"
                  className="input"
                  onKeyDown={(e) => {
                    if (restrictedKeys.includes(e?.key)) {
                      e.preventDefault();
                      return;
                    }
                  }}
                  value={form.getFieldValue("mobileNo")}
                  onChange={handlePhoneInputChange}
                  min={0}
                />
              </Form.Item>
            </div>
            <Form.Item name="country" style={{ width: "100%" }}>
              <Select
                showSearch
                type="text"
                placeholder="Country"
                className="input"
                filterOption={(input, option) =>
                  (option?.value ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={countries}
                // onChange={(e) => setDestination(e)}
              />
            </Form.Item>
            <Form.Item
              name="subject"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <StyledInput
                type="text"
                placeholder="Subject *"
                className="input"
              />
            </Form.Item>
            <Form.Item
              name="question"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
              className="help-with"
            >
              <Select
                type="text"
                placeholder="What can we help you with? *"
                className="select input"
                options={[{ label: "Visa Service", value: "visaService" }]}
              />
            </Form.Item>
            <Form.Item
              name="query"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  max: 10000,
                  message: "Maximum 10000 characters Allowed",
                },
              ]}
              className="your-request"
            >
              <TextArea
                placeholder="Message *"
                className="text-area"
                rows={4}
                maxLength={10000}
              />
            </Form.Item>
            <div className="upload-container">
              <Dragger
                maxCount={1}
                fileList={fileList}
                customRequest={(e) => uploadImageFn(e)}
                onChange={(e) => handleOnChange(e)}
                // accept="image/*"
                onRemove={() => setFileList([])}
              >
                <div className="upload-div">
                  <span className="heading">Attach Files</span>
                  <span className="sub-heading">
                    Add or drop your file here.
                  </span>
                </div>
              </Dragger>
            </div>
          </div>
          <StyledButton
            className="button"
            htmlType="submit"
            loading={isLoading}
          >
            Submit Request!
          </StyledButton>
        </Form>
      </div>
    </ContactUsFormContainer>
  );
};

export default ContactUsForm;
