import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const WhyUsContainer = styled.div`
  background: url("../Images/row-bgimage-2.png") center center/cover;
  padding: 40px;
  .main-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .main-heading {
      color: ${(props) => props.theme.colors.white};
      font-weight: 600;
      text-transform: capitalize;
      font-size: 40px;
      line-height: 50px;
      font-family: "Roboto", sans-serif;
      @media (${deviceQuery.mobileSM}) {
        font-size: 28px;
        line-height: 35px;
      }
    }
    .card-container {
      display: flex;
      justify-content: center;
      gap: 30px;
      @media (${deviceQuery.laptopS}) {
        flex-wrap: wrap;
      }
      .odd-card {
        margin-top: 60px;
        @media (${deviceQuery.laptopS}) {
          margin-top: 0;
        }
      }
      .card {
        padding: 55px 30px 45px;
        overflow: hidden;
        background-color: ${(props) => props.theme.colors.cardBackground};
        border: 1px solid ${(props) => props.theme.colors.borderColor3};
        max-width: 260px;
        max-height: 420px;
        transition: 0.5s;
        .logo-container {
          .logo {
            height: 50px;
            width: 50px;
            .image {
              height: 100%;
              width: 100%;
            }
          }
          .divider {
            height: 1px;
            width: 42px;
            background-color: ${(props) => props.theme.colors.cardBackground};
            margin-top: 20px;
          }
        }
        .heading {
          color: ${(props) => props.theme.colors.white};
          text-transform: capitalize;
          font-weight: 500;
          font-size: 19px;
          line-height: 25px;
          font-family: "Roboto", sans-serif;
          margin-bottom: 10px;
          padding-top: 31px;
        }
        .content {
          color: ${(props) => props.theme.colors.whiteFade};
          margin: 0 0 25px;
          font-weight: 400;
          font-size: 15px;
          line-height: 25px;
        }
        .number-container {
          display: flex;
          justify-content: space-between;
          align-items: center;
          .arrow-logo {
            font-size: 20px;
            width: 41px;
            height: 41px;
            line-height: 41px;
            z-index: 2;
            text-align: center;
            position: relative;
            color: rgba(255, 255, 255, 0.08);
            border: 1px solid;
            border-radius: 3px;
            transition: all 0.3s ease 0s;
            background: transparent;
            cursor: pointer;
            &:hover {
              background-color: ${(props) => props.theme.colors.blue};
              border-color: ${(props) => props.theme.colors.blue} !important;
            }
          }
          .number {
            font-size: 60px;
            line-height: 0;
            text-align: right;
            font-weight: bold;
            -webkit-text-stroke: 1px rgba(255, 255, 255, 0.08);
            -webkit-text-fill-color: transparent;
            font-family: "Roboto", sans-serif;
          }
        }
        &:hover {
          transform: translateY(-8px);
          .number-container {
            .arrow-logo {
              color: ${(props) => props.theme.colors.white};
              border-color: white;
            }
            .number {
              -webkit-text-stroke: 1px
                ${(props) => props.theme.colors.whiteFade};
            }
          }
        }
      }
    }
  }
`;
