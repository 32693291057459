import styled from "styled-components";
import { deviceQuery } from "../../Utils/MediaSizes";

export const DashboardContainer = styled.div`
  .ant-layout-sider {
    background-color: ${(props) => props.theme.colors.white} !important;
    box-shadow: ${(props) => props.theme.shadow.cardShadow2} !important;
    .ant-menu {
      background-color: ${(props) => props.theme.colors.white} !important;
      .ant-menu-item {
        color: ${(props) => props.theme.colors.black} !important;
        a {
          transition: none !important;
        }
      }
      .ant-menu-item-selected {
        background: ${(props) => props.theme.colors.blue} !important;
        color: ${(props) => props.theme.colors.white} !important;
      }
    }
    .demo-logo-vertical {
      height: 35px;
      width: 90%;
      margin: 20px auto 45px;
      .image {
        height: 100%;
        width: 100%;
      }
    }
    .demo-logo-vertical-collapsed {
      height: 45px;
      width: 45px;
      margin: 20px auto 45px;
      .image {
        height: 100%;
        width: 100%;
      }
    }
    .trigger {
      position: absolute;
      top: 5px;
      right: -8px;
      border: 1px solid ${(props) => props.theme.colors.white};
      border-radius: 50%;
      cursor: pointer;
      svg {
        height: 25px;
        width: 25px;
      }
    }
    .logout-btn {
      position: fixed;
      bottom: 0;
      left: 0;
      background-color: ${(props) => props.theme.colors.darkBlue};
      color: ${(props) => props.theme.colors.white};
      margin: 8px 0 0;
      height: 40px;
      width: ${(props) => (props?.collapsed ? "80px" : "200px")};
      padding: 8px 0 8px 25px;
      font-weight: 500;
      font-size: 16px;
      transition: all 0.1s;
      cursor: pointer;
      span {
        margin-right: 8px;
      }
    }
  }
  .layout-container {
    @media (${deviceQuery.mobileS}) {
      padding: 12px 12px 70px !important;
    }
  }
  .ant-layout-header {
    display: flex;
    justify-content: end;
    gap: 30px;
    padding: 10px 20px !important;
    .anticon {
      cursor: pointer;
      svg {
        height: 20px;
        width: 20px;
      }
    }
    .profile-container {
      display: flex;
      gap: 8px;
      align-items: start;
      margin-top: 6px;
      cursor: pointer;
      .image-container {
        height: 35px;
        width: 35px;
        border: 1px solid ${(props) => props.theme.colors.gray};
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        .image {
          height: 100%;
          width: 100%;
          padding: 5px;
          border-radius: 50%;
          line-height: 1px;
        }
      }
      .content-container {
        display: flex;
        flex-direction: column;
        gap: 4px;
        line-height: 15px;
        .name {
          color: ${(props) => props.theme.colors.blue};
          font-size: 16px;
          font-weight: 500;
        }
        .sub-text {
          color: ${(props) => props.theme.colors.darkBlue};
          font-size: 13px;
        }
      }
    }
  }
  .ant-table {
    overflow-x: auto;
    .expand-icon {
      cursor: pointer;
      font-size: 16px;
    }
    thead > tr > th,
    thead > tr > td {
      background-color: ${(props) => props.theme.colors.darkBlue} !important;
      color: ${(props) => props.theme.colors.white} !important;
      &::before {
        display: none !important;
      }
    }
    td {
      background-color: ${(props) => props.theme.colors.lightBlue} !important;
      color: ${(props) => props.theme.colors.darkBlue} !important;
    }
  }
  .card-container {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    @media (${deviceQuery.tabletM}) {
      flex-wrap: wrap;
    }
    .card {
      padding: 20px;
      box-shadow: ${(props) => props.theme.shadow.cardShadow2};
      border-radius: 8px;
      width: 33%;
      @media (${deviceQuery.tabletM}) {
        width: 100%;
      }
      .heading-section {
        display: flex;
        gap: 10px;
        align-items: center;
        .icon {
          svg {
            height: 16px;
            width: 16px;
          }
          .image {
            height: 30px;
            width: 30px;
          }
        }
        .name {
          color: ${(props) => props.theme.colors.darkBlue};
          font-size: 16px;
          line-height: 28px;
          margin-bottom: 8px;
          margin-top: 0;
        }
      }
      .value {
        color: ${(props) => props.theme.colors.darkBlue};
        font-weight: 500;
        font-size: 28px;
        line-height: 28px;
        margin-top: 20px;
      }
    }
  }
  .graph-container {
    .heading {
      color: ${(props) => props.theme.colors.darkBlue};
      font-size: 22px;
      line-height: 28px;
      /* margin-bottom: 8px; */
    }
    .graph-canva-container {
      width: 80%;
      margin: auto;
      @media (${deviceQuery.mobileSM}) {
        width: 100%;
      }
      canvas {
        @media (${deviceQuery.mobileSM}) {
          height: 320px !important;
        }
      }
    }
  }
  .ant-pagination {
    text-align: right;
    padding: 10px;
  }
  .status-select {
    min-width: 120px;
  }
  .green {
    .ant-select-selector {
      border-color: ${(props) => props.theme.colors.green};
      color: ${(props) => props.theme.colors.green};
    }
    :hover {
      .ant-select-selector {
        border-color: ${(props) => props.theme.colors.green} !important;
      }
    }
  }
  .red {
    .ant-select-selector {
      border-color: ${(props) => props.theme.colors.red};
      color: ${(props) => props.theme.colors.red};
    }
    :hover {
      .ant-select-selector {
        border-color: ${(props) => props.theme.colors.red} !important;
      }
    }
  }
  .blue {
    .ant-select-selector {
      border-color: ${(props) => props.theme.colors.blue};
      color: ${(props) => props.theme.colors.blue};
    }
  }
  .actionContainer,
  .actionContainer2 {
    width: 70px;
    display: flex;
    gap: 8px;
    align-items: end;
    justify-content: end;
    svg {
      cursor: pointer;
    }
  }
  .actionContainer2 {
    width: 100%;
  }
  .action-container {
    display: flex;
    gap: 14px;
  }
  .editLogo {
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: end;
    cursor: pointer;
  }
  .button-container {
    margin: 20px;
    display: flex;
    justify-content: end;
    .ant-btn {
      max-width: 150px;
    }
  }

  .ant-table-expanded-row > .ant-table-cell {
    padding: 10px 60px;
  }
  .country-select {
    min-width: 150px !important;
  }

  .custom-notification-submenu {
    margin-top: 5px !important;
  }
  .notification-container {
    position: relative;
  }
  .notification-count {
    position: absolute;
    top: -9px;
    left: 58%;
    span {
      background-color: ${(props) => props.theme.colors.red};
      color: ${(props) => props.theme.colors.white};
      font-weight: 500;
      border-radius: 50%;
      padding: 0px 3px;
    }
  }
  .ant-layout-header > .ant-menu {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    padding: 10px;
    .ant-menu-submenu::after {
      display: none !important;
    }
  }
`;

export const ExpandableRowContainer = styled.div`
  .ant-table {
    overflow-x: auto;
    border: 1px solid ${(props) => props.theme.colors.gray};
    thead > tr > th {
      background-color: ${(props) => props.theme.colors.darkBlue} !important;
      color: ${(props) => props.theme.colors.white} !important;
      &::before {
        display: none !important;
      }
    }
    td {
      background-color: ${(props) => props.theme.colors.lightBlue} !important;
      color: ${(props) => props.theme.colors.darkBlue} !important;
    }
  }
`;

export const DropdownWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.backgroundColor}!important;
  color: ${(props) => props.theme.colors.greyTitle}!important;
  .ant-menu-item {
    &:hover {
      color: ${(props) => props.theme.colors.greyTitle}!important;
    }
  }
  .ant-menu-item-group-title {
    color: ${(props) => props.theme.colors.greyTitle4}!important;
  }
`;
