import React from "react";
import { TrustWorthyContainer } from "./TrustWorthy.styles";

const TrustWorthy = () => {
  return (
    <TrustWorthyContainer>
      <h2 className="trustworthy-heading">Your Trustworthy Partner</h2>
      <h3 className="trustworthy-content">
        How convenient and reliable we bring to you?
      </h3>
      <div className="card-container">
        <div className="card card1">
          <div className="image-container">
            <img src="../Images/high-trust.jpg" className="image" alt="" />
            <div className="content-card">
              <h5 className="heading">High-trust Services</h5>
              <div className="text">
                Having been established for over 10 years, Global Citizen Portal
                Team is a prestigious company, particularly in visa service. It
                is our honor that we have served and satisfied over 80,000
                customers from all over the world.
              </div>
            </div>
          </div>
        </div>
        <div className="card card2">
          <div className="image-container">
            <img src="../Images/FeatureRich.jpg" className="image" alt="" />
            <div className="content-card">
              <h5 className="heading">Feature Rich Services</h5>
              <div className="text">
                Offering on-demand reliable and secure Visa services is why our
                customers stay here. We are constantly working to improve our
                services and believe in a long-lasting customer relationship.
              </div>
            </div>
          </div>
        </div>
        <div className="card card3">
          <div className="image-container">
            <img src="../Images/CostEffective.jpg" className="image" alt="" />
            <div className="content-card">
              <h5 className="heading">Cost Effective Pricing</h5>
              <div className="text">
                We provide our services at a rational fee and no hidden charges.
                We strive to keep our operating costs low so we can deliver
                quality Visa services at very reasonable prices for all
                customers.
              </div>
            </div>
          </div>
        </div>
        <div className="card card4">
          <div className="image-container">
            <img src="../Images/CustomerCare.jpg" className="image" alt="" />
            <div className="content-card">
              <h5 className="heading">Customer Care Program</h5>
              <div className="text">
                Our commitment to customer service is the foundation from which
                we continue to grow. Our primary goal is sustaining long-term
                relationships with customers by providing quality services.
              </div>
            </div>
          </div>
        </div>
      </div>
    </TrustWorthyContainer>
  );
};

export default TrustWorthy;
