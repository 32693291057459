import { Button } from "antd";
import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const ExploreBannerContainer = styled.div`
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../Images/mountain-travel.jpg");
  background-position: top;
  background-size: 100% 402px;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  padding-left: 40px;
  gap: 24px;
  .content {
    max-width: 30%;
    color: ${(props) => props.theme.colors.white};
    font-size: 16px;
    @media (${deviceQuery.mobileSM}) {
      font-size: 14px;
      max-width: 70%;
    }
  }
  .heading {
    font-size: 24px;
    font-weight: 600;
    color: ${(props) => props.theme.colors.white};
    @media (${deviceQuery.mobileSM}) {
      font-size: 18px;
    }
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.grayBackground};
  border: 1px solid ${(props) => props.theme.colors.buttonBorderColor};
  border-radius: 30px;
  color: ${(props) => props.theme.colors.darkBlue};
  width: 195px;
  height: 46px;
  font-size: 14px;
  font-weight: 500;
  transition: 0.4s;
  &:hover {
    color: ${(props) => props.theme.colors.white} !important;
    background-color: ${(props) => props.theme.colors.darkBlue} !important;
    border: none;
  }
  @media (${deviceQuery.mobileSM}) {
    width: 150px;
    height: 42px;
  }
`;
