import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const VisaTypeContainer = styled.div`
  .heading {
    color: ${(props) => props.theme.colors.darkBlue};
    margin: 40px 0px;
    font-size: 26px;
    line-height: 36px;
    @media (${deviceQuery.mobileSM}) {
      font-size: 22px;
      line-height: 30px;
    }
  }
  .tab-container {
    margin-bottom: 40px;

    @media (${deviceQuery.mobileSM}) {
      .ant-tabs-nav {
        margin-bottom: 0px;
      }
    }
    .ant-tabs-nav-list {
      border: 1px solid ${(props) => props.theme.colors.gray};
      border-radius: 8px 0px 0px 8px;
      border-right: none;

      @media (${deviceQuery.mobileSM}) {
        border-radius: 8px 8px 0 0;
        border-bottom: none;
        border-right: 1px solid ${(props) => props.theme.colors.gray} !important;
        padding: 10px;
        overflow-x: scroll;
        width: 100%;
      }
      .ant-tabs-tab-active {
        border-bottom: 1px solid ${(props) => props.theme.colors.gray} !important;
        border-top: 1px solid ${(props) => props.theme.colors.gray} !important;
        border-radius: 0;
        border-radius: 8px 0px 0px 8px;
        @media (${deviceQuery.mobileSM}) {
          border-radius: 8px 8px 0px 0px;
          border-top: none !important;
          border-bottom: none !important;
        }
      }
      .ant-tabs-tab-active:first-child {
        border-top: none !important;
      }
    }
    .ant-tabs-tab {
      border: none !important;
      .ant-tabs-tab-btn {
        min-height: 75px;
        display: flex;
        gap: 8px;
        align-items: center;
        color: ${(props) => props.theme.colors.gray} !important;
        transition: all 0.3s ease 0s;
        .ant-tabs-tab-icon {
          margin-right: 0;
          svg {
            height: 30px;
            width: 30px;
          }
        }
        .label {
          display: flex;
          flex-direction: column;
          align-items: start;
          .label-heading {
            font-size: 18px;
            font-weight: 500;
            margin-top: 0;
            margin-bottom: 0;
            max-width: 135px;
            white-space: normal;
          }
          .sub-heading {
          }
        }
        &:hover {
          color: ${(props) => props.theme.colors.blue} !important;
        }
      }
    }
    .ant-tabs-tabpane-active {
      padding: 10px;
      padding-left: 10px !important;
    }

    .ant-tabs-content-holder {
      border: 1px solid ${(props) => props.theme.colors.gray};
      border-radius: 0 8px 8px 0;
      @media (${deviceQuery.mobileSM}) {
        border-radius: 0 0 8px 8px;
      }
      .content-container {
        .content {
          color: ${(props) => props.theme.colors.gray3};
          font-size: 16px;
          @media (${deviceQuery.mobileSM}) {
            font-size: 14px;
          }
        }
        .content-heading {
          color: ${(props) => props.theme.colors.darkBlue2};
          font-size: 20px;
          font-weight: 600;
          @media (${deviceQuery.mobileSM}) {
            font-size: 18px;
          }
        }
        ul {
          color: ${(props) => props.theme.colors.gray3};
          font-size: 16px;
          margin-top: 0px;
          @media (${deviceQuery.mobileSM}) {
            font-size: 14px;
          }
          li {
            margin-top: 8px;
          }
        }
        .card-container {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
          row-gap: 18px;
          gap: 12px;
          .card {
            justify-self: center;
            min-height: 220px;
            width: 200px;
            background: ${(props) => props.theme.colors.white};
            box-shadow: ${(props) => props.theme.shadow.cardShadow};
            border-radius: 14px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            padding: 10px;
            .image {
              height: 50px;
              width: 50px;
              margin: 0px auto;
            }
            .card-heading {
              color: ${(props) => props.theme.colors.darkBlue2};
              font-size: 16px;
              font-weight: 600;
              text-align: center;
              margin-bottom: 8px;
              @media (${deviceQuery.mobileSM}) {
                font-size: 14px;
              }
            }
            .sub-content {
              color: ${(props) => props.theme.colors.gray3};
              font-size: 14px;
              margin-top: 0px;
              text-align: center;
              min-height: 62px;
              @media (${deviceQuery.mobileSM}) {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
    .ant-tabs-tab-active > .ant-tabs-tab-btn {
      .label-heading {
        color: ${(props) => props.theme.colors.blue} !important;
      }
      .sub-heading {
        color: ${(props) => props.theme.colors.gray} !important;
      }
    }
  }
`;
