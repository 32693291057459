import React from "react";
import { DetailedInstructionContainer } from "./DetailedInstruction.styles";
import { StyledButton } from "../../../CheckStatus/Components/CheckStatusForm/CheckStatusForm.styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../Constants/Routes";

const DetailedInstruction = () => {
  const navigate = useNavigate();
  return (
    <DetailedInstructionContainer>
      <div className="detailed-instruction-main-container">
        <div className="left-container">
          <h2 className="left-container-heading">
            Detailed Instruction For Applying An{" "}
            <span className="diff">eVisa</span>
          </h2>
          <div className="content">
            Four simple steps will make your application form goes easier
          </div>
          <StyledButton
            className="button"
            onClick={() => navigate(ROUTES.APPLICATIONFORM)}
          >
            Apply For an eVisa
          </StyledButton>
          <div className="image-container">
            <img
              src="../Images/SimpleMethods.jpg"
              alt="steps"
              className="image"
            />
          </div>
        </div>
        <div className="right-container">
          <div className="card">
            <img
              src="../Images/fill-form.png"
              alt="fill form"
              className="image"
            />
            <p className="card-heading">1. Fill out The Form</p>
            <ul>
              <li>Enter your information at the link Apply for an eVisa.</li>
            </ul>
          </div>
          <div className="card">
            <img
              src="../Images/FeesIcon.png"
              alt="fill form"
              className="image"
            />
            <p className="card-heading">2. Pay The eVisa Fees</p>
            <ul>
              <li>
                Choose a payment method and provide additional documents through
                email.
              </li>
            </ul>
          </div>
          <div className="card">
            <img
              src="../Images/ResultIcon.png"
              alt="fill form"
              className="image"
            />
            <p className="card-heading">3. Receive eVisa Result</p>
            <ul>
              <li>
                Within 3 business days, your eVisa will be sent to your email
                box.
              </li>
              <li>
                If you want to receive eVisa quicker, apply for Urgent Visa
                service.
              </li>
            </ul>
          </div>
          <div className="card">
            <img
              src="../Images/SignIcon.png"
              alt="fill form"
              className="image"
            />
            <p className="card-heading">4. Get The eVisa Stamped</p>
            <ul>
              <li>
                Download and print the eVisa in order to be stamped at the
                airport.
              </li>
              <li>Now, get ready for an intriguing journey.</li>
            </ul>
          </div>
        </div>
      </div>
    </DetailedInstructionContainer>
  );
};

export default DetailedInstruction;
