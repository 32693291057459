import React from "react";
import { OurExpertiseContainer } from "./OurExpertise.styles";

const OurExpertise = () => {
  return (
    <OurExpertiseContainer>
      <h2 className="our-expertise-heading">Our Expertise</h2>
      <div className="card-container">
        <div className="card">
          <h5 className="card-heading">The know-how you need</h5>
          <div className="card-content">
            We have the processes and expertise to solve your visa problems and
            help you reduce time and effort. We consistently leverage our
            collaborative teams of experts to get your visa as quickly as
            possible.
          </div>
        </div>
        <div className="card">
          <h5 className="card-heading">Global expertise, applied locally</h5>
          <div className="card-content">
            Our methodologies have been proven across markets around the globe.
            No matter where you are, our consultants have firsthand experience
            and a keen understanding of India Immigration rules, so we can help
            you arrange for your trip ahead.
          </div>
        </div>
      </div>
    </OurExpertiseContainer>
  );
};

export default OurExpertise;
