import { Select } from "antd";
import styled from "styled-components";
import { deviceQuery } from "../../../Utils/MediaSizes";

export const CountrySelectContainer = styled(Select)`
  height: 50px;
  border-radius: 0px;
  .ant-form-item {
    width: 35%;
  }
  .ant-select-selector > .ant-select-selection-item {
    color: ${(props) => props.theme.colors.inputColor};
    font-weight: 400;
    font-size: 13px;
  }

  .ant-select-selector
    > .ant-select-selection-search
    > .ant-select-selection-search-input {
    padding: 0px !important;
    height: 50px !important;
    width: 100%;
  }

  & .ant-select-selector > .ant-select-selection-placeholder {
    padding-left: 0 !important;
    margin: 0 !important;
    font-weight: 400;
    font-size: 14px;
    color: ${(props) => props.theme.colors.gray};
  }
  & > .ant-select-selector {
    border-radius: 0px !important;
    height: 50px;
    color: ${(props) => props.theme.colors.gray};
    background: ${(props) => props.theme.colors.white};
    padding-left: 10px !important;
  }

  .ant-select-selector {
    border-right: none !important;
    @media (${deviceQuery.mobileSM}) {
      max-width: 100% !important;
    }
  }
`;
