import React, { useCallback, useEffect, useState } from "react";
import { Form, Input } from "antd";
import { StyledButton } from "../../../Screens/ApplicationForm/Components/OrderInformation/OrderInformation.styles";
import { handleFieldError, restrictedKeys } from "../../../Utils/Common";
import { useGetAllCountriesMutation } from "../../../Services/Users";
import { useGetVisaInsuranceDetailsQuery } from "../../../Services/Visa";
import { AddTravelInsuranceModal } from "./AddTravelInsurance.styles";

const AddTravelInsurance = ({
  handleOk,
  isModalOpen,
  handleCancel,
  editId,
  form
}) => {
  const [countries, setCountries] = useState([]);
  const [getAllCountries] = useGetAllCountriesMutation();
  const handleModalCancel = () => {
    handleCancel();
    form.resetFields();
  };
  const { data: visaInsuranceDetails } = useGetVisaInsuranceDetailsQuery(
    editId,
    {
      skip: editId !== null ? false : true,
    }
  );
  const getAllCountriesFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: 300,
        current: 0,
      };
      const response = await getAllCountries(reqData).unwrap();
      setCountries(
        response?.data?.data?.map((item, i) => {
          return {
            key: i,
            label: item?.name,
            value: item?.id,
          };
        })
      );
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllCountries]);

  useEffect(() => {
    getAllCountriesFn();
  }, [getAllCountriesFn]);

  useEffect(() => {
    if (visaInsuranceDetails && editId) {
      form.setFieldsValue({
        serviceCharge: visaInsuranceDetails?.data?.serviceCharge,
        description: visaInsuranceDetails?.data?.description,
        name: visaInsuranceDetails?.data?.name,
        price: visaInsuranceDetails?.data?.price,
      });
    }
  }, [visaInsuranceDetails, form, editId]);

  return (
    <AddTravelInsuranceModal
      onCancel={handleModalCancel}
      open={isModalOpen}
      footer={null}
      destroyOnClose={true}
      width={640}
    >
      <div className="heading">
        {editId ? `Update` : "Add"} Travel Insurance
      </div>
      <Form className="main-container" onFinish={handleOk} form={form}>
        <div className="grid-container">
          {/* <Form.Item
            name="country"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Select
              showSearch
              placeholder="Select Country"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={countries}
            />
          </Form.Item> */}
          <Form.Item
            name="name"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input placeholder="Insurance Name" className="input" />
          </Form.Item>
          <Form.Item
            name="description"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input placeholder="Description" className="input" />
          </Form.Item>
          <Form.Item
            name="price"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input
              placeholder="Price"
              className="input"
              type="number"
              onKeyDown={(e) => {
                if (restrictedKeys.includes(e?.key)) {
                  e.preventDefault();
                  return;
                }
              }}
              max={100000}
              maxLength={6}
            />
          </Form.Item>
          <Form.Item
            name="serviceCharge"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "",
              },
            ]}
          >
            <Input
              placeholder="Service Charge"
              className="input"
              type="number"
              onKeyDown={(e) => {
                if (restrictedKeys.includes(e?.key)) {
                  e.preventDefault();
                  return;
                }
              }}
              max={100000}
              maxLength={6}
            />
          </Form.Item>
        </div>
        <StyledButton
          className="button"
          // onClick={() => {
          //   handleOk(value, description);
          //   setValue("");
          //   setDescription("");
          // }}
          htmlType="submit"
        >
          {editId ? "Update" : "Add"}
        </StyledButton>
      </Form>
    </AddTravelInsuranceModal>
  );
};
export default AddTravelInsurance;
