import styled from "styled-components";
import { deviceQuery } from "../../Utils/MediaSizes";

export const ContactUsContainer = styled.div`
  padding: 40px;
  max-width: 1190px;
  margin: auto;
  @media (${deviceQuery.mobileSM}) {
    padding: 20px;
  }
  .contact-us-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: ${(props) => props.theme.colors.white};
    padding: 90px 0px;
    @media (${deviceQuery.laptopS}) {
      padding: 60px 0;
      flex-wrap: wrap;
      gap: 20px;
      justify-content: center;
    }
    .contact-card {
      width: 32%;
      padding: 25px;
      background-color: ${(props) => props.theme.colors.darkBlue3};
      display: flex;
      align-items: center;
      gap: 12px;
      min-width: 280px;
      @media (${deviceQuery.laptopS}) {
        width: 50%;
      }
      .heading {
        margin-top: 0;
        margin-bottom: 10px;
      }
      .contact {
        font-size: 15px;
        font-weight: 400;
        line-height: 23px;
        margin-bottom: 0;
        margin-top: 0;
      }
      .icon {
        width: 46px;
        height: 46px;
        line-height: 46px;
        background-color: ${(props) => props.theme.colors.white};
        color: ${(props) => props.theme.colors.black};
        box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.08);
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          height: 25px;
          width: 25px;
        }
      }
    }
    .location-container {
      padding: 25px 30px;
      background-color: ${(props) => props.theme.colors.blue};
    }
  }
`;
