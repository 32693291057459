import { createSlice } from "@reduxjs/toolkit";

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    isDarkMode: false, // Set the initial theme state to light mode
  },
  reducers: {
    toggleTheme: (state,action) => {
      state.isDarkMode = action.payload; // Toggle between light and dark mode
    },
   
  },
});

export const { toggleTheme } = themeSlice.actions;
export const selectIsDarkMode = (state) => state.theme.isDarkMode;

export default themeSlice.reducer;