import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const VisaProcessNoteContainer = styled.div`
  max-width: 1170px;
  border: 1px solid ${(props) => props.theme.colors.blue};
  background: ${(props) => props.theme.colors.lightBlue};
  box-shadow: ${(props) => props.theme.shadow.cardShadow};
  border-radius: 14px;
  padding: 10px;
  margin: auto;
  .visa-process-note-heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.darkBlue2};
    padding-left: 22px;
    @media (${deviceQuery.mobileSM}) {
      font-size: 22px;
      line-height: 22px;
    }
    .diff {
      color: ${(props) => props.theme.colors.blue};
    }
  }
  ul {
    color: ${(props) => props.theme.colors.gray};
    font-size: 16px;
    text-align: justify;
    @media (${deviceQuery.mobileSM}) {
      font-size: 14px;
      padding-left: 20px;
    }
    li {
      margin-top: 8px;
    }
  }
`;
