import styled from "styled-components";
import { deviceQuery } from "../../Utils/MediaSizes";

export const CountryDetailsContainer = styled.div`
  .main-container {
    max-width: 1170px;
    margin: 0px auto;
    display: flex;
    gap: 40px;
    padding: 40px 0px;
    @media (${deviceQuery.laptopM}) {
      flex-direction: column-reverse;
      align-items: center;
      margin: 0px 20px;
    }
    @media (${deviceQuery.mobileSM}) {
      margin: 0 10px;
    }
    .left-container {
      width: 30%;
      @media (${deviceQuery.laptopM}) {
        width: 100%;
      }
      .current-country {
        color: ${(props) => props.theme.colors.white} !important;
        background: ${(props) => props.theme.colors.blue} !important;
        svg {
          color: ${(props) => props.theme.colors.gray4} !important;
        }
      }
      .country-name-card {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 25px;
        box-shadow: 0 0 6px 0px rgba(29, 33, 67, 0.1);
        color: ${(props) => props.theme.colors.darkBlue};
        transition: all 0.4s linear;
        background: linear-gradient(
            to left,
            ${(props) => props.theme.colors.white} 50%,
            ${(props) => props.theme.colors.blue} 50%
          )
          right;
        background-size: 200%;
        cursor: pointer;
        margin-bottom: 15px;
        .country-name {
          font-weight: 600;
          font-size: 15px;
          line-height: 16px;
          font-family: "Roboto", sans-serif;
        }
        svg {
          color: ${(props) => props.theme.colors.gray};
          font-size: 18px;
        }
        &:hover {
          background-position: left;
          color: ${(props) => props.theme.colors.white};
          svg {
            color: ${(props) => props.theme.colors.gray4} !important;
          }
        }
        &::selection {
          color: ${(props) => props.theme.colors.white};
          background-color: ${(props) => props.theme.colors.blue};
        }
      }
    }
    .right-container {
      width: 80%;
      @media (${deviceQuery.laptopML}) {
        width: 100%;
      }
      .country-poster {
        height: 570px;
        width: 100%;
        .image {
          height: 100%;
          width: 100%;
        }
      }
      .content-container {
        .heading {
          color: ${(props) => props.theme.colors.darkBlue};
          margin-bottom: 10px;
          font-size: 26px;
          line-height: 36px;
          margin-top: 0px;

          @media (${deviceQuery.mobileSM}) {
            font-size: 22px;
            line-height: 30px;
          }
        }
        .uppercase {
          text-transform: uppercase;
        }
        .content {
          margin: 0 0 15px;
          font-weight: 400;
          font-size: 15px;
          line-height: 25px;
          letter-spacing: 0px;
          color: ${(props) => props.theme.colors.gray};
          text-align: justify;
          @media (${deviceQuery.mobileSM}) {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
      .points-container {
        display: flex;
        gap: 40px;
        margin: 20px 0px;
        .points-image-container {
          width: 50%;
          height: 200px;
          .image {
            height: 100%;
            width: 100%;
          }
        }
        .points {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
          gap: 11px;
          row-gap: 30px;
          .list-item {
            display: flex;
            gap: 12px;
            .logo-container {
              display: flex;
              align-items: center;
              max-height: 25px;
              svg {
                margin-top: 2px;
                height: 24px;
                width: 24px;
                color: ${(props) => props.theme.colors.blue};
              }
            }
            .item-content {
              transition: all 0.3s ease 0s;
              font-size: 15px;
              line-height: 25px;
              letter-spacing: 0px;
              @media (${deviceQuery.mobileSM}) {
                font-size: 14px;
                line-height: 20px;
              }
              .content-heading {
                color: ${(props) => props.theme.colors.blue};
              }
              /* &:hover {
                color: ${(props) => props.theme.colors.blue};
              } */
            }
          }
        }
      }
      .feature-box-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
        gap: 20px;
        padding: 20px 0px;
        .feature-box {
          display: flex;
          align-items: center;
          justify-self: center;
          .icon-main-container {
            position: relative;
            height: 100px;
            width: 120px;
            .icon-container {
              position: absolute;
              height: 100px;
              width: 50px;
              border: 4px solid rgba(0, 0, 0, 0.8);
              border-radius: 3px;
              opacity: 0.08;
              z-index: 9;
              top: 0%;
              transition: all 0.2s;
              &:hover {
                width: 100px;
              }
            }
            svg {
              z-index: 2;
              position: absolute;
              height: 50px;
              width: 50px;
              top: 25%;
              left: 25%;
              color: ${(props) => props.theme.colors.blue};
            }
          }
          .box-content-container {
            width: 60%;
            .box-heading {
              color: ${(props) => props.theme.colors.darkBlue};
              margin-bottom: 5px;
              font-size: 15px;
              line-height: 20px;
              margin-top: 0;
              font-size: 500;
              &:hover {
                color: ${(props) => props.theme.colors.blue};
              }
            }
            .content {
              margin: 0;
            }
          }
        }
      }
      .government-instructions-container {
        .heading {
          color: ${(props) => props.theme.colors.darkBlue};
          margin-bottom: 20px;
          font-size: 26px;
          line-height: 36px;
          @media (${deviceQuery.mobileSM}) {
            font-size: 22px;
            line-height: 30px;
          }
        }
        .instructions-container {
          display: flex;
          justify-content: space-between;
          gap: 8px;
          @media (${deviceQuery.mobileSM}) {
            flex-direction: column-reverse;
          }
          .content-container {
            width: 70%;
            @media (${deviceQuery.mobileSM}) {
              width: 100%;
            }
            .content-heading {
              color: ${(props) => props.theme.colors.darkBlue};
              margin-bottom: 10px;
              font-size: 22px;
              line-height: 26px;
              margin-top: 8px;
              @media (${deviceQuery.mobileSM}) {
                font-size: 20px;
                line-height: 24px;
              }
            }
            ol {
              color: ${(props) => props.theme.colors.gray3};
              font-size: 16px;
              margin-top: 0px;
              @media (${deviceQuery.mobileSM}) {
                font-size: 14px;
                padding-left: 20px;
              }
              li {
                margin-top: 8px;
              }
            }
          }
          .image-container {
            width: 32%;
            @media (${deviceQuery.mobileSM}) {
              width: 100%;
            }
            .image {
              width: 100%;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
`;
