import React from "react";
import { AllVisaHeaderContainer } from "./AllVisaHeader.styles";
import { Breadcrumb } from "antd";

const AllVisaHeader = () => {
  const breadCrumbItems = [
    {
      title: "Home",
    },
    {
      title: "All Visa",
    },
  ];
  return (
    <AllVisaHeaderContainer>
      <div className="content-section">
        <h2 className="heading">Choose Your Destination</h2>
        <div className="sub-heading">
          Explore and plan your trips with the best of our services
        </div>
        {/* <Breadcrumb items={breadCrumbItems} /> */}
      </div>
    </AllVisaHeaderContainer>
  );
};

export default AllVisaHeader;
