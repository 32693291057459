export const countryDescription = [
  {
    countryName: "India",
    welcomeNote:
      "India - A mesmerizing land of diverse cultures, rich traditions, and stunning landscapes. Discover the grandeur of the Taj Mahal, a UNESCO World Heritage site and an epitome of timeless love. Dive into the lively energy of cities like Delhi and Mumbai, where colorful bazaars, dynamic street scenes, and delicious cuisine captivate the senses. From the serene backwaters of Kerala to the sacred atmosphere of Varanasi, India beckons you to explore its depths, where ancient heritage intertwines with contemporary vibrancy in an extraordinarily captivating journey.",
    climate: "Tropical Climate",
    language: "Hindi & English",
    currency: "India Rupee (INR)",
    about:
      "On November 27, 2014, the Indian government launched the Electronic Travel Authorization (India e-visa), which was available to several nationals. This type of visa can be used to travel to India with the purposes of tourist, business and medical treatment",
    governmentOverview:
      "India Immigration Department allows visitor to apply for the India eVisa online without having to visit India Embassy, or obtain the visa on arrrival.",
    governmentPointsToNote: [
      "Visa processing fee is non-refundable.",
      "Incomplete applications will be rejected.",
      "Possession of an eVisa does not grant final authority to enter India.",
      "A visa must be obtained prior to entry into India.",
      "The e-visa must be presented at the point of entry.",
      "It is recommended to apply at least 4 working days before the trip starts.",
    ],
    visaCategories: [
      {
        categoryName: "Tourist E-Visa",
        entries: "Double Entries 30 days",
        overview:
          "The India government requires 165 nationalities to get a Tourist E-Visa to enter the country for Tourism. Now you can apply on our website easily and receive it by email.",
        visaInformation: [
          "Government & Admin fee: See More",
          "Service Fee: See More",
        ],
        howToApply:
          "Fantastic! India allows you to apply for an e-Visa (Electronic Visa) and now, you can easily apply for this eVisa through our website following 3 simple steps below:",
      },
    ],
  },
  {
    countryName: "United States",
    welcomeNote:
      "Welcome to the United States, a land of endless possibilities and diversity! From the iconic skyline of New York City to the majestic beauty of the Grand Canyon, the USA offers an array of experiences waiting to be explored. Immerse yourself in the vibrant culture of cities like Los Angeles, Chicago, and Miami, or unwind on the sun-soaked beaches of California and Hawaii. Indulge in the melting pot of cuisines, from classic American diners to gourmet eateries serving flavors from around the world. Whether you're drawn to the excitement of Hollywood, the historical landmarks of Washington D.C., or the natural wonders of Yellowstone National Park, the United States welcomes you with open arms to create unforgettable memories.",
    climate: "Diverse Regions",
    language: "English",
    currency: "United States Dollar (USD)",
    about:
      "United States visas permit non-citizens entry for tourism, business, education, or work. Applicants must complete forms, provide documentation, and attend interviews at U.S. embassies or consulates. Visa types include B-1/B-2 (tourism/business), F-1 (students), H-1B (temporary workers), each with specific criteria. Approval is discretionary, and visa holders must comply with the terms. ",
    governmentOverview:
      "U.S. uses a system called the Visa Waiver Program (VWP) for eligible countries. Citizens of countries participating in the VWP can travel to the U.S. for tourism or business for up to 90 days without obtaining a visa, provided they meet certain requirements and obtain authorization through the Electronic System for Travel Authorization (ESTA) before traveling. Now you can apply on our website easily and receive it by email.",
    governmentPointsToNote: [
      "Visa processing fee is non-refundable.",
      "Incomplete applications will be rejected.",
      "Possession of an eVisa does not grant final authority to enter India.",
      "A visa must be obtained prior to entry into India.",
      "The e-visa must be presented at the point of entry.",
      "It is recommended to apply at least 4 working days before the trip starts.",
    ],
    visaCategories: [
      {
        categoryName: "Tourist E-Visa",
        entries: "Double Entries 30 days",
        overview:
          "The India government requires 165 nationalities to get a Tourist E-Visa to enter the country for Tourism. Now you can apply on our website easily and receive it by email.",
        visaInformation: [
          "Government & Admin fee: See More",
          "Service Fee: See More",
        ],
        howToApply:
          "Fantastic! India allows you to apply for an e-Visa (Electronic Visa) and now, you can easily apply for this eVisa through our website following 3 simple steps below:",
      },
    ],
  },
  {
    countryName: "Portugal",
    welcomeNote:
      "Welcome to Portugal, where vibrant culture meets stunning landscapes! Explore historic Lisbon, with its charming cobblestone streets and pastel-colored buildings. Immerse yourself in the soulful melodies of Fado music while savoring delicious Portuguese cuisine, from fresh seafood to delectable pastries like Pastéis de Nata. Wander through Porto's narrow alleys, where you can taste the world-famous Port wine. Discover the picturesque villages of the Algarve, with golden beaches and dramatic cliffs. Dive into Portugal's rich history at UNESCO World Heritage sites like the Jerónimos Monastery and the Tower of Belém. Experience warmth, beauty, and hospitality in every corner of this enchanting country.",
    climate: " Varied, Mediterranean.",
    language: " Portuguese",
    currency: " Euro (€)",
    about:
      "A Portugal visa allows entry into Portugal for tourism, business, study, or residency. Schengen visas cover short stays up to 90 days, while national visas are for longer stays.   ",
    governmentOverview:
      "U.S. uses a system called the Visa Waiver Program (VWP) for eligible countries. Citizens of countries participating in the VWP can travel to the U.S. for tourism or business for up to 90 days without obtaining a visa, provided they meet certain requirements and obtain authorization through the Electronic System for Travel Authorization (ESTA) before traveling. Now you can apply on our website easily and receive it by email.",
    governmentPointsToNote: [
      "Visa processing fee is non-refundable.",
      "Incomplete applications will be rejected.",
      "Possession of an eVisa does not grant final authority to enter India.",
      "A visa must be obtained prior to entry into India.",
      "The e-visa must be presented at the point of entry.",
      "It is recommended to apply at least 4 working days before the trip starts.",
    ],
    visaCategories: [
      {
        categoryName: "Tourist E-Visa",
        entries: "Double Entries 30 days",
        overview:
          "The India government requires 165 nationalities to get a Tourist E-Visa to enter the country for Tourism. Now you can apply on our website easily and receive it by email.",
        visaInformation: [
          "Government & Admin fee: See More",
          "Service Fee: See More",
        ],
        howToApply:
          "Fantastic! India allows you to apply for an e-Visa (Electronic Visa) and now, you can easily apply for this eVisa through our website following 3 simple steps below:",
      },
    ],
  },
  {
    countryName: "Lithuania",
    welcomeNote:
      "Lithuania a captivating blend of history, culture, and natural beauty. Immerse yourself in the charm of Vilnius, with its UNESCO-listed Old Town and Gothic architecture. Explore the ancient castles of Trakai and the picturesque landscapes of the Curonian Spit. Indulge in Lithuanian cuisine, rich in hearty flavors and local ingredients. Experience traditional festivals and vibrant arts scenes. From the tranquil shores of the Baltic Sea to the bustling streets of Kaunas, Lithuania offers a diverse range of experiences for every traveler. Come and discover the warmth of Lithuanian hospitality and the wonders of this Baltic gem. Welcome!",
    climate: "Moderate Continental ",
    language: "Lithuanian",
    currency: "Euro (€)",
    about:
      "Lithuania, a Schengen Area member, offers various visa types for short-term stays, including tourism, business, and family visits.",
    governmentOverview:
      "U.S. uses a system called the Visa Waiver Program (VWP) for eligible countries. Citizens of countries participating in the VWP can travel to the U.S. for tourism or business for up to 90 days without obtaining a visa, provided they meet certain requirements and obtain authorization through the Electronic System for Travel Authorization (ESTA) before traveling. Now you can apply on our website easily and receive it by email.",
    governmentPointsToNote: [
      "Visa processing fee is non-refundable.",
      "Incomplete applications will be rejected.",
      "Possession of an eVisa does not grant final authority to enter India.",
      "A visa must be obtained prior to entry into India.",
      "The e-visa must be presented at the point of entry.",
      "It is recommended to apply at least 4 working days before the trip starts.",
    ],
    visaCategories: [
      {
        categoryName: "Tourist E-Visa",
        entries: "Double Entries 30 days",
        overview:
          "The India government requires 165 nationalities to get a Tourist E-Visa to enter the country for Tourism. Now you can apply on our website easily and receive it by email.",
        visaInformation: [
          "Government & Admin fee: See More",
          "Service Fee: See More",
        ],
        howToApply:
          "Fantastic! India allows you to apply for an e-Visa (Electronic Visa) and now, you can easily apply for this eVisa through our website following 3 simple steps below:",
      },
    ],
  },
  {
    countryName: "Latvia",
    welcomeNote:
      "Latvia a country of enchanting forests, captivating coastlines, and rich cultural heritage. Explore the picturesque capital city of Riga, with its stunning medieval architecture and vibrant atmosphere. Discover the charm of historic towns like Cēsis and Sigulda or relax on the sandy beaches of Jurmala. Indulge in Latvia's culinary delights, from hearty rye bread to savory smoked fish. Immerse yourself in traditional folk festivals and modern arts scenes. With its warm hospitality and breathtaking landscapes, Latvia offers a memorable experience for every traveler. Come and explore the hidden gems of the Baltic region in this captivating land.",
    climate: "Temperate Continental",
    language: "Latvian",
    currency: "Euro (€)",
    about:
      "Latvia typically requires a Schengen Visa for short stays, including tourism, business, and family visits. Applicants must complete a visa application form and provide necessary documents such as a valid passport, travel insurance, financial proof, and purpose-specific papers.  ",
    governmentOverview:
      "U.S. uses a system called the Visa Waiver Program (VWP) for eligible countries. Citizens of countries participating in the VWP can travel to the U.S. for tourism or business for up to 90 days without obtaining a visa, provided they meet certain requirements and obtain authorization through the Electronic System for Travel Authorization (ESTA) before traveling. Now you can apply on our website easily and receive it by email.",
    governmentPointsToNote: [
      "Visa processing fee is non-refundable.",
      "Incomplete applications will be rejected.",
      "Possession of an eVisa does not grant final authority to enter India.",
      "A visa must be obtained prior to entry into India.",
      "The e-visa must be presented at the point of entry.",
      "It is recommended to apply at least 4 working days before the trip starts.",
    ],
    visaCategories: [
      {
        categoryName: "Tourist E-Visa",
        entries: "Double Entries 30 days",
        overview:
          "The India government requires 165 nationalities to get a Tourist E-Visa to enter the country for Tourism. Now you can apply on our website easily and receive it by email.",
        visaInformation: [
          "Government & Admin fee: See More",
          "Service Fee: See More",
        ],
        howToApply:
          "Fantastic! India allows you to apply for an e-Visa (Electronic Visa) and now, you can easily apply for this eVisa through our website following 3 simple steps below:",
      },
    ],
  },
  {
    countryName: "United Arab Emirates",
    welcomeNote:
      "Welcome to the United Arab Emirates (UAE), a dynamic fusion of tradition and modernity set against the backdrop of desert landscapes and futuristic skylines. Explore the iconic city of Dubai, home to towering skyscrapers, luxurious shopping malls, and vibrant souks. Discover the cultural heritage of Abu Dhabi, with its majestic mosques and historic forts. Immerse yourself in the diverse experiences offered by the seven emirates, from exhilarating desert safaris to indulgent beach resorts. With its warm hospitality, rich heritage, and limitless opportunities, the UAE invites you to experience a truly unforgettable journey. Welcome to a land of endless possibilities. ",
    climate: "Arid Desert",
    language: "Arabic",
    currency: "UAE Dirham (AED)",
    about:
      "Latvia typically requires a Schengen Visa for short stays, including tourism, business, and family visits. Applicants must complete a visa application form and provide necessary documents such as a valid passport, travel insurance, financial proof, and purpose-specific papers.  ",
    governmentOverview:
      "U.S. uses a system called the Visa Waiver Program (VWP) for eligible countries. Citizens of countries participating in the VWP can travel to the U.S. for tourism or business for up to 90 days without obtaining a visa, provided they meet certain requirements and obtain authorization through the Electronic System for Travel Authorization (ESTA) before traveling. Now you can apply on our website easily and receive it by email.",
    governmentPointsToNote: [
      "Visa processing fee is non-refundable.",
      "Incomplete applications will be rejected.",
      "Possession of an eVisa does not grant final authority to enter India.",
      "A visa must be obtained prior to entry into India.",
      "The e-visa must be presented at the point of entry.",
      "It is recommended to apply at least 4 working days before the trip starts.",
    ],
    visaCategories: [
      {
        categoryName: "Tourist E-Visa",
        entries: "Double Entries 30 days",
        overview:
          "The India government requires 165 nationalities to get a Tourist E-Visa to enter the country for Tourism. Now you can apply on our website easily and receive it by email.",
        visaInformation: [
          "Government & Admin fee: See More",
          "Service Fee: See More",
        ],
        howToApply:
          "Fantastic! India allows you to apply for an e-Visa (Electronic Visa) and now, you can easily apply for this eVisa through our website following 3 simple steps below:",
      },
    ],
  },
  {
    countryName: "Canada",
    welcomeNote:
      "Welcome to Canada, a vast and diverse country renowned for its stunning natural beauty, multicultural cities, and friendly people. Explore the vibrant metropolises of Toronto, Vancouver, and Montreal, each offering unique cultural experiences and culinary delights. Discover the breathtaking landscapes of Banff and Jasper National Parks, the pristine coastlines of Nova Scotia, and the majestic Rocky Mountains. Immerse yourself in Canada's rich indigenous heritage, arts, and festivals. Whether you're seeking outdoor adventures, urban exploration, or tranquil retreats, Canada offers endless possibilities for every traveler. Come and experience the warmth and hospitality of the Great White North. Welcome to Canada!",
    climate: "Diverse Regions ",
    language: "English and French",
    currency: "Canadian Dollar (CAD)",
    about:
      "Canada offers various visas, including tourist, student, work, and immigration visas. Requirements vary based on visa type and applicant's nationality. Generally, applicants need a valid passport, application forms, photos, proof of finance, and purpose-specific documents. Check the official website of Immigration, Refugees, and Citizenship Canada for detailed instructions and eligibility criteria.",
    governmentOverview:
      "U.S. uses a system called the Visa Waiver Program (VWP) for eligible countries. Citizens of countries participating in the VWP can travel to the U.S. for tourism or business for up to 90 days without obtaining a visa, provided they meet certain requirements and obtain authorization through the Electronic System for Travel Authorization (ESTA) before traveling. Now you can apply on our website easily and receive it by email.",
    governmentPointsToNote: [
      "Visa processing fee is non-refundable.",
      "Incomplete applications will be rejected.",
      "Possession of an eVisa does not grant final authority to enter India.",
      "A visa must be obtained prior to entry into India.",
      "The e-visa must be presented at the point of entry.",
      "It is recommended to apply at least 4 working days before the trip starts.",
    ],
    visaCategories: [
      {
        categoryName: "Tourist E-Visa",
        entries: "Double Entries 30 days",
        overview:
          "The India government requires 165 nationalities to get a Tourist E-Visa to enter the country for Tourism. Now you can apply on our website easily and receive it by email.",
        visaInformation: [
          "Government & Admin fee: See More",
          "Service Fee: See More",
        ],
        howToApply:
          "Fantastic! India allows you to apply for an e-Visa (Electronic Visa) and now, you can easily apply for this eVisa through our website following 3 simple steps below:",
      },
    ],
  },
  {
    countryName: "Australia",
    welcomeNote:
      "Welcome to Australia, a land of extraordinary landscapes, diverse wildlife, and laid-back culture. Explore the iconic Sydney Opera House and the stunning Great Barrier Reef. Immerse yourself in the rugged beauty of the Outback or relax on pristine beaches along the coast. Discover vibrant cities like Melbourne and Brisbane, offering world-class dining and cultural experiences. Experience the unique Aboriginal heritage and rich multicultural tapestry that shapes Australia's identity. Whether you're seeking adventure, relaxation, or cultural immersion, Australia offers endless possibilities for unforgettable experiences. Come and discover the wonders of the Land Down Under. Welcome to Australia!",
    climate: "Varied Regions",
    language: "English",
    currency: "Australian Dollar (AUD)",
    about:
      "Australia offers eVisitor and Electronic Travel Authority (ETA) eVisas for eligible passport holders. These visas allow for tourism and short-term business visits. Applicants can apply online, providing passport details and other required information. Processing is usually quick, and eVisas are electronically linked to passports, eliminating the need for physical documents.",
    governmentOverview:
      "U.S. uses a system called the Visa Waiver Program (VWP) for eligible countries. Citizens of countries participating in the VWP can travel to the U.S. for tourism or business for up to 90 days without obtaining a visa, provided they meet certain requirements and obtain authorization through the Electronic System for Travel Authorization (ESTA) before traveling. Now you can apply on our website easily and receive it by email.",
    governmentPointsToNote: [
      "Visa processing fee is non-refundable.",
      "Incomplete applications will be rejected.",
      "Possession of an eVisa does not grant final authority to enter India.",
      "A visa must be obtained prior to entry into India.",
      "The e-visa must be presented at the point of entry.",
      "It is recommended to apply at least 4 working days before the trip starts.",
    ],
    visaCategories: [
      {
        categoryName: "Tourist E-Visa",
        entries: "Double Entries 30 days",
        overview:
          "The India government requires 165 nationalities to get a Tourist E-Visa to enter the country for Tourism. Now you can apply on our website easily and receive it by email.",
        visaInformation: [
          "Government & Admin fee: See More",
          "Service Fee: See More",
        ],
        howToApply:
          "Fantastic! India allows you to apply for an e-Visa (Electronic Visa) and now, you can easily apply for this eVisa through our website following 3 simple steps below:",
      },
    ],
  },
  {
    countryName: "Australia",
    welcomeNote:
      "Welcome to Australia, a land of extraordinary landscapes, diverse wildlife, and laid-back culture. Explore the iconic Sydney Opera House and the stunning Great Barrier Reef. Immerse yourself in the rugged beauty of the Outback or relax on pristine beaches along the coast. Discover vibrant cities like Melbourne and Brisbane, offering world-class dining and cultural experiences. Experience the unique Aboriginal heritage and rich multicultural tapestry that shapes Australia's identity. Whether you're seeking adventure, relaxation, or cultural immersion, Australia offers endless possibilities for unforgettable experiences. Come and discover the wonders of the Land Down Under. Welcome to Australia!",
    climate: "Varied Regions",
    language: "English",
    currency: "Australian Dollar (AUD)",
    about:
      "Australia offers eVisitor and Electronic Travel Authority (ETA) eVisas for eligible passport holders. These visas allow for tourism and short-term business visits. Applicants can apply online, providing passport details and other required information. Processing is usually quick, and eVisas are electronically linked to passports, eliminating the need for physical documents.",
    governmentOverview:
      "U.S. uses a system called the Visa Waiver Program (VWP) for eligible countries. Citizens of countries participating in the VWP can travel to the U.S. for tourism or business for up to 90 days without obtaining a visa, provided they meet certain requirements and obtain authorization through the Electronic System for Travel Authorization (ESTA) before traveling. Now you can apply on our website easily and receive it by email.",
    governmentPointsToNote: [
      "Visa processing fee is non-refundable.",
      "Incomplete applications will be rejected.",
      "Possession of an eVisa does not grant final authority to enter India.",
      "A visa must be obtained prior to entry into India.",
      "The e-visa must be presented at the point of entry.",
      "It is recommended to apply at least 4 working days before the trip starts.",
    ],
    visaCategories: [
      {
        categoryName: "Tourist E-Visa",
        entries: "Double Entries 30 days",
        overview:
          "The India government requires 165 nationalities to get a Tourist E-Visa to enter the country for Tourism. Now you can apply on our website easily and receive it by email.",
        visaInformation: [
          "Government & Admin fee: See More",
          "Service Fee: See More",
        ],
        howToApply:
          "Fantastic! India allows you to apply for an e-Visa (Electronic Visa) and now, you can easily apply for this eVisa through our website following 3 simple steps below:",
      },
    ],
  },
  {
    countryName: "United Kingdom",
    welcomeNote:
      "Welcome to the United Kingdom (UK), a land of rich history, iconic landmarks, and diverse cultures. Explore the historic streets of London, home to Buckingham Palace, the Tower of London, and Big Ben. Discover the breathtaking landscapes of Scotland's Highlands, Wales' rugged coastlines, and England's charming countryside. Immerse yourself in the vibrant culture of cities like Edinburgh, Cardiff, and Manchester, with their thriving arts scenes and culinary delights. Whether you're drawn to ancient castles, bustling markets, or scenic vistas, the UK offers a tapestry of experiences waiting to be explored. Welcome to a kingdom of endless discovery and timeless charm.",
    climate: "Mild and Variable",
    language: "English",
    currency: "Pound Sterling (GBP)",
    about:
      "The United Kingdom offers various visas for tourism, business, study, work, and immigration. Requirements vary based on visa type and applicant's nationality.",
    governmentOverview:
      "U.S. uses a system called the Visa Waiver Program (VWP) for eligible countries. Citizens of countries participating in the VWP can travel to the U.S. for tourism or business for up to 90 days without obtaining a visa, provided they meet certain requirements and obtain authorization through the Electronic System for Travel Authorization (ESTA) before traveling. Now you can apply on our website easily and receive it by email.",
    governmentPointsToNote: [
      "Visa processing fee is non-refundable.",
      "Incomplete applications will be rejected.",
      "Possession of an eVisa does not grant final authority to enter India.",
      "A visa must be obtained prior to entry into India.",
      "The e-visa must be presented at the point of entry.",
      "It is recommended to apply at least 4 working days before the trip starts.",
    ],
    visaCategories: [
      {
        categoryName: "Tourist E-Visa",
        entries: "Double Entries 30 days",
        overview:
          "The India government requires 165 nationalities to get a Tourist E-Visa to enter the country for Tourism. Now you can apply on our website easily and receive it by email.",
        visaInformation: [
          "Government & Admin fee: See More",
          "Service Fee: See More",
        ],
        howToApply:
          "Fantastic! India allows you to apply for an e-Visa (Electronic Visa) and now, you can easily apply for this eVisa through our website following 3 simple steps below:",
      },
    ],
  },
];
