import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { rtkBaseQuery } from "../Utils/BaseQuery";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: rtkBaseQuery,
  tagTypes: ["clearNotification", "updateUser"],
  endpoints: (builder) => ({
    getAllUsers: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/user/all`,
          body: data,
          method: "POST",
        };
      },
    }),
    editUser: builder.mutation({
      query: (data) => {
        return { url: `api/v1/user`, body: data, method: "PUT" };
      },
      invalidatesTags: ["updateUser"],
    }),
    getUser: builder.query({
      query: (data) => {
        return {
          url: `api/v1/user`,
          params: { userId: data },
        };
      },
      providesTags: ["updateUser"],
    }),
    contactUs: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/contact`,
          body: data,
          method: "POST",
        };
      },
    }),
    getAllContacts: builder.mutation({
      query: (data) => {
        return { url: `api/v1/contact/all`, body: data, method: "POST" };
      },
    }),
    addCountry: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/country/`,
          body: data,
          method: "POST",
        };
      },
    }),
    editCountry: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/country/`,
          body: data,
          method: "PUT",
        };
      },
    }),
    getAllCountries: builder.mutation({
      query: (data) => {
        return { url: `api/v1/country/all`, body: data, method: "POST" };
      },
    }),
    getCountryDetails: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/country/${data}`,
          method: "POST",
        };
      },
    }),
    getUserNotification: builder.query({
      query: (data) => {
        return {
          url: `api/v1/notification/user/${data}`,
        };
      },
      providesTags: ["clearNotification"],
    }),
    ClearNotification: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/notification/user/${data}`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["clearNotification"],
    }),
    ReadNotification: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/notification/${data}`,
          method: "PUT",
        };
      },
      invalidatesTags: ["clearNotification"],
    }),
    addFaq: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/faq/`,
          body: data,
          method: "POST",
        };
      },
    }),
    editFaq: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/faq/`,
          body: data,
          method: "PUT",
        };
      },
    }),
    getAllFaq: builder.mutation({
      query: (data) => {
        return { url: `api/v1/faq/all`, body: data, method: "POST" };
      },
    }),
    getFaq: builder.query({
      query: (data) => {
        return { url: `api/v1/faq/${data}` };
      },
    }),
    deleteFaq: builder.mutation({
      query: (data) => {
        return {
          url: `api/v1/faq/${data}`,
          method: "DELETE",
        };
      },
    }),
  }),
});

export const {
  useGetAllUsersMutation,
  useEditUserMutation,
  useGetUserQuery,
  useContactUsMutation,
  useGetAllContactsMutation,
  useAddCountryMutation,
  useEditCountryMutation,
  useGetAllCountriesMutation,
  useGetCountryDetailsMutation,
  useGetUserNotificationQuery,
  useClearNotificationMutation,
  useReadNotificationMutation,
  useAddFaqMutation,
  useEditFaqMutation,
  useGetAllFaqMutation,
  useGetFaqQuery,
  useDeleteFaqMutation,
} = userApi;
