import { Button } from "antd";
import styled from "styled-components";
import { deviceQuery } from "../../Utils/MediaSizes";

export const LandingPageContainer = styled.div`
  background-color: #f5f5f5;
  .main-container {
    display: flex;
    gap: 10px;
    min-height: 96vh;
    @media (${deviceQuery.laptopS}) {
      flex-direction: column;
      max-height: 100%;
    }
    .left-section {
      width: 70%;
      /* background: url("../Images/banner-desktop.jpg");
      background-position: center;
      background-size: cover; */
      position: relative;
      @media (${deviceQuery.laptopS}) {
        width: 100%;
        height: 100vh;
      }
      @media (${deviceQuery.mobileSM}) {
        height: 80vh;
        /* background: url("../Images/banner-mobile.jpg");
        background-position: center;
        background-size: cover; */
      }
      .desktop-image {
        display: block;
        height: 100%;
        width: 100%;
        @media (${deviceQuery.mobileSM}) {
          display: none;
        }
      }
      .mobile-image {
        display: none;
        height: 100%;
        width: 100%;
        @media (${deviceQuery.mobileSM}) {
          display: block;
        }
      }
      /* .background {
        background-color: rgba(0, 0, 0, 0.5);
        height: 100%;
      } */
      .content-container {
        position: absolute;
        top: 50px;
        right: 0;
        color: ${(props) => props.theme.colors.white};
        padding: 40px;
        width: 60%;
        @media (${deviceQuery.tabletM}) {
          width: 100%;
          padding: 12px;
        }
        @media (${deviceQuery.mobileSM}) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        h1 {
          font-size: 42px;
          @media (${deviceQuery.mobileSM}) {
            font-size: 28px;
            text-align: center;
          }
        }
        .points-container {
          margin: 20px 0;
          .point {
            display: flex;
            gap: 12px;
            color: ${(props) => props.theme.colors.white};
            margin-bottom: 16px;
            font-size: 20px;
            @media (${deviceQuery.mobileSM}) {
              font-size: 16px;
            }
          }
        }
        .top-button {
          border-color: ${(props) => props.theme.colors.white};
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          &:hover {
            border: none;
          }
          span {
            color: ${(props) => props.theme.colors.white};
          }
          svg {
            fill: ${(props) => props.theme.colors.white};
            font-size: 24px;
          }
        }
      }
    }
    .right-section {
      width: 30%;
      /* background: url("../Images/shape.png"); */
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 29px;
      @media (${deviceQuery.laptopS}) {
        width: 100%;
        margin-bottom: 60px;
      }
      .logo {
        height: 42px;
        width: 240px;
        .images {
          height: 100%;
          width: 100%;
        }
      }
      .form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        .ant-form-item {
          margin: 0px;
          width: 100%;
          height: 52px;
          @media (${deviceQuery.mobileSM}) {
            height: 44px;
          }
        }
        .heading {
          font-weight: 900;
          text-align: center;
          font-size: 28px;
          line-height: 38px;
          color: #0067ed;
          text-transform: capitalize;
          margin: 20px 0 0;
        }
        .input {
          height: 52px;
          border-radius: 6px;
          border: 1px solid #d5d1d1;
          font-size: 16px;
          /* border: 1.5px solid ${(props) =>
            props.theme.colors.buttonBorderColor}; */
          background-color: ${(props) => props.theme.colors.white};
          @media (${deviceQuery.mobileSM}) {
            height: 44px;
          }
        }
        .note {
          color: rgba(0, 0, 0, 0.6);
          border-color: rgba(255, 255, 255, 0.06);
          margin-left: 20px;
        }
        .ant-input {
          &::placeholder {
            font-size: 18px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
            border-color: rgba(255, 255, 255, 0.06);
            @media (${deviceQuery.mobileSM}) {
              font-size: 15px;
            }
          }
        }

        .phone-country-select {
          display: flex;
          align-items: start;
          width: 100%;
          .country-select {
            width: 25%;
            .ant-select-selector > .ant-select-selection-placeholder {
              font-size: 16px;
              @media (${deviceQuery.mobileSM}) {
                font-size: 15px;
              }
            }
            .ant-select > .ant-select-selector {
              border-top-left-radius: 6px !important;
              border-bottom-left-radius: 6px !important;
              .ant-select-selection-item {
                font-size: 16px;
              }
            }
            .ant-select-arrow {
              top: 70%;
            }

            .ant-form-item {
              .ant-form-item-explain > .ant-form-item-explain-error {
                margin-top: 10px !important;
                padding: 2px !important;
                font-size: 13px !important;
              }
            }
          }
          .input {
            border-top-left-radius: 0px !important;
            border-bottom-left-radius: 0px !important;
            border-left: none;
          }
        }
        .select {
          position: relative;
          width: 100%;
        }
        .ant-select {
          width: 100%;
        }
        .ant-select > .ant-select-selector {
          border-radius: 6px;
          /* border: 1px solid #d5d1d1; */
          height: 52px;
          color: rgba(0, 0, 0);
          background: ${(props) => props.theme.colors.white};
          @media (${deviceQuery.mobileSM}) {
            height: 44px;
          }
        }
        .ant-select-selector > .ant-select-selection-placeholder {
          font-weight: 400;
          font-size: 16px;
          color: rgba(0, 0, 0);
          @media (${deviceQuery.mobileSM}) {
            font-size: 15px;
          }
        }
        .ant-select-selection-item {
          font-weight: 400;
          font-size: 14px;
        }
      }
      .input-select-container {
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        gap: 10px;
        justify-content: center;
        width: 100%;
        @media (${deviceQuery.laptopS}) {
          width: 100%;
        }
        @media (${deviceQuery.mobileL}) {
          grid-template-columns: 1fr;
        }
      }
      .points-container {
        margin-top: -10px;
        width: 100%;
        @media (${deviceQuery.laptopS}) {
          width: fit-content;
        }
        .point {
          display: flex;
          align-items: center;
          gap: 8px;
          color: #4b4a4a;
          margin-bottom: 5px;
          font-size: 18px;
          font-family: "Roboto", sans-serif;
          @media (${deviceQuery.laptopS}) {
            font-size: 16px;
          }
          svg {
            height: 15px;
            width: 15px;
          }
        }
      }
    }
  }
  .footer {
    /* position: fixed;
    bottom: 0;
    right: 0;
    left: 0; */
    width: 100%;
    height: auto;
    background: linear-gradient(139deg, #0067ed, rgba(0, 103, 237, 0.8));
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 21px 0;
    .content {
      color: ${(props) => props.theme.colors.white};
      font-family: "Roboto", sans-serif;
    }
  }
`;

export const StyledButton = styled(Button)`
  background-color: #0067ed;
  border: 1px solid #0067ed;
  border-radius: 50px;
  color: ${(props) => props.theme.colors.white};
  width: 95%;
  height: 50px;
  /* transition: all 0.5s ease 0s; */
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  max-width: 450px;
  @media (${deviceQuery.mobileSM}) {
    height: 41px;
  }
  span {
    font-size: 20px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
    @media (${deviceQuery.mobileSM}) {
      font-size: 16px;
    }
  }
  &:hover {
    color: ${(props) => props.theme.colors.white} !important;
    background-color: ${(props) => props.theme.colors.darkBlue} !important;
  }
`;
