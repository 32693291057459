import styled from "styled-components";
import { deviceQuery } from "../../../Utils/MediaSizes";

export const ShortTermVisaCardContainer = styled.div`
  width: 332px;
  display: flex;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.shadow.cardShadow2};
  transition: 0.5s;
  &:hover {
    transform: translateY(-8px);
    .image-container > .image {
      clip-path: polygon(0 100%, 0 0, 100% 0, 100% 85%, 0 85%);
    }
  }
  @media (${deviceQuery.mobileMS}) {
    width: 240px;
  }
  .image-container {
    background-color: ${(props) => props.theme.colors.white};
    .image {
      border-radius: 5px;
      width: 100%;
      height: 100%;
      clip-path: polygon(0 100%, 0 0, 100% 0, 100% 70%, 0 99%);
      transition: all 0.8s;
      cursor: pointer;
    }
  }
  .content-container {
    padding: 0px 10px 20px 10px;
    background-color: ${(props) => props.theme.colors.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    padding: 30px 10px;
    .country-name-container {
      h5 {
        margin: 0px;
        margin-bottom: 10px;
      }
      .country-name {
        color: ${(props) => props.theme.colors.darkBlue};
        transition: all 0.3s ease 0s;
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0px;
        font-weight: 400;
        text-transform: uppercase;
        font-family: "Roboto", sans-serif;
        cursor: pointer;
        transition: all 0.3s ease 0s;
        margin: 0px;
        margin-bottom: 20px;
        &:hover {
          color: ${(props) => props.theme.colors.blue};
        }
      }
    }
    .content {
      text-align: center;
      font-size: 15px;
      line-height: 25px;
      font-weight: 400px;
      letter-spacing: 0px;
      color: ${(props) => props.theme.colors.gray};
      margin-bottom: 30px;
    }
    .read-more {
      color: ${(props) => props.theme.colors.blue};
      font-size: 14px;
      font-weight: 500;
      font-family: "Roboto", sans-serif;
      transition: all 0.5s ease 0s;
      cursor: pointer;
      &:hover {
        color: ${(props) => props.theme.colors.darkBlue2};
      }
    }
  }
`;
