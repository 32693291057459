import { Button } from "antd";
import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const ContactUsFormContainer = styled.div`
  background-color: ${(props) => props.theme.colors.lightBlue};
  box-shadow: 0px 4px 20px 0px rgb(238 238 238 / 5);
  margin: 60px auto 40px;
  max-width: 1170px;
  padding: 20px 60px;
  display: flex;
  gap: 40px;
  justify-content: center;
  /* padding: 20px; */
  @media (${deviceQuery.laptopS}) {
    flex-direction: column;
    padding: 20px;
    gap: 0;
  }
  @media (${deviceQuery.mobileSM}) {
    padding: 10px;
  }
  .left-container {
    height: 100%;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    padding: 30px 20px;
    @media (${deviceQuery.laptopS}) {
      width: 100%;
    }
    .small-heading {
      color: ${(props) => props.theme.colors.blue};
      font-weight: 600;
      display: block;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 13px;
      letter-spacing: 1px;
      margin-bottom: 5px;
      @media (${deviceQuery.mobileSM}) {
        font-size: 12px;
        line-height: 12px;
      }
    }
    .container-heading {
      font-weight: 300;
      text-transform: capitalize;
      font-size: 40px;
      line-height: 50px;
      margin-bottom: 0;
      margin-top: 0;
      color: ${(props) => props.theme.colors.darkBlue};
      align-self: self-start;
      margin-top: 0px;
      @media (${deviceQuery.mobileSM}) {
        font-size: 28px;
        line-height: 38px;
      }
      .diff {
        font-weight: 600;
      }
    }
    .content {
      font-size: 18px;
      line-height: 28px;
      font-weight: 500;
      letter-spacing: 0.2px;
      color: ${(props) => props.theme.colors.gray};
      padding-bottom: 5px;
      text-align: justify;
      @media (${deviceQuery.mobileSM}) {
        font-size: 14px;
        line-height: 20px;
      }
      .small-text {
        font-size: 15px;
        line-height: 25px;
        font-weight: 400;
        @media (${deviceQuery.mobileSM}) {
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
    .social-list-container {
      display: flex;
      justify-content: space-between;
      width: 170px;
      .social-item {
        border: 1px solid ${(props) => props.theme.colors.blue};
        border-radius: 50%;
        padding: 6px 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          background-color: ${(props) => props.theme.colors.blue};
          svg {
            fill: ${(props) => props.theme.colors.white};
          }
        }
      }
      svg {
        fill: ${(props) => props.theme.colors.blue};
      }
      .google {
        height: 20px;
        width: 20px;
      }
    }
  }
  .right-container {
    width: 65%;
    padding: 30px 20px;
    height: 100%;
    @media (${deviceQuery.laptopS}) {
      width: 100%;
    }
    .form {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px;
      .ant-form-item {
        margin: 0px;
        width: 100%;
        height: 72px;
      }
      .heading {
        font-weight: 600;
        text-align: center;
        font-size: 24px;
        line-height: 24px;
        color: ${(props) => props.theme.colors.darkBlue};
        text-transform: capitalize;
        margin-bottom: 15px;
      }
      .input {
        height: 50px;
        border-radius: 0px;
        /* border: 1.5px solid ${(props) =>
          props.theme.colors.buttonBorderColor}; */
        background-color: ${(props) => props.theme.colors.white};
      }
      .note {
        color: rgba(0, 0, 0, 0.6);
        border-color: rgba(255, 255, 255, 0.06);
        margin-left: 20px;
      }
      .ant-input {
        &::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.6);
          border-color: rgba(255, 255, 255, 0.06);
        }
      }

      .phone-country-select {
        display: flex;
        align-items: start;
        width: 100%;
        .country-select {
          width: 35%;
          .ant-form-item {
            .ant-form-item-explain > .ant-form-item-explain-error {
              margin-top: 10px !important;
              padding: 2px !important;
              font-size: 13px !important;
            }
          }
        }
      }
      .select {
        position: relative;
        width: 100%;
      }
      .ant-select {
        width: 100%;
      }
      .ant-select > .ant-select-selector {
        border-radius: 0px;
        height: 50px;
        color: rgba(0, 0, 0, 0.6);
        background: ${(props) => props.theme.colors.white};
      }
      .ant-select-selector > .ant-select-selection-placeholder {
        font-weight: 400;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.6);
      }

      .ant-select-selection-item {
        font-weight: 400;
        font-size: 14px;
      }
      .ant-picker {
        width: 100%;
        border-radius: 30px;
        background-color: rgba(0, 0, 0, 0.6);
        border-color: rgba(255, 255, 255, 0.06) !important;
        .ant-picker-input {
          padding-left: 26px;
          &::placeholder {
            font-size: 14px;
            font-weight: 400;
            color: ${(props) => props.theme.colors.gray40};
          }
        }
        .ant-picker-suffix {
          position: absolute;
          left: -5px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    .input-select-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
      justify-content: center;
      row-gap: 4px;
      @media (${deviceQuery.laptopS}) {
        width: 100%;
      }
      @media (${deviceQuery.mobileL}) {
        grid-template-columns: 1fr;
      }
    }
    .your-request {
      min-height: 110px;
      .ant-form-item-control-input-content {
        display: flex;
        justify-content: center;
      }
      /* .ant-form-item-explain-error {
      text-align: center;
    } */
    }
    .text-area {
      /* width: 80%; */
      border-radius: 0px;
      /* border: none; */
      background-color: ${(props) => props.theme.colors.white};
    }
    .help-with {
      grid-column: span 2;
      @media (${deviceQuery.mobileL}) {
        grid-column: span 1;
      }
    }
    .upload-container {
      height: 86%;
      .ant-upload-list-item-actions {
        display: none !important;
      }
      .upload-div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .heading {
          font-size: 18px;
          font-weight: 500;
          color: ${(props) => props.theme.colors.blueDark};
          margin-bottom: 0;
        }
        .sub-heading {
          font-size: 14px;
          color: ${(props) => props.theme.colors.blue};
        }
      }
      .ant-upload {
        background-color: ${(props) => props.theme.colors.white};
        border-radius: 0px;
        /* border: none; */
        &:hover {
        }
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.darkBlue};
  border-radius: 0px;
  color: ${(props) => props.theme.colors.darkBlue};
  width: 100%;
  height: 50px;
  /* transition: all 0.5s ease 0s; */
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  max-width: 450px;
  span {
    font-size: 16px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
  }
  &:hover {
    color: ${(props) => props.theme.colors.white} !important;
    background-color: ${(props) => props.theme.colors.darkBlue} !important;
  }
`;
