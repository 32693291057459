import { Pagination, Table, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { handleFieldError } from "../../../Utils/Common";
import { useGetAllCountriesMutation } from "../../../Services/Users";
import { StyledButton } from "../../ContactUs/Components/ContactUsForm/ContactUsForm.styles";
import { FaRegEdit } from "react-icons/fa";
import {
  useAddInsuranceDetailMutation,
  useEditInsuranceDetailMutation,
  useGetAllInsuranceMutation,
} from "../../../Services/Visa";
import AddTravelInsurance from "../../../Common/Modals/AddTravelInsurance/AddTravelInsurance";
import { useForm } from "antd/es/form/Form";

const TravelInsurance = () => {
  const [form] = useForm();
  const [getAllCountries] = useGetAllCountriesMutation();
  const [countryData, setCountryData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [insuranceDetails, setInsuranceDetails] = useState();
  const [paginate, setPaginate] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [editId, setEditId] = useState(null);
  const [addInsuranceDetail] = useAddInsuranceDetailMutation();
  const [editInsuranceDetail] = useEditInsuranceDetailMutation();
  const [getAllInsurance] = useGetAllInsuranceMutation();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditId(null);
  };

  const getAllCountriesFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: 300,
        current: 0,
      };
      const response = await getAllCountries(reqData).unwrap();

      setCountryData(response?.data?.data);
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllCountries]);

  useEffect(() => {
    getAllCountriesFn();
  }, [getAllCountriesFn]);

  const getCountryName = (countryId) => {
    try {
      let countryName = "-";
      countryData?.forEach((item) => {
        if (item?.id === countryId) {
          countryName = item?.name;
        }
      });
      return countryName;
    } catch (error) {
      console.error(error);
    }
  };

  const addInsuranceFn = async (values) => {
    try {
      const reqData = {
        id: editId,
        serviceCharge: Number(values?.serviceCharge),
        countryId: values?.country,
        price: Number(values.price),
        name: values?.name,
        description: values?.description,
      };
      await (editId ? editInsuranceDetail : addInsuranceDetail)(
        reqData
      ).unwrap();
      message.success(
        editId
          ? "Insurance Updated Successfully"
          : "Insurance Added Successfully"
      );
      handleCancel();
      getAllInsuranceFn();
      form.resetFields();
    } catch (error) {
      handleFieldError(error);
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      render: (id) => <div className="name-container">{id ?? "-"}</div>,
    },
    {
      title: "Description",
      dataIndex: "description",
      align: "center",
      render: (id) => <div className="name-container">{id ?? "-"}</div>,
    },
    // {
    //   title: "Country",
    //   dataIndex: "countryId",
    //   key: "name",
    //   align: "center",
    //   render: (countryId) => (
    //     <div className="name-container">{getCountryName(countryId)}</div>
    //   ),
    // },
    {
      title: "Service Charge",
      dataIndex: "serviceCharge",
      align: "center",
      render: (id) => <div className="name-container">{id ?? "-"}</div>,
    },
    {
      title: "Price",
      dataIndex: "price",
      align: "center",
      render: (id) => <div className="name-container">{id ?? "-"}</div>,
    },
    {
      title: "Action",
      dataIndex: ["countryCode", "status", "id", "description", "name"],
      width: "10%",
      align: "right",
      render: (_, record) => {
        return (
          <div
            title="Edit status"
            className={`editLogo`}
            onClick={(e) => {
              e.stopPropagation();
              setEditId(record?.id);
              showModal();
            }}
          >
            <FaRegEdit fontSize={20} />
          </div>
        );
      },
    },
  ];

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setCountryData([]);
    }
  };
  const getAllInsuranceFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: tableParams?.pagination?.pageSize,
        current: tableParams?.pagination?.current - 1,
      };
      const response = await getAllInsurance(reqData).unwrap();
      setInsuranceDetails(response?.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response?.data?.total,
        },
      });
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllInsurance, paginate]);

  useEffect(() => {
    getAllInsuranceFn();
  }, [getAllInsuranceFn]);

  return (
    <>
      <div className="button-container">
        <StyledButton onClick={showModal}>Add Insurance</StyledButton>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.key}
        dataSource={insuranceDetails?.data}
        onChange={handleTableChange}
        pagination={false}
      />
      {insuranceDetails?.total > 10 && (
        <Pagination
          total={insuranceDetails?.total}
          showSizeChanger
          showTotal={(total) => `Total ${total} items`}
          current={tableParams?.pagination?.current}
          pageSize={tableParams?.pagination?.pageSize}
          onChange={(page, pageSize) => {
            setPaginate(!paginate);
            setTableParams({
              pagination: {
                current: page,
                pageSize: pageSize,
              },
            });
          }}
        />
      )}
      <AddTravelInsurance
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={addInsuranceFn}
        editId={editId}
        form={form}
      />
    </>
  );
};

export default TravelInsurance;
