import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const CountryDetailsHeaderContainer = styled.div`
  background: url("/Images/pagetitle-bg.jpg") center center/cover;
  padding: 12px 120px;
  /* height: 350px; */
  color: ${(props) => props.theme.colors.white};
  @media (${deviceQuery.laptopM}) {
    margin-top: 95px;
  }
  @media (${deviceQuery.tabletM}) {
    padding: 12px 40px;
  }
  @media (${deviceQuery.mobileM}) {
    padding: 12px 20px;
  }
  .content-section {
    border-left: 2px solid ${(props) => props.theme.colors.blue};
    padding: 10px;
    .heading {
      font-weight: 500;
      text-transform: capitalize;
      font-size: 41px;
      line-height: 51px;
      margin-bottom: 0px;
      margin-top: 0px;
      @media (${deviceQuery.tabletM}) {
        font-size: 36px;
        line-height: 40px;
      }
      @media (${deviceQuery.mobileM}) {
        font-size: 28px;
        line-height: 32px;
      }
    }
    .sub-heading {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      letter-spacing: 0px;
      margin: 0 0 15px;
      @media (${deviceQuery.tabletM}) {
        font-size: 16px;
        line-height: 16px;
      }
      @media (${deviceQuery.mobileM}) {
        font-size: 14px;
      }
    }
    .ant-breadcrumb > ol > li > a,
    .ant-breadcrumb > ol > li {
      color: ${(props) => props.theme.colors.white};
      font-weight: 400;
      text-transform: capitalize;
      font-size: 16px;
      line-height: 30px;
      cursor: pointer;
      @media (${deviceQuery.tabletM}) {
        font-size: 14px;
      }
      @media (${deviceQuery.mobileM}) {
        font-size: 12px;
      }
    }
    .ant-breadcrumb > ol > li:last-child {
      font-size: 16px;
      color: ${(props) => props.theme.colors.gray};
    }
  }
`;
