import React, { useEffect } from "react";
import { VisaProcessContainer } from "./VisaProcess.styles";
import VisaProcessHeader from "./Components/VisaProcessHeader/VisaProcessHeader";
import VisaProcessNote from "./Components/VisaProcessNote/VisaProcessNote";
import DetailedInstruction from "./Components/DetailedInstruction/DetailedInstruction";
import { scrollToTop } from "../../Utils/Common";

const VisaProcess = () => {
  useEffect(() => {
    scrollToTop(0);
  }, []);

  return (
    <VisaProcessContainer>
      <VisaProcessHeader />
      <DetailedInstruction />
      <div className="visa-process-note-main-container">
        <VisaProcessNote />
      </div>
    </VisaProcessContainer>
  );
};

export default VisaProcess;
