import styled from "styled-components";

export const LandingThanksContainer = styled.div`
  text-align: center;
  color: #0067ed;
  position: relative;
  .thankyou_page_content {
    height: 100vh;
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    position: relative;
    margin: auto;
    gap: 40px;
  }
  .thankyou_page_content h1 {
    font-size: 38px;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 25px;
    font-weight: 800;
  }
  .btn {
    background-color: #172945;
    min-width: 150px;
    border-radius: none;
    height: 46px;
    &:hover {
      border-color: #172945 !important;
      span {
        color: #172945;
      }
    }
    span {
      color: white;
      font-size: 16px;
      font-weight: 500;
    }
  }
  .left-section {
    width: 50%;
  }
  .right-section {
    width: 50%;
    .calendly-inline-widget {
      width: 100%;
    }
  }
`;
