import { Button } from "antd";
import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const OrderInformationContainer = styled.div`
  padding: 30px 20px;
  height: 100%;
  @media (${deviceQuery.mobileSM}) {
    padding: 20px 6px;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    .ant-form-item {
      margin: 0px;
      width: 100%;
      height: 72px;
    }
    .heading {
      font-weight: 600;
      text-align: center;
      font-size: 24px;
      line-height: 24px;
      color: ${(props) => props.theme.colors.darkBlue};
      text-transform: capitalize;
      margin-bottom: 15px;
    }
    .input {
      height: 50px;
      border-radius: 0px;
      background-color: ${(props) => props.theme.colors.white};
    }
    .note {
      color: rgba(0, 0, 0, 0.6);
      border-color: rgba(255, 255, 255, 0.06);
      margin-left: 20px;
    }
    .ant-input {
      &::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        border-color: rgba(255, 255, 255, 0.06);
      }
    }

    .select {
      position: relative;
      width: 100%;
    }
    .ant-select {
      width: 100%;
    }
    .phone-country-select {
      display: flex;
      align-items: start;
      width: 100%;
      .country-select {
        width: 35%;
        .ant-form-item {
          .ant-form-item-explain > .ant-form-item-explain-error {
            margin-top: 10px !important;
            padding: 2px !important;
            font-size: 13px !important;
          }
        }
      }
    }
    .ant-select > .ant-select-selector {
      border-radius: 0px;
      height: 50px;
      color: rgba(0, 0, 0, 0.6);
      background: ${(props) => props.theme.colors.white};
    }
    .ant-select-selector > .ant-select-selection-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }

    .ant-select-selection-item {
      font-weight: 400;
      font-size: 14px;
    }
    .ant-picker {
      width: 100%;
      border-radius: 0px;
      background-color: ${(props) => props.theme.colors.white};
      border-color: none !important;
      .ant-picker-input {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        border-color: rgba(255, 255, 255, 0.06);
        input {
          &::placeholder {
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.6);
            border-color: rgba(255, 255, 255, 0.06);
          }
        }
      }
      .ant-picker-suffix {
        display: none;
      }
    }
  }
  .upload-container {
    height: 100%;
    .ant-upload-list-item-actions {
      display: none !important;
    }
    .upload-div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .heading {
        font-size: 16px;
        font-weight: 500;
        color: ${(props) => props.theme.colors.blueDark};
        margin-bottom: 0;
      }
      .sub-heading {
        font-size: 12px;
        color: ${(props) => props.theme.colors.blue};
      }
    }
    .ant-upload {
      background-color: ${(props) => props.theme.colors.white};
      border-radius: 0px;
      /* border: none; */
      padding: 6px !important;

      &:hover {
      }
    }
  }
  .processing-container {
    height: 150px !important;
    grid-column: span 2;
    margin-bottom: 20px !important;
    .ant-form-item {
      height: 100% !important;
    }
    @media (${deviceQuery.mobileSM}) {
      grid-column: span 1;
      height: 185px !important;
    }
    .ant-form-item-control-input-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .label-heading {
        font-weight: 500;
      }
      label {
        font-size: 16px;
        color: ${(props) => props.theme.colors.gray40};
        @media (${deviceQuery.mobileSM}) {
          font-size: 14px;
        }
      }
      .ant-radio-group {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
    }
  }
  .input-select-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-content: center;
    row-gap: 4px;
    @media (${deviceQuery.laptopS}) {
      width: 100%;
    }
    @media (${deviceQuery.mobileSM}) {
      grid-template-columns: 1fr;
    }
  }
  .form-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    justify-content: center;
    row-gap: 4px;
    border: 1px dashed ${(props) => props.theme.colors.darkBlue};
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    @media (${deviceQuery.mobileSM}) {
      grid-template-columns: 1fr 1fr;
    }
  }
  .remove-btn,
  .add-btn {
    position: absolute;
    max-width: 150px;
    font-weight: 500;
    height: 38px;
    font-size: 16px;
    top: -40px;
    &:hover {
      color: ${(props) => props.theme.colors.blue};
    }
  }
  .add-btn {
    right: 4%;
  }
  .remove-btn {
    left: 4%;
  }
  .button-container {
    position: relative;
    height: 0;
  }
  .your-request {
    min-height: 162px;
    grid-column: span 2;
    @media (${deviceQuery.mobileSM}) {
      grid-column: span 1;
    }
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }
  }
  .text-area {
    border-radius: 0px;
    border: none;
    background-color: ${(props) => props.theme.colors.white};
  }
  .insurance-card {
    border: 1px solid ${(props) => props.theme.colors.blue};
    padding: 20px;
    border-radius: 8px;
    .heading {
      text-align: left;
      color: ${(props) => props.theme.colors.darkBlue};
      margin-top: 10px;
    }
    .details-card {
      background-color: ${(props) => props.theme.colors.blueLighter};
      padding: 10px;
      border-radius: 8px;
      color: ${(props) => props.theme.colors.white};
      span {
        color: ${(props) => props.theme.colors.white} !important;
      }
    }
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.grayBackground};
  border: 1px solid ${(props) => props.theme.colors.darkBlue};
  border-radius: 0px;
  color: ${(props) => props.theme.colors.darkBlue};
  width: 100%;
  max-width: 200px;
  height: 50px;
  margin: 30px 0;
  transition: all 0.5s ease 0s;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  span {
    font-size: 16px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
  }
  &:hover {
    color: ${(props) => props.theme.colors.white} !important;
    background-color: ${(props) => props.theme.colors.darkBlue} !important;
  }
`;
