import React, { useState } from "react";
import {
  CaraouselContainer,
  StyledArrowButton,
  StyledButton,
} from "./Caraousel.styles";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { ROUTES } from "../../../../Constants/Routes";
import { useNavigate } from "react-router-dom";

const Caraousel = () => {
  const navigate = useNavigate();
  const [currentSlide, setCurrentSlide] = useState(0);
  const onChange = (currentSlide) => {
    setCurrentSlide(currentSlide);
  };

  const nextArrow = <StyledArrowButton icon={<RightOutlined />} />;
  const prevArrow = <StyledArrowButton icon={<LeftOutlined />} />;

  return (
    <CaraouselContainer
      afterChange={onChange}
      autoplay={true}
      autoplaySpeed={5000}
      dots={false}
      effect="fade"
      nextArrow={nextArrow}
      prevArrow={prevArrow}
      arrows
    >
      <div className="slider-element-1">
        <div className="content-container">
          <div
            className={`sub-heading ${currentSlide === 0 && "text-animation"}`}
          >
            Welcome to the fastest way
          </div>
          <div className={`heading ${currentSlide === 0 && "text-animation"}`}>
            Get a <span className="important"> Visa</span> &{" "}
            <span className="important">Immigration</span>
          </div>
          <StyledButton
            className={currentSlide === 0 && "button-animation"}
            onClick={() => navigate(ROUTES.APPLICATIONFORM)}
          >
            Apply Now!
          </StyledButton>
        </div>
      </div>
      <div className="slider-element-2">
        <div className="content-container">
          <div
            className={`sub-heading ${currentSlide === 1 && "text-animation"}`}
          >
            Welcome to the fastest way
          </div>
          <div className={`heading ${currentSlide === 1 && "text-animation"}`}>
            Get a <span className="important"> Visa</span> &{" "}
            <span className="important">Immigration</span>
          </div>
          <StyledButton
            className={currentSlide === 1 && "button-animation"}
            onClick={() => navigate(ROUTES.APPLICATIONFORM)}
          >
            Apply Now!
          </StyledButton>
        </div>
      </div>
    </CaraouselContainer>
  );
};

export default Caraousel;
