import styled from "styled-components";
import { deviceQuery } from "../../Utils/MediaSizes";

export const AllVisaContainer = styled.div`
  .card-container {
    max-width: 1170px;
    padding: 40px;
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    margin: auto;
    @media (${deviceQuery.laptopML}) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (${deviceQuery.tabletM}) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
