import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const OurExpertiseContainer = styled.div`
  padding: 0px 0px 40px;
  max-width: 1170px;
  margin: auto;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  .our-expertise-heading {
    font-weight: 700;
    text-align: center;
    font-size: 28px;
    line-height: 28px;
    color: ${(props) => props.theme.colors.darkBlue};
    text-transform: uppercase;
    margin-bottom: 80px;
    @media (${deviceQuery.mobileSM}) {
      font-size: 24px;
      line-height: 24px;
    }
    @media (${deviceQuery.mobileXS}) {
      margin-bottom: 50px;
    }
  }

  .card-container {
    display: flex;
    justify-content: center;
    gap: 40px;
    @media (${deviceQuery.tabletM}) {
      flex-direction: column;
      align-items: center;
    }
    .card {
      padding: 10px;
      position: relative;
      border-left: 1px solid ${(props) => props.theme.colors.blue};
      width: fit-content;
      margin: 0px 20px;
      .card-heading {
        color: ${(props) => props.theme.colors.darkBlue};
        font-size: 20px;
        line-height: 25px;
        margin-bottom: 8px;
        margin-top: 0;
        @media (${deviceQuery.mobileSM}) {
          font-size: 18px;
          line-height: 22px;
        }
      }
      .card-content {
        /* width: 90%; */
        color: ${(props) => props.theme.colors.gray};
        font-size: 14px;
        text-align: justify;
        @media (${deviceQuery.mobileSM}) {
          font-size: 12px;
        }
      }
    }
    .card::before {
      content: "";
      position: absolute;
      height: 12px;
      width: 12px;
      top: -8px;
      left: -6px;
      border-radius: 50%;
      background-color: ${(props) => props.theme.colors.blue};
    }
  }
`;
