import {
  getCountries,
  getCountryCallingCode,
} from "react-phone-number-input/input";
import "react-phone-number-input/style.css";
import { CountrySelectContainer } from "./CountrySelect.styles";
import { useEffect, useState } from "react";
import { Form } from "antd";
import ReactCountryFlag from "react-country-flag";

const CountrySelect = ({
  labels,
  marginBottom,
  isRequired,
  selectedCountry,
  ...rest
}) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    const value = getCountries().map((country, i) => {
      return {
        key: i,
        label: (
          <span>
            <ReactCountryFlag
              countryCode={country}
              svg
              style={{ marginRight: "4px" }}
            />
            {`+${getCountryCallingCode(country)}`}
          </span>
        ),
        value: country,
        search:
          labels[country] + country + ` +${getCountryCallingCode(country)}`,
      };
    });
    setOptions(value);
  }, [labels]);

  return (
    <Form.Item
      style={{ marginBottom: marginBottom }}
      rules={[
        {
          required: isRequired,
          message: "",
        },
      ]}
      name="countryCode"
    >
      <CountrySelectContainer
        showSearch
        filterOption={(input, option) =>
          (option?.search ?? "").toLowerCase().includes(input.toLowerCase())
        }
        placeholder="Country Code"
        options={options}
        {...rest}
        auto
      />
    </Form.Item>
  );
};

export default CountrySelect;
