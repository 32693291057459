import React, { useEffect } from "react";
import { HomePageContainer } from "./HomePage.styles";
import Caraousel from "./Components/Caraousel/Caraousel";
import CommonVisa from "./Components/CommonVisa/CommonVisa";
import ShortTermVisa from "./Components/ShortTermVisa/ShortTermVisa";
import AboutCompany from "./Components/AboutCompany/AboutCompany";
import WhyUs from "./Components/WhyUs/WhyUs";
import { scrollToTop } from "../../Utils/Common";
import TravelInsurance from "./Components/TravelInsurance/TravelInsurance";

const HomePage = () => {
  useEffect(() => {
    scrollToTop(0);
  }, []);
  return (
    <HomePageContainer>
      <Caraousel />
      <CommonVisa />
      <ShortTermVisa />
      <TravelInsurance />
      <WhyUs />
      <AboutCompany />
    </HomePageContainer>
  );
};

export default HomePage;
