import { Pagination, Table, Tag } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { handleFieldError } from "../../../Utils/Common";
import moment from "moment";
import {
  useGetAllVisaMutation,
  useGetUserVisaQuery,
} from "../../../Services/Visa";
import { ExpandableRowContainer } from "../Dashboard.styles";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

const Orders = () => {
  const [getAllVisa] = useGetAllVisaMutation();
  const [visaData, setVisaData] = useState();
  const [paginate, setPaginate] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const userId = sessionStorage.getItem("userId");
  const { data: userVisaData } = useGetUserVisaQuery(userId);
  const getAllVisaFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: tableParams?.pagination?.pageSize,
        current: tableParams?.pagination?.current - 1,
      };
      const response = await getAllVisa(reqData).unwrap();
      const data = {
        data: response?.data?.data?.map((items, index) => {
          return { ...items, key: index + 1 };
        }),
        total: response?.data?.total,
      };
      setVisaData(data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response?.data?.total,
        },
      });
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllVisa, paginate]);

  useEffect(() => {
    getAllVisaFn();
  }, [getAllVisaFn]);

  const columns = [
    {
      title: "ID",
      dataIndex: "applicationNo",
      render: (id) => <div className="name-container">{id ?? "-"}</div>,
    },
    {
      title: "Total(USD)",
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (amount) => <div className="name-container">{amount ?? "-"}</div>,
    },
    {
      title: "Apply Date",
      dataIndex: "appliedDate",
      key: "appliedDate",
      align: "center",
      render: (date) => (
        <div className="name-container">
          {date ? moment(date).format("DD-MM-YYYY") : "-"}
        </div>
      ),
    },
  ];

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setVisaData([]);
    }
  };

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record?.key}
        dataSource={userVisaData?.data}
        onChange={handleTableChange}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => <ExpandableRow data={record} />,
          rowExpandable: (record) => record?.travellers?.length > 0,
          expandIcon: ({ expanded, onExpand, record }) =>
            record?.travellers?.length > 0 &&
            (expanded ? (
              <IoIosArrowUp
                onClick={(e) => onExpand(record, e)}
                className="expand-icon"
              />
            ) : (
              <IoIosArrowDown
                onClick={(e) => onExpand(record, e)}
                className="expand-icon"
              />
            )),
        }}
        expandRowByClick={true}
      />
      {/* {visaData?.total > 10 && (
        <Pagination
          total={visaData?.total}
          showSizeChanger
          showTotal={(total) => `Total ${total} items`}
          current={tableParams?.pagination?.current}
          pageSize={tableParams?.pagination?.pageSize}
          onChange={(page, pageSize) => {
            setPaginate(!paginate);
            setTableParams({
              pagination: {
                current: page,
                pageSize: pageSize,
              },
            });
          }}
        />
      )} */}
    </>
  );
};

const ExpandableRow = ({ data }) => {
  const columns = [
    {
      title: "Surname",
      dataIndex: "surName",
      key: "surName",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Given Name",
      dataIndex: "givenName",
      key: "givenName",
    },
    {
      title: "DOB",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
    },
    {
      title: "Passport No.",
      dataIndex: "passportNumber",
      key: "passportNumber",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (status) => (status ? <Tag color="green">{status}</Tag> : "-"),
    },
  ];
  return (
    <ExpandableRowContainer key={data?.key}>
      <Table
        pagination={false}
        columns={columns}
        dataSource={data?.travellers}
      />
    </ExpandableRowContainer>
  );
};
export default Orders;
