import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const ProfileContainer = styled.div`
  .profile-heading {
    font-weight: 600;
    text-align: center;
    font-size: 32px;
    line-height: 32px;
    color: ${(props) => props.theme.colors.blue};
    text-transform: capitalize;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 20px;
    @media (${deviceQuery.mobileSM}) {
      padding: 0px;
    }
    .input-select-container {
      display: grid;
      gap: 40px;
      row-gap: 0;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      @media (${deviceQuery.mobileSM}) {
        grid-template-columns: 1fr;
      }
    }
    
    .phone-country-select {
      display: flex;
      align-items: start;
      width: 100%;
      .country-select {
        width: 35%;
        @media (${deviceQuery.tabletML}) {
          min-width: auto !important;
        }

        .ant-select {
          height: 38px !important;
        }
        .ant-form-item {
          .ant-form-item-explain > .ant-form-item-explain-error {
            margin-top: 10px !important;
            padding: 2px !important;
            font-size: 13px !important;
          }
        }
      }
    }
    .input-container {
      label {
        margin-bottom: 8px;
        font-weight: 500;
        font-size: 14px;
        color: ${(props) => props.theme.colors.darkBlue};
      }
    }

    .ant-form-item {
      margin: 0px;
      width: 100%;
      height: 60px;
    }
    .input {
      height: 38px;
      border-radius: 0px;
      background-color: ${(props) => props.theme.colors.white};
    }

    .ant-input {
      &::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        border-color: rgba(255, 255, 255, 0.06);
      }
    }
    .forgot-password {
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 14px;
      color: ${(props) => props.theme.colors.darkBlue};
      margin-top: -25px;
      text-align: right;
    }
    .ant-btn {
      margin: 20px 0px;
      max-width: 200px;
    }
    .no-account {
      text-align: center;
      margin-bottom: 8px;
      font-weight: 500;
      font-size: 16px;
      color: ${(props) => props.theme.colors.darkBlue};
      margin-top: -10px;
      .sign-up {
        color: ${(props) => props.theme.colors.blue};
        margin-left: 6px;
        cursor: pointer;
      }
    }
  }
`;
