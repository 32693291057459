import React, { useEffect, useState } from "react";
import { DashboardContainer, DropdownWrapper } from "./Dashboard.styles";
import {
  Breadcrumb,
  Divider,
  Layout,
  Menu,
  Space,
  Table,
  Tag,
  message,
  theme,
} from "antd";
import { Content, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import {
  BellOutlined,
  ContactsOutlined,
  InsuranceOutlined,
  OrderedListOutlined,
  PieChartOutlined,
  ProfileOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { FaCcVisa, FaQ } from "react-icons/fa6";
import { BalanceChart } from "./Components/BalanceChart";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../Constants/Routes";
import { setIsLogin, setToken, useLogoutMutation } from "../../Services/Auth";
import { useDispatch } from "react-redux";
import Users from "./Components/Users";
import Visa from "./Components/Visa";
import { useGetDashboardDataQuery } from "../../Services/Visa";
import Contacts from "./Components/Contacts";
import {
  useClearNotificationMutation,
  useGetUserNotificationQuery,
  useGetUserQuery,
  useReadNotificationMutation,
} from "../../Services/Users";
import Orders from "./Components/Orders";
import Profile from "./Components/Profile/Profile";
import { BiCategory, BiGlobe } from "react-icons/bi";
import Countries from "./Components/Countries";
import VisaCategories from "./Components/VisaCategories/VisaCategories";
import { CiSettings } from "react-icons/ci";
import { MdAnnouncement, MdApproval } from "react-icons/md";
import moment from "moment";
import TravelInsurance from "./Components/TravelInsurance";
import FAQs from "./Components/FAQs";
import { handleFieldError } from "../../Utils/Common";
import PortalFooter from "../../Common/Components/PortalFooter/PortalFooter";

const Dashboard = () => {
  const navigate = useNavigate();
  const userId = sessionStorage.getItem("userId");
  const [collapsed, setCollapsed] = useState(window.innerWidth < 767);
  const [activeWindow, setActiveWindow] = useState();
  const [activeKey, setActiveKey] = useState();
  const { data: dashboardData } = useGetDashboardDataQuery();
  const { data: userData } = useGetUserQuery(userId);
  const { data: userNotificationData } = useGetUserNotificationQuery(userId, {
    skip: userId !== null ? false : true,
  });
  const [clearNotification] = useClearNotificationMutation();
  const [ReadNotification] = useReadNotificationMutation();
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [logout] = useLogoutMutation();
  const dispatch = useDispatch();
  const params = useLocation();
  const handleMenuItemClick = (label) => {
    setActiveWindow(label);
  };

  const items = [
    {
      label: "Dashboard",
      key: "1",
      icon: <PieChartOutlined />,
      to: ROUTES.DASHBOARD,
    },
    { label: "Users", key: "2", icon: <UserOutlined />, to: ROUTES.USERS },
    { label: "Visas", key: "3", icon: <FaCcVisa />, to: ROUTES.VISAS },
    {
      label: "Contact Us",
      key: "4",
      icon: <ContactsOutlined />,
      to: ROUTES.ADMINCONTACTUS,
    },
    {
      label: "Countries",
      key: "5",
      icon: <BiGlobe />,
      to: ROUTES.COUNTRIES,
    },
    {
      label: "Visa Categories",
      key: "6",
      icon: <BiCategory />,
      to: ROUTES.VISACATEGORIES,
    },
    {
      label: "Travel Insurance",
      key: "7",
      icon: <InsuranceOutlined />,
      to: ROUTES.TRAVELINSURANCE,
    },
    {
      label: "FAQ",
      key: "8",
      icon: <FaQ />,
      to: ROUTES.FAQs,
    },
  ];

  const userItems = [
    {
      label: "Orders",
      key: "1",
      icon: <OrderedListOutlined />,
      to: ROUTES.ORDERS,
    },
    {
      label: "Profile",
      key: "2",
      icon: <ProfileOutlined />,
      to: ROUTES.PROFILE,
    },
  ];
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Status",
      key: "tags",
      dataIndex: "tags",
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = tag === "Pending" ? "geekblue" : "green";
            if (tag === "Rejected") {
              color = "volcano";
            }
            return (
              <Tag color={color} key={tag}>
                {tag.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <span>Invite {record.name}</span>
          <span>Delete</span>
        </Space>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address: "New York No. 1 Lake Park",
      tags: ["Pending"],
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
      tags: ["Rejected"],
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sydney No. 1 Lake Park",
      tags: ["Approved"],
    },
  ];

  const handleLogoutClick = async () => {
    try {
      const data = {
        userName: sessionStorage.getItem("userName"),
        token: sessionStorage.getItem("accessToken"),
      };
      await logout(data).unwrap();
      sessionStorage.clear();
      dispatch(setToken(""));
      dispatch(setIsLogin(false));
      navigate(ROUTES.LOGIN);
      message.success("Logged Out Successfully");
    } catch (error) {
      message.error(error?.data?.message ?? "Something Went wrong");
    }
  };

  useEffect(() => {
    if (params.pathname.includes("dashboard")) {
      setActiveKey("1");
      setActiveWindow("Dashboard");
    } else if (params.pathname.includes("users")) {
      setActiveKey("2");
      setActiveWindow("Users");
    } else if (params.pathname.includes("visas")) {
      setActiveKey("3");
      setActiveWindow("Visas");
    } else if (params.pathname.includes("contact-us")) {
      setActiveKey("4");
      setActiveWindow("Contact Us");
    } else if (params.pathname.includes("countries")) {
      setActiveKey("5");
      setActiveWindow("Countries");
    } else if (params.pathname.includes("visa-categories")) {
      setActiveKey("6");
      setActiveWindow("Visa Categories");
    } else if (params.pathname.includes("travel-insurance")) {
      setActiveKey("7");
      setActiveWindow("Travel Insurance");
    } else if (params.pathname.includes("FAQs")) {
      setActiveKey("8");
      setActiveWindow("FAQs");
    } else if (params.pathname.includes("orders")) {
      setActiveKey("1");
      setActiveWindow("Orders");
    } else if (params.pathname.includes("profile")) {
      setActiveKey("2");
      setActiveWindow("Profile");
    }
  }, [params.pathname]);

  const clearNotificationFn = async () => {
    try {
      await clearNotification(Number(userId)).unwrap();
    } catch (error) {
      handleFieldError(error);
    }
  };

  const ReadNotificationFn = async (id) => {
    try {
      await ReadNotification(Number(id)).unwrap();
    } catch (error) {
      handleFieldError(error);
    }
  };
  return (
    <DashboardContainer collapsed={collapsed}>
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          trigger={window.innerWidth < 767 && null}
        >
          {/* {window.innerWidth > 767 && (
            <div className="trigger" onClick={() => setCollapsed(!collapsed)}>
              {collapsed ? <IoIosArrowDropright /> : <IoIosArrowDropleft />}
            </div>
          )} */}
          <div
            className={
              collapsed ? "demo-logo-vertical-collapsed" : "demo-logo-vertical"
            }
          >
            <img
              src={
                collapsed
                  ? "../Images/Global_Citizen_Portal_favicon.png"
                  : "../Images/Global_Citizen_Portal_Logo.png"
              }
              alt="logo"
              className="image"
            />
          </div>
          <Menu theme="dark" mode="inline" selectedKeys={[activeKey]}>
            {(userData?.data?.userType === "SUPER_ADMIN"
              ? items
              : userItems
            ).map((item, index) => (
              <Menu.Item
                key={item.key}
                icon={item.icon}
                onClick={() => handleMenuItemClick(item.label)}
              >
                <Link to={item.to}>{item.label}</Link>
              </Menu.Item>
            ))}
          </Menu>
          {/* <div className="logout-btn" onClick={() => handleLogoutClick()}>
            <LogoutOutlined />
            {!collapsed && "Logout"}
          </div> */}
        </Sider>
        <Layout>
          <Header
            style={{
              padding: 0,
              background: colorBgContainer,
            }}
          >
            <Menu
              theme="light"
              mode="horizontal"
              className="menu"
              style={{
                border: "none",
              }}
            >
              <div className="notification-container">
                <Menu.SubMenu
                  key="notification"
                  className="custom-notification-submenu"
                  icon={<BellOutlined />}
                >
                  <DropdownWrapper className="notification-dropdown">
                    <div className="notification-dropdown-container">
                      {userNotificationData?.data?.length > 0 ? (
                        userNotificationData?.data?.map((item, i) => (
                          <Menu.Item
                            key={`notification-${item.id}`}
                            style={{ whiteSpace: "normal", display: "block" }}
                            onClick={() => {
                              window.open(item?.redirectionUrl);
                              ReadNotificationFn(item?.id);
                            }}
                            className={item?.read && "read"}
                          >
                            <div className="notification-header">
                              <div className="logo">
                                {item?.notificationSubType === "APPROVAL" ||
                                item?.notificationSubType === "REJECTION" ? (
                                  <MdApproval />
                                ) : (
                                  <MdAnnouncement />
                                )}
                              </div>
                              <div className="heading">
                                {item?.notificationSubType === "APPROVAL"
                                  ? "Approved"
                                  : item?.notificationSubType === "REJECTION" &&
                                    "Rejected"}
                              </div>
                            </div>
                            <div className="description-container">
                              <div className="divider-container">
                                <Divider type="vertical" />
                              </div>
                              <div className="notification-description-text">
                                {item?.description}
                              </div>
                            </div>
                            <div className="time" style={{ fontSize: "12px" }}>
                              {moment(item?.creationDate).format(
                                "DD MMM, YY [at] h:mm A"
                              )}
                            </div>
                          </Menu.Item>
                        ))
                      ) : (
                        <Menu.Item
                          key="notification-1"
                          style={{ whiteSpace: "normal", display: "block" }}
                        >
                          <div className="no-notification">
                            No Pending Notification
                          </div>
                        </Menu.Item>
                      )}
                    </div>
                    {userNotificationData?.data?.length > 0 && (
                      <>
                        <Menu.Divider />
                        <Menu.Item
                          key="mark-all-as-read"
                          onClick={clearNotificationFn}
                          className="mark-all"
                        >
                          <span>Mark All as Read</span>
                          <CiSettings />
                        </Menu.Item>
                      </>
                    )}
                  </DropdownWrapper>
                </Menu.SubMenu>
              </div>
              <Menu.SubMenu
                title={
                  <div className="profile-container">
                    <div className="image-container">
                      <img
                        src="../Images/Global_Citizen_Portal_Logo.png"
                        alt="profile"
                        className="image"
                      />
                    </div>
                    <div className="content-container">
                      <div className="name">
                        {" "}
                        {userData?.data?.firstName
                          ? userData?.data?.firstName +
                            " " +
                            userData?.data?.lastName
                          : "-"}
                      </div>
                      <div className="sub-text">
                        {userData?.data?.country ?? "-"}
                      </div>
                    </div>
                  </div>
                }
              >
                <Menu.Item onClick={handleLogoutClick}>Logout</Menu.Item>
              </Menu.SubMenu>
            </Menu>
          </Header>
          <Content
            style={{
              margin: "0 16px",
            }}
          >
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>{activeWindow}</Breadcrumb.Item>
            </Breadcrumb>
            <div
              style={{
                padding: 24,
                minHeight: "80vh",
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
                paddingBottom: 70,
              }}
              className="layout-container"
            >
              {activeWindow === "Dashboard" ? (
                <div className="Dashboard">
                  <div className="card-container">
                    <div className="card">
                      <div className="heading-section">
                        <div className="icon">
                          <img
                            src="../Images/ExperiencedTeam.png"
                            alt="form-icon"
                            className="image"
                          />
                        </div>
                        <h5 className="name">Total No. of Users</h5>
                      </div>
                      <div className="value">
                        {dashboardData?.data?.totalUsers ?? "-"}
                      </div>
                    </div>
                    <div className="card">
                      <div className="heading-section">
                        <div className="icon">
                          <img
                            src="../Images/passport.png"
                            alt="form-icon"
                            className="image"
                          />
                        </div>
                        <h5 className="name">Total No. of Visa</h5>
                      </div>
                      <div className="value">
                        {dashboardData?.data?.totalVisa ?? "-"}
                      </div>
                    </div>
                    <div className="card">
                      <div className="heading-section">
                        <div className="icon">
                          <img
                            src="../Images/SignIcon.png"
                            alt="form-icon"
                            className="image"
                          />
                        </div>
                        <h5 className="name">Total No. of Application</h5>
                      </div>
                      <div className="value">
                        {dashboardData?.data?.totalApplication ?? "-"}
                      </div>
                    </div>
                    <div className="card">
                      <div className="heading-section">
                        <div className="icon">
                          <img
                            src="../Images/confirmedVisa.png"
                            alt="form-icon"
                            className="image"
                          />
                        </div>
                        <h5 className="name">Total No. of Confirmed Visa</h5>
                      </div>
                      <div className="value">
                        {dashboardData?.data?.totalConfirmedVisa ?? "-"}
                      </div>
                    </div>
                  </div>
                  <div className="graph-container">
                    <h5 className="heading">Visa Graph</h5>
                    <BalanceChart />
                  </div>
                </div>
              ) : activeWindow === "Users" ? (
                <Users />
              ) : activeWindow === "Visas" ? (
                <Visa />
              ) : activeWindow === "Contact Us" ? (
                <Contacts />
              ) : activeWindow === "Orders" ? (
                <Orders />
              ) : activeWindow === "Profile" ? (
                <Profile userData={userData?.data} />
              ) : activeWindow === "Countries" ? (
                <Countries />
              ) : activeWindow === "Visa Categories" ? (
                <VisaCategories />
              ) : activeWindow === "Travel Insurance" ? (
                <TravelInsurance />
              ) : activeWindow === "FAQs" ? (
                <FAQs />
              ) : (
                <Table columns={columns} dataSource={data} />
              )}
            </div>
          </Content>
          <PortalFooter />
        </Layout>
      </Layout>
    </DashboardContainer>
  );
};

export default Dashboard;
