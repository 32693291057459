import React, { useCallback, useEffect, useState } from "react";
import { AddCountryModal } from "./AddCountry.styles";
import { Input, Select, message } from "antd";
import { StyledButton } from "../../../Screens/ApplicationForm/Components/OrderInformation/OrderInformation.styles";
import { Country } from "country-state-city";
import Dragger from "antd/es/upload/Dragger";
import { IoAdd } from "react-icons/io5";
import { config } from "../../../Utils/Config";
import axios from "axios";
import {
  useGetAllCountriesMutation,
  useGetCountryDetailsMutation,
} from "../../../Services/Users";
import { handleFieldError } from "../../../Utils/Common";
import { useGetDocumentsMutation } from "../../../Services/Visa";

const AddCountry = ({
  handleOk,
  isModalOpen,
  handleCancel,
  setUploadRes,
  editCountryId,
}) => {
  const [value, setValue] = useState("");
  const [description, setDescription] = useState();
  const [countries, setCountries] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [existingCountries, setExistingCountries] = useState([]);
  const [getAllCountries] = useGetAllCountriesMutation();
  const [countryOptions, setCountryOptions] = useState([]);
  const [countryDetails, setCountryDetails] = useState();
  const [getDocuments] = useGetDocumentsMutation();

  const getAllCountriesFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: 295,
        current: 0,
      };
      const response = await getAllCountries(reqData).unwrap();
      setExistingCountries(response?.data);
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllCountries]);

  useEffect(() => {
    getAllCountriesFn();
  }, [getAllCountriesFn]);
  const [getCountryDetails] = useGetCountryDetailsMutation();

  const getCountryDetailsFn = useCallback(async () => {
    try {
      const response = await getCountryDetails(editCountryId?.id).unwrap();
      setCountryDetails(response?.data);
      setDescription(response?.data?.description);
      setValue(response?.data?.name);
      const document = await getDocuments([
        response?.data?.documentId,
      ]).unwrap();
      const fileListEntry = {
        uid: document?.data[0]?.id,
        name: document?.data[0]?.fileName,
        status: "done",
        url: document?.data[0]?.path,
        thumbUrl: document?.data[0]?.path,
      };
      setFileList([fileListEntry]);
    } catch (error) {
      handleFieldError(error);
    }
  }, [editCountryId?.id, getCountryDetails, getDocuments]);

  useEffect(() => {
    if (editCountryId?.id) {
      getCountryDetailsFn();
    }
  }, [editCountryId, getCountryDetailsFn]);

  const handleOnChange = (e) => {
    if (value) {
      if (e.file.status === "uploading" || e.file.status === "done") {
        setFileList(e.fileList);
        if (e.file.status === "done") {
          message.success(`${e.file.name} file uploaded successfully`);
        }
      } else if (e.file.status === "error") {
        message.error(`${e.file.name} file upload failed.`);
      }
    }
  };

  const uploadImageFn = async (options, i) => {
    const { onSuccess, onError, file, onProgress } = options;
    const fmData = new FormData();
    const configg = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: sessionStorage.getItem("token"),
      },
      onUploadProgress: (event) => {
        onProgress({ percent: (event.loaded / event.total) * 100 });
      },
    };
    fmData.append("file", file);
    try {
      const res = await axios.put(
        `${config.BASE_URL}api/v1/country/uploadFile?documentName=${value}`,
        fmData,
        configg
      );
      onSuccess("Ok");
      setUploadRes(res);
    } catch (err) {
      message.error(err?.data?.message);
      onError({ err });
    }
  };
  const handleModalCancel = () => {
    handleCancel();
    setValue("");
    setDescription("");
    setFileList([]);
  };

  useEffect(() => {
    setCountries(
      Country.getAllCountries()?.map((item, i) => {
        return {
          key: i,
          label: item?.name,
          value: item?.name,
          isoCode: item?.isoCode,
        };
      })
    );
  }, []);

  useEffect(() => {
    if (countries?.length > 0 && existingCountries?.data?.length > 0) {
      const existingCountryNames = new Set(
        existingCountries?.data?.map((country) => country.name)
      );

      const newCountryOptions = countries
        .filter((country) => !existingCountryNames.has(country?.label))
        .map((country, index) => ({
          key: `${country?.isoCode}-${index}`,
          label: country?.label,
          value: country?.label,
          isoCode: country?.isoCode,
        }));

      setCountryOptions(newCountryOptions);
    } else {
      setCountryOptions(countries);
    }
  }, [countries, existingCountries]);

  return (
    <AddCountryModal
      onCancel={handleModalCancel}
      open={isModalOpen}
      footer={null}
      destroyOnClose={true}
      width={370}
    >
      <div className="heading">
        {editCountryId?.id ? "Update" : "Add"} Country
      </div>
      <div className="main-container">
        <Select
          showSearch
          placeholder="Select Country"
          filterOption={(input, option) =>
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={countryOptions}
          onChange={(e) => setValue(e)}
          value={value}
        />
        <Input
          bordered={false}
          placeholder="Description"
          className="input"
          onChange={(e) => setDescription(e?.target?.value)}
          value={description}
        />
        <Dragger
          className="dragger"
          maxCount={1}
          fileList={fileList}
          customRequest={(e) => uploadImageFn(e)}
          onChange={(e) => handleOnChange(e)}
          onRemove={() => setFileList([])}
        >
          <IoAdd />
          <div className="text">Upload Country Image</div>
        </Dragger>
        <StyledButton
          className="button"
          onClick={() => {
            handleOk(value, description);
            setValue("");
            setDescription("");
          }}
        >
          {editCountryId?.id ? "Update" : "Add"}
        </StyledButton>
      </div>
    </AddCountryModal>
  );
};
export default AddCountry;
