import React, { useCallback, useEffect, useState } from "react";
import { CountryDetailsContainer } from "./CountryDetails.styles";
import CountryDetailsHeader from "./Components/CountryDetailsHeader/CountryDetailsHeader";
import { handleFieldError, scrollToTop } from "../../Utils/Common";
import { IoMdArrowDropright } from "react-icons/io";
import { TiWeatherSunny } from "react-icons/ti";
import { MdLanguage } from "react-icons/md";
import { HiOutlineCurrencyDollar } from "react-icons/hi2";
import { BsBarChartSteps } from "react-icons/bs";
import { FaShippingFast } from "react-icons/fa";
import { BiLogoTelegram } from "react-icons/bi";
import VisaType from "./Components/VisaType/VisaType";
import FeeCalculator from "./Components/FeeCalculator/FeeCalculator";
import { useGetAllCountriesMutation } from "../../Services/Users";
import { useNavigate, useParams } from "react-router-dom";
import { useGetCountryVisaDetailsQuery } from "../../Services/Visa";
import { ROUTES } from "../../Constants/Routes";
import { countryDescription } from "../../Utils/CountryDescription";

const CountryDetails = () => {
  const [countries, setCountries] = useState([]);
  const [countryDetails, setCountryDetails] = useState();
  const [visaCategories, setVisaCategories] = useState();
  const [allvisaCategories, setAllVisaCategories] = useState();
  const [selectedVisaCategory, setSelectedVisaCategory] = useState();
  const [fee, setFee] = useState();
  const [getAllCountries] = useGetAllCountriesMutation();
  const { country } = useParams();
  const { countryId } = useParams();
  const { data: visaDetails, error } = useGetCountryVisaDetailsQuery(
    countryId,
    {
      skip: countryId !== null ? false : true,
    }
  );
  const navigate = useNavigate();
  const visaCategoryFn = useCallback(() => {
    try {
      if (error === undefined) {
        if (visaDetails?.data?.visaCategories?.length > 0) {
          setAllVisaCategories(visaDetails?.data);
          setVisaCategories(
            visaDetails?.data?.visaCategories?.map((item, i) => {
              return {
                key: i,
                label: item?.name,
                value: item?.id,
              };
            })
          );
        }
      } else {
        setAllVisaCategories();
        setVisaCategories();
      }
    } catch (error) {
      setAllVisaCategories();
      setVisaCategories();
    }
  }, [visaDetails, error]);

  useEffect(() => {
    visaCategoryFn();
  }, [visaDetails, country, countryId, visaCategoryFn]);

  useEffect(() => {
    scrollToTop(0);
  }, []);

  const getAllCountriesFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: 300,
        current: 0,
      };
      const response = await getAllCountries(reqData).unwrap();
      const allCountries = response?.data?.data;
      const countryIndex = allCountries.findIndex((c) => c.name === country);
      if (countryIndex !== -1) {
        const updatedCountries = [
          allCountries[countryIndex],
          ...allCountries.slice(0, countryIndex),
          ...allCountries.slice(countryIndex + 1),
        ];
        setCountries(updatedCountries);
      } else {
        setCountries(allCountries);
      }
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllCountries, country]);

  useEffect(() => {
    getAllCountriesFn();
  }, [getAllCountriesFn]);

  useEffect(() => {
    countryDescription?.forEach((item, i) => {
      if (item?.countryName.toLowerCase() === country.toLowerCase()) {
        setCountryDetails(item);
      }
    }, []);
  }, [country]);

  useEffect(() => {
    visaDetails?.data?.visaCategories?.forEach((item) => {
      if (selectedVisaCategory === item?.name) {
        setFee(item);
      }
    });
  }, [selectedVisaCategory, setFee, visaDetails?.data?.visaCategories]);
  return (
    <CountryDetailsContainer>
      <CountryDetailsHeader country={country} />
      <div className="main-container">
        <div className="left-container">
          <div className="card-container">
            {countries?.length > 0 &&
              countries?.map((item) => {
                return (
                  <div
                    className={`country-name-card ${
                      country === item?.name && "current-country"
                    }`}
                    onClick={() =>
                      navigate(
                        `${ROUTES.COUNTRYDETAILS}/${item?.name}/${item?.id}`
                      )
                    }
                  >
                    <div className="country-name ">{item?.name}</div>
                    <IoMdArrowDropright />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="right-container">
          {/* <div className="country-poster">
            <img
              src="/Images/country-details_06.jpg"
              alt="country poster"
              className="image"
            />
          </div> */}
          <div className="content-container">
            <h4 className="heading uppercase">WELCOME TO {country}</h4>
            <div className="content">{countryDetails?.welcomeNote}</div>
          </div>
          <div className="points-container">
            {/* <div className="points-image-container">
              <img
                src="/Images/single-img-14.jpg"
                alt="poster"
                className="image"
              />
            </div> */}
            <div className="points">
              <div className="list-item">
                <div className="logo-container">
                  <TiWeatherSunny />
                </div>
                <div className="item-content">
                  <span className="content-heading">Climate: </span>
                  {countryDetails?.climate}
                </div>
              </div>
              <div className="list-item">
                <div className="logo-container">
                  <MdLanguage />
                </div>
                <div className="item-content">
                  <span className="content-heading">Language: </span>
                  {countryDetails?.language}
                </div>
              </div>
              <div className="list-item">
                <div className="logo-container">
                  <HiOutlineCurrencyDollar />
                </div>
                <div className="item-content">
                  <span className="content-heading">Currency: </span>
                  {countryDetails?.currency}
                </div>
              </div>
            </div>
          </div>
          <div className="content-container">
            <h4 className="heading">About {country} eVisa</h4>
            <div className="content">{countryDetails?.about}</div>
            <div className="feature-box-container">
              <div className="feature-box">
                <div className="icon-main-container">
                  <div className="icon-container"></div>
                  <BsBarChartSteps />
                </div>
                <div className="box-content-container">
                  <h5 className="box-heading">Apply Online in 3 Steps</h5>
                </div>
              </div>
              <div className="feature-box">
                <div className="icon-main-container">
                  <div className="icon-container"></div>
                  <FaShippingFast />
                </div>
                <div className="box-content-container">
                  <h5 className="box-heading">As Soon As 1 Day</h5>
                </div>
              </div>
              <div className="feature-box">
                <div className="icon-main-container">
                  <div className="icon-container"></div>
                  <BiLogoTelegram />
                </div>
                <div className="box-content-container">
                  <h5 className="box-heading">Receive Visa by Email</h5>
                </div>
              </div>
            </div>
          </div>
          {allvisaCategories && (
            <VisaType country={country} visaCategories={allvisaCategories} />
          )}
          <div className="government-instructions-container">
            <h2 className="heading">
              General {country} eVisa Government Instruction
            </h2>
            <div className="instructions-container">
              <div className="content-container">
                <h3 className="content-heading">Overview</h3>
                <div className="content">
                  {country} Immigration Department allows visitor to apply for
                  the {country} eVisa online without having to visit {country}{" "}
                  Embassy, or obtain the visa on arrrival.
                </div>
                <h3 className="content-heading">Points to note</h3>
                <ol>
                  <li>Visa processing fee is non-refundable.</li>
                  <li>Incomplete applications will be rejected.</li>
                  <li>
                    Possession of an eVisa does not grant final authority to
                    enter {country}.
                  </li>
                  <li>
                    A visa must be obtained prior to entry into {country}.
                  </li>
                  <li>The e-visa must be presented at the point of entry.</li>
                  <li>
                    It is recommended to apply at least 4 working days before
                    the trip starts.
                  </li>
                </ol>
              </div>
              <div className="image-container">
                <img
                  src="/Images/india-intro.jpg"
                  alt="intro"
                  className="image"
                />
              </div>
            </div>
          </div>
          <FeeCalculator
            visaCategories={visaCategories}
            setSelectedVisaCategory={setSelectedVisaCategory}
            fee={fee}
          />
        </div>
      </div>
    </CountryDetailsContainer>
  );
};

export default CountryDetails;
