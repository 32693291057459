import styled from "styled-components";
import { deviceQuery } from "../../Utils/MediaSizes";

export const FAQsContainer = styled.div`
  max-width: 1170px;
  display: flex;
  gap: 25px;
  margin: auto;
  padding: 90px 0px;
  @media (${deviceQuery.laptopML}) {
    flex-direction: column;
    align-items: center;
  }
`;
