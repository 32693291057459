import React, { useEffect, useState } from "react";
import { TransactionFailedContainer } from "./TransactionFailed.styled";
import { useNavigate, useParams } from "react-router-dom";
import { useTransactionCancelMutation } from "../../Services/Visa";
import { ROUTES } from "../../Constants/Routes";
import { StyledButton } from "../ApplicationForm/Components/OrderInformation/OrderInformation.styles";

const TransactionFailed = () => {
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(5);
  const [transactionCancel] = useTransactionCancelMutation();
  const { id } = useParams();
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  setTimeout(() => {
    navigate(ROUTES.HOME);
  }, 5000);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const transactionFailFn = async () => {
    try {
      await transactionCancel(id).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    transactionFailFn();
  }, []);

  return (
    <TransactionFailedContainer>
      <img
        src="/Images/caution.png"
        rel="noreferrer"
        alt="caution"
        className="image"
      />
      <div className="heading">Transaction Failed</div>
      <div className="id">Please Try Again</div>
      <div className="timer">
        Redirecting to Home in: {minutes}:
        {remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}
      </div>
      <StyledButton
        onClick={() => navigate(ROUTES.HOME)}
        className="button"
        disabled={seconds > 0}
      >
        Go to Home
      </StyledButton>
    </TransactionFailedContainer>
  );
};

export default TransactionFailed;
