import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const ShortTermVisaContainer = styled.div`
  background: url("../Images/row-bgimage-7.png") center center/cover;
  background-color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .term-visa-heading {
    text-align: center;
    color: ${(props) => props.theme.colors.darkBlue2};
    font-weight: 600;
    font-size: 35px;
    line-height: 46px;
    font-family: "Roboto", sans-serif;
    margin-bottom: 0px;
    .diff {
      color: ${(props) => props.theme.colors.blue};
    }

    @media (${deviceQuery.mobileSM}) {
      font-size: 28px;
      line-height: 35px;
    }
  }

  .card-container {
    padding: 40px;
    display: grid !important;
    grid-template-columns: repeat(4, 1fr);
    gap: 22px;
    justify-content: center;
    @media (${deviceQuery.desktop}) {
      grid-template-columns: repeat(3, 1fr);
    }
    @media (${deviceQuery.laptopML}) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (${deviceQuery.tabletM}) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;
