import React, { useEffect } from "react";
import { AboutUsContainer } from "./AboutUs.styles";
import AboutUsHeader from "./Components/AboutUsHeader/AboutUsHeader";
import AboutCompany from "../HomePage/Components/AboutCompany/AboutCompany";
import TrustWorthy from "./Components/TrustWorthy/TrustWorthy";
import OurExpertise from "./Components/OurExpertise/OurExpertise";
import { scrollToTop } from "../../Utils/Common";

const AboutUs = () => {
  useEffect(() => {
    scrollToTop(0);
  }, []);
  return (
    <AboutUsContainer>
      <AboutUsHeader />
      <AboutCompany />
      <TrustWorthy />
      <OurExpertise />
    </AboutUsContainer>
  );
};

export default AboutUs;
