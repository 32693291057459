import styled from "styled-components";
import { deviceQuery } from "../../Utils/MediaSizes";

export const CheckStatusContainer = styled.div`
  .content-container {
    background-color: ${(props) => props.theme.colors.lightBlue};
    box-shadow: 0px 4px 20px 0px rgb(238 238 238 / 5);
    margin: 60px auto 40px;
    max-width: 1170px;
    padding: 20px 60px;
    display: flex;
    gap: 20px;
    justify-content: center;
    @media (${deviceQuery.laptopS}) {
      flex-direction: column;
      padding: 20px;
      gap: 0;
    }
    .left-container {
      height: 100%;
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 30px 20px;
      @media (${deviceQuery.laptopS}) {
        width: 100%;
        padding: 10px;
      }
      .container-heading {
        font-weight: 600;
        text-transform: capitalize;
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 0;
        color: ${(props) => props.theme.colors.darkBlue};
        align-self: self-start;
        margin-top: 0px;
        @media (${deviceQuery.mobileSM}) {
          font-size: 28px;
          line-height: 32px;
        }
        .diff {
          color: ${(props) => props.theme.colors.blue};
        }
      }
      .content {
        font-size: 18px;
        line-height: 28px;
        letter-spacing: 0.2px;
        color: ${(props) => props.theme.colors.gray};
        font-weight: 500;
        margin-top: 20px;
        padding-bottom: 5px;
        text-align: justify;
        @media (${deviceQuery.mobileSM}) {
          font-size: 16px;
          line-height: 24px;
          margin-top: 8px;
        }
        p {
          margin-bottom: 30px;
          @media (${deviceQuery.mobileSM}) {
            margin-bottom: 8px;
          }
        }
      }
      .image-container {
        height: 200px;
        width: 200px;
        .image {
          height: 100%;
          width: 100%;
          border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
        }
      }
    }
    .right-container {
      width: 55%;
      @media (${deviceQuery.laptopS}) {
        width: 100%;
      }
    }
    .active {
      position: relative;
      animation-name: opac;
      animation-duration: 1s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease;
      @keyframes opac {
        0% {
          left: 0px;
          top: 0px;
        }
        100% {
          left: -200px;
          top: 0px;
        }
      }
    }
    .active-status {
      position: relative;
      animation-name: opac;
      animation-duration: 1s;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease;
      @keyframes opac {
        0% {
          left: 200px;
          top: 0px;
        }
        100% {
          left: 0px;
          top: 0px;
        }
      }
    }
    .status-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 8px;
      justify-content: center;
      align-items: center;
      h3 {
        color: ${(props) => props.theme.colors.blue};
        font-size: 24px;
        font-weight: 600;
        margin-top: 0;
      }
      h5 {
        margin-bottom: 0;
        color: ${(props) => props.theme.colors.darkBlue};
        font-size: 20px;
        font-weight: 500;
      }
      .check-button {
        max-width: 220px !important;
      }
      .not-recieve {
        margin: 20px 0px;
        color: ${(props) => props.theme.colors.darkBlue};
        .contact {
          color: ${(props) => props.theme.colors.blue};
          font-weight: 500;
          cursor: pointer;
        }
      }
    }
  }
`;
