import React, { useState } from "react";
import { ResetPasswordContainer } from "./ResetPassword.styles";
import { Divider, Form, Input, message } from "antd";
import { StyledButton } from "../ContactUs/Components/ContactUsForm/ContactUsForm.styles";
import { useResetPasswordMutation } from "../../Services/Auth";
import { handleFieldError } from "../../Utils/Common";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Constants/Routes";

const ResetPassword = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [ResetPassword] = useResetPasswordMutation();
  const otp = sessionStorage.getItem("otp");
  const resetPasswordFn = async (values) => {
    try {
      const data = {
        ...values,
        otp: Number(otp),
        userName: sessionStorage.getItem("userName"),
      };
      await ResetPassword(data).unwrap();
      navigate(ROUTES.LOGIN);
      sessionStorage.clear();
      message.success("Password reset Successfully");
    } catch (error) {
      handleFieldError(error);
    }
  };

  return (
    <ResetPasswordContainer>
      <div className="main-container">
        <div className="right-container">
          <div className="card">
            <h3 className="heading">Reset Password</h3>
            {/* <h5 className="sub-heading">Step </h5> */}
            <div className="google-login-container"></div>
            <Divider />
            <Form className="form" onFinish={resetPasswordFn}>
              <div className="input-container">
                <label>New Password</label>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input
                    type={showPassword ? "text" : "password"}
                    className="input"
                    suffix={
                      showPassword ? (
                        <EyeInvisibleOutlined
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <EyeOutlined
                          onClick={() => setShowPassword(!showPassword)}
                          style={{ cursor: "pointer" }}
                        />
                      )
                    }
                  />
                </Form.Item>
              </div>
              <div className="input-container">
                <label>Confirm New Password</label>
                <Form.Item
                  name="confirmPassword"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please confirm password!",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("Password doesn't match")
                        );
                      },
                    }),
                  ]}
                >
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    className="input"
                    suffix={
                      showConfirmPassword ? (
                        <EyeInvisibleOutlined
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <EyeOutlined
                          onClick={() =>
                            setShowConfirmPassword(!showConfirmPassword)
                          }
                          style={{ cursor: "pointer" }}
                        />
                      )
                    }
                  />
                </Form.Item>
              </div>
              <StyledButton htmlType="submit">Reset Password</StyledButton>
            </Form>
          </div>
        </div>
      </div>
    </ResetPasswordContainer>
  );
};

export default ResetPassword;
