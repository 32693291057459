import React from "react";
import { ShortTermVisaContainer } from "./ShortTermVisa.styles";
import ShortTermVisaCard from "../../../../Common/Cards/ShortTermVisaCard/ShortTermVisaCard";

const ShortTermVisa = () => {
  const cardData = [
    {
      key: 1,
      heading: "Tourist Visa",
      content:
        "The holder of this visa type will be allowed to explore the corresponding country for leisure. And it typically requires a valid passport and travel itinerary.",
    },
    {
      key: 2,
      heading: "Business Visa",
      content:
        "The holder of this visa type will be allowed to do business in the corresponding country. And it usually requires some extra documents.",
    },
    {
      key: 3,
      heading: "Transit Visa",
      content:
        "It is issued to persons connecting through a country to other destinations for a determined period. Many countries exempt this type of visa.",
    },
    {
      key: 4,
      heading: "Medical Visa",
      content:
        "Medical visa is used for travelers who want to be treated under the system of medicine of a country. It is used by just some governments.",
    },
  ];
  return (
    <ShortTermVisaContainer>
      <h2 className="term-visa-heading">
        <span className="diff">Visa</span> Categories
      </h2>
      <div className="card-container">
        {cardData?.map((item) => {
          return <ShortTermVisaCard item={item} key={item?.key} />;
        })}
      </div>
    </ShortTermVisaContainer>
  );
};

export default ShortTermVisa;
