import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const TrustWorthyContainer = styled.div`
  padding: 20px;
  margin-bottom: 60px;
  @media (${deviceQuery.laptopS}) {
    margin-bottom: 0px;
    padding-bottom: 0px;
  }
  @media (${deviceQuery.mobileXS}) {
    padding: 10px;
  }
  .trustworthy-heading {
    font-weight: 700;
    text-align: center;
    font-size: 28px;
    line-height: 28px;
    color: ${(props) => props.theme.colors.darkBlue};
    text-transform: uppercase;
    margin-bottom: 8px;
    margin-top: 0;
  }
  .trustworthy-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${(props) => props.theme.colors.blue};
    text-align: center;
    margin: 0;
  }
  .card-container {
    margin: auto;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    gap: 20px;
    place-items: center;
    row-gap: 62px;
    @media (${deviceQuery.laptopML}) {
      width: fit-content;
      gap: 8px;
      margin: 70px auto 30px;
    }
    @media (${deviceQuery.laptopS}) {
      grid-template-columns: 1fr;
      margin: 70px auto 10px;
    }
    @media (${deviceQuery.mobileL}) {
      margin: 30px auto;
    }
    .card1 {
      .content-card {
        @media (${deviceQuery.laptopML}) {
          bottom: 0;
          top: -50px;
        }
        @media (${deviceQuery.laptopS}) {
          left: -140px !important;
        }
      }
    }
    .card2 {
      margin-right: 100px;
      .content-card {
        left: 180px !important;
        @media (${deviceQuery.laptopML}) {
          bottom: 0;
          top: -50px;
        }
      }
    }
    .card3 {
      margin-left: 40px;
      .content-card {
        bottom: 180px !important;
        left: 140px !important;
        @media (${deviceQuery.laptopML}) {
          top: 180px;
          left: -140px !important;
        }
        @media (${deviceQuery.laptopS}) {
          top: -20px;
        }
      }
    }
    .card4 {
      justify-self: center;
      margin-right: 40px;
      .content-card {
        bottom: 180px !important;
        left: -170px !important;
        @media (${deviceQuery.laptopML}) {
          top: 180px;
          left: 170px !important;
        }
        @media (${deviceQuery.laptopS}) {
          top: -20px;
        }
      }
    }
    .card {
      width: 300px;
      @media (${deviceQuery.mobileL}) {
        min-height: 500px;
        margin: 0;
      }
      @media (${deviceQuery.mobileXS}) {
        width: 260px;
        min-height: 420px;
      }
      .image-container {
        position: relative;
        width: 300px;
        height: 300px;
        z-index: 0;
        @media (${deviceQuery.mobileL}) {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        @media (${deviceQuery.mobileXS}) {
          width: 240px;
          height: 240px;
        }
        .image {
          height: 100%;
          width: 100%;
        }
      }
      .content-card {
        z-index: 1;
        position: absolute;
        bottom: -50px;
        left: -150px;
        height: 180px;
        width: 280px;
        padding: 20px;
        background: ${(props) => props.theme.colors.white};
        box-shadow: ${(props) => props.theme.shadow.cardShadow};
        border-radius: 6px;
        @media (${deviceQuery.mobileL}) {
          position: static;
        }
        @media (${deviceQuery.mobileXS}) {
          width: 260px;
          height: auto;
        }
        .heading {
          color: ${(props) => props.theme.colors.darkBlue};
          font-size: 20px;
          line-height: 25px;
          margin-bottom: 8px;
          margin-top: 0;
        }
        .text {
          color: ${(props) => props.theme.colors.gray};
          font-size: 14px;
          text-align: justify;
        }
      }
    }
  }
`;
