import { styled } from "styled-components";
import { deviceQuery } from "../../Utils/MediaSizes";

export const TransactionFailedContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  gap: 25px;
  text-align: center;
  padding: 20px;
  .image {
    height: 100px;
    width: 100px;
  }
  .heading {
    font-weight: 800;
    font-size: 40px;
    color: ${(props) => props.theme.colors.darkBlue};
    @media (${deviceQuery.mobileM}) {
      font-weight: 600;
      font-size: 28px;
    }
  }
  .id {
    font-size: 32px;
    font-weight: 500;
    max-width: 70vw;
    color: ${(props) => props.theme.colors.darkBlue};
    @media (${deviceQuery.mobileM}) {
      font-size: 24px;
    }
  }
  .timer {
    font-size: 24px;
    color: ${(props) => props.theme.colors.blue};
    @media (${deviceQuery.mobileM}) {
      font-size: 18px;
    }
  }
  .button {
    max-width: 400px;
  }
`;
