import React from "react";
import { ShortTermVisaCardContainer } from "./ShortTermVisaCard.styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Constants/Routes";

const ShortTermVisaCard = ({ item }) => {
  const navigate = useNavigate();
  return (
    <ShortTermVisaCardContainer>
      {/* <div className="image-container">
        <img src="../Images/country.jpg" alt="country" className="image"></img>
      </div> */}
      <div className="content-container">
        <div className="country-name-container">
          <h5>
            <div className="country-name">{item?.heading}</div>
          </h5>
        </div>
        <div className="content">{item?.content}</div>
        <div className="read-more" onClick={() => navigate(ROUTES.APPLICATIONFORM)}>
          Apply Now
        </div>
      </div>
    </ShortTermVisaCardContainer>
  );
};

export default ShortTermVisaCard;
