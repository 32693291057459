import React, { useCallback, useEffect, useState } from "react";
import { AllVisaContainer } from "./AllVisa.styles";
import AllVisaHeader from "./Components/AllVisaHeader/AllVisaHeader";
import CommonVisaCard from "../../Common/Cards/CommonVisaCard/CommonVisaCard";
import ExploreBanner from "./Components/ExploreBanner/ExploreBanner";
import ApplyingSteps from "../../Common/Components/ApplyingSteps/ApplyingSteps";
import { handleFieldError, scrollToTop } from "../../Utils/Common";
import { useGetAllCountriesMutation } from "../../Services/Users";
import { useGetDocumentsMutation } from "../../Services/Visa";

const AllVisa = () => {
  useEffect(() => {
    scrollToTop(0);
  }, []);
  const stepsHeading = (
    <>
      Apply With 4 Simple <span className="diff">Steps</span>
    </>
  );

  const [countries, setCountries] = useState([]);
  const [documentIds, setDocumentIds] = useState([]);
  const [getAllCountries] = useGetAllCountriesMutation();
  const [getDocuments] = useGetDocumentsMutation();
  const [documentData, setDocumentData] = useState();

  const getAllCountriesFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: 300,
        current: 0,
      };
      const response = await getAllCountries(reqData).unwrap();
      setCountries(response?.data?.data);
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllCountries]);

  useEffect(() => {
    getAllCountriesFn();
  }, [getAllCountriesFn]);

  const getAllDocumentsFn = useCallback(async () => {
    try {
      const response = await getDocuments(documentIds).unwrap();
      setDocumentData(response?.data);
    } catch (error) {
      handleFieldError(error);
    }
  }, [getDocuments, documentIds]);

  useEffect(() => {
    getAllDocumentsFn();
  }, [getAllDocumentsFn]);

  useEffect(() => {
    const ids = countries?.map((doc) => doc.documentId);
    setDocumentIds(ids);
  }, [countries, getAllCountriesFn]);

  return (
    <AllVisaContainer>
      <AllVisaHeader />
      <div className="card-container">
        {countries?.map((item) => {
          const matchedDocument = documentData?.find(
            (doc) => doc.id === item.documentId
          );
          return (
            <CommonVisaCard
              key={item.documentId}
              item={item}
              documentData={matchedDocument}
            />
          );
        })}
      </div>
      <ApplyingSteps heading={stepsHeading} />
      <ExploreBanner />
    </AllVisaContainer>
  );
};

export default AllVisa;
