import React, { useEffect, useState } from "react";
import { FeeCalculatorContainer } from "./FeeCalculator.styles";
import { Select } from "antd";
import { StyledButton } from "../../../CheckStatus/Components/CheckStatusForm/CheckStatusForm.styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../Constants/Routes";
import { Country } from "country-state-city";

const FeeCalculator = ({ visaCategories, setSelectedVisaCategory, fee }) => {
  const [value, setValue] = useState(0);
  const [countries, setCountries] = useState([]);
  const [processTime, setProcessTime] = useState(0);
  const decrease = () => {
    if (value > 0) {
      setValue((prev) => prev - 1);
    }
  };
  const increase = () => {
    setValue((prev) => prev + 1);
  };
  const navigate = useNavigate();
  useEffect(() => {
    setCountries(
      Country.getAllCountries()?.map((item, i) => {
        return {
          key: i,
          label: item?.name,
          value: item?.name,
          isoCode: item?.isoCode,
        };
      })
    );
  }, []);
  return (
    <FeeCalculatorContainer>
      <h2 className="heading">Fee Calculator</h2>
      <div className="content">
        Special note: You will receive a special discount when you make group
        order.
      </div>
      <div className="select-container">
        <div className="number-of-visa">
          <div className="label">Number Of Visa</div>
          <div className="value-container">
            <span
              className={value > 0 ? "incr-button active-incr" : "incr-button"}
              onClick={decrease}
            >
              -
            </span>
            <span className="value">{value ?? 0}</span>
            <span className="incr-button active-incr" onClick={increase}>
              +
            </span>
          </div>
        </div>
        <Select
          type="text"
          placeholder="Type Of Visa"
          className="select input"
          options={visaCategories}
          onChange={(e) => {
            visaCategories?.forEach((item) => {
              if (item?.value === e) {
                setSelectedVisaCategory(item?.label);
              }
            });
          }}
        />
        <Select
          type="text"
          placeholder="Processing Time"
          className="select input"
          options={[
            {
              label: "Normal (Guranteed within 3 working days)",
              value: 3,
            },
            {
              label: "Urgent (Guranteed within 2 working days)",
              value: 2,
            },
            {
              label: "Super Urgent (After 1 working day)",
              value: 1,
            },
          ]}
          onChange={(e) => {
            setProcessTime(e);
          }}
        />
        <Select
          showSearch
          type="text"
          placeholder="Nationality *"
          className="select input"
          filterOption={(input, option) =>
            (option?.value ?? "").toLowerCase().includes(input.toLowerCase())
          }
          options={countries}
        />
      </div>
      <div className="fee-main-container">
        <div className="fees-container content">
          <div className="fees-divider">
            <div className="fee-text">Visa Fee:</div>
            {/* <div className="divider" /> */}
          </div>
          <div className="fee">
            US${" "}
            {fee && (fee?.visaFee || fee.visaFee === 0)
              ? `$ ${fee?.visaFee * value}`
              : "00.00"}
          </div>
        </div>
        <div className="fees-container content">
          <div className="fees-divider">
            <div className="fee-text">Service Fee:</div>
            {/* <div className="divider" /> */}
          </div>
          <div className="fee">
            US${" "}
            {fee && (fee?.serviceCharge || fee.serviceCharge === 0)
              ? `$ ${fee?.serviceCharge}`
              : "00.00"}
          </div>
        </div>
        {/* <div className="fees-container content">
          <div className="fees-divider">
            <div className="fee-text">Visa Fee Discount:</div>
            <div className="divider" />
          </div>
          <div className="fee">
            <strike>US$ 00.00</strike>
          </div>
        </div> */}
        <div className="fees-container content">
          <div className="fees-divider">
            <div className="fee-text">
              {processTime === 1
                ? "Super Urgent"
                : processTime === 2
                ? "Urgent"
                : "Normal"}{" "}
              Processing {processTime} working days:
            </div>
            {/* <div className="divider" /> */}
          </div>
          <div className="fee">
            US$ {processTime ? 0 * processTime : "00.00"}
          </div>
        </div>
        <div className="total-fees-container">
          <h3 className="total-fee">
            Total: US${" "}
            {fee &&
            ((fee.visaFee && fee.serviceCharge) || fee.serviceCharge === 0)
              ? `$ ${fee.visaFee * value + fee.serviceCharge}`
              : "00.00"}
          </h3>
        </div>
        <StyledButton
          className="button"
          onClick={() => navigate(ROUTES.APPLICATIONFORM)}
        >
          Apply Now
        </StyledButton>
      </div>
    </FeeCalculatorContainer>
  );
};

export default FeeCalculator;
