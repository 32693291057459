import { Table, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { handleFieldError } from "../../../../Utils/Common";
import { useGetAllCountriesMutation } from "../../../../Services/Users";
import { StyledButton } from "../../../ContactUs/Components/ContactUsForm/ContactUsForm.styles";
import { FaRegEdit } from "react-icons/fa";
import { ExpandableRowContainer } from "../../Dashboard.styles";
import AddVisaCategory from "../../../../Common/Modals/AddVisaCategories/AddVisaCategories";
import {
  useAddVisaDetailMutation,
  useEditVisaDetailMutation,
  useGetVisaDetailAllQuery,
} from "../../../../Services/Visa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useForm } from "antd/es/form/Form";
import { VisaCategoriesContainer } from "./VisaCategories.styles";

const VisaCategories = () => {
  const [getAllCountries] = useGetAllCountriesMutation();
  const [countryData, setCountryData] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newCategoriesDetail, setNewCategoriesDetail] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [editId, setEditId] = useState(null);
  const [editVisaDetail] = useEditVisaDetailMutation();
  const [addVisaDetail] = useAddVisaDetailMutation();
  const { data: allCategoriesDetail } = useGetVisaDetailAllQuery();
  const [form] = useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditId(null);
  };

  const getAllCountriesFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: 300,
        current: 0,
      };
      const response = await getAllCountries(reqData).unwrap();

      setCountryData(response?.data?.data);
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllCountries]);

  useEffect(() => {
    getAllCountriesFn();
  }, [getAllCountriesFn]);

  const getCountryName = (countryId) => {
    try {
      let countryName = "-";
      countryData?.forEach((item) => {
        if (item?.id === countryId) {
          countryName = item?.name;
        }
      });
      return countryName;
    } catch (error) {
      console.error(error);
    }
  };

  const addCategoryFn = async (values) => {
    try {
      const reqData = {
        id: editId,
        countryId: values?.countryId,
        visaCategories: [
          ...values?.visaCategories?.map((item, i) => ({
            serviceCharge: Number(item?.serviceCharge),
            visaFee: Number(item.visaFee),
            name: item?.name,
            description: item?.description,
            processDuration: item?.processDuration,
            validity: item?.validity,
            requiredDocument: item?.requiredDocument?.map((item) => {
              return item?.document;
            }),
            eligibilityCriteria: item?.eligibilityCriteria?.map((item) => {
              return item?.criteria;
            }),
          })),
        ],
      };
      await (editId ? editVisaDetail : addVisaDetail)(reqData).unwrap();
      message.success(
        editId ? "Category Updated Successfully" : "Category Added Successfully"
      );
      handleCancel();
      form.resetFields();
    } catch (error) {
      handleFieldError(error);
    }
  };

  const columns = [
    {
      title: "Country",
      dataIndex: "countryId",
      key: "name",
      align: "left",
      render: (countryId) => (
        <div className="name-container">{getCountryName(countryId)}</div>
      ),
    },
    {
      title: "Action",
      dataIndex: ["countryCode", "status", "id", "description", "name"],
      width: "10%",
      align: "right",
      render: (_, record) => {
        return (
          <div
            title="Edit status"
            className={`editLogo`}
            onClick={(e) => {
              e.stopPropagation();
              setEditId(record?.id);
              showModal();
            }}
          >
            <FaRegEdit fontSize={20} />
          </div>
        );
      },
    },
  ];

  const handleTableChange = (pagination) => {
    setTableParams({
      pagination,
    });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setCountryData([]);
    }
  };
  useEffect(() => {
    if (allCategoriesDetail) {
      const data = allCategoriesDetail?.data.map((item, index) => {
        return {
          ...item,
          key: index + 1,
        };
      });
      setNewCategoriesDetail(data);
    }
  }, [allCategoriesDetail]);
  return (
    <VisaCategoriesContainer>
      <div className="button-container">
        <StyledButton onClick={showModal}>Add Category</StyledButton>
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record?.key}
        dataSource={newCategoriesDetail}
        onChange={handleTableChange}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <ExpandableRow data={record?.visaCategories} />
          ),
          rowExpandable: (record) => true,
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <IoIosArrowUp
                onClick={(e) => onExpand(record, e)}
                className="expand-icon"
              />
            ) : (
              <IoIosArrowDown
                onClick={(e) => onExpand(record, e)}
                className="expand-icon"
              />
            ),
        }}
        expandRowByClick={false}
      />

      <AddVisaCategory
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        handleOk={addCategoryFn}
        editId={editId}
        newCategoriesDetail={newCategoriesDetail}
        form={form}
      />
    </VisaCategoriesContainer>
  );
};

const ExpandableRow = ({ data }) => {
  const columns = [
    {
      title: "Visa Category",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Fee",
      dataIndex: "visaFee",
      key: "visaFee",
    },
    {
      title: "Service Charge",
      dataIndex: "serviceCharge",
      align: "center",
      render: (id) => <div className="name-container">{id ?? "-"}</div>,
    },

    {
      title: "Required Documents",
      key: "requiredDocument",
      dataIndex: "requiredDocument",
      align: "center",
      render: (requiredDocument) => (
        <ul>
          {requiredDocument?.map((item, i) => {
            return <li>{item}</li>;
          })}
        </ul>
      ),
    },
    {
      title: "Eligibility Criteria",
      key: "eligibilityCriteria",
      dataIndex: "eligibilityCriteria",
      align: "center",
      render: (eligibilityCriteria) => (
        <ul>
          {eligibilityCriteria?.map((item, i) => {
            return <li>{item}</li>;
          })}
        </ul>
      ),
    },
  ];
  return (
    <ExpandableRowContainer key={data?.key}>
      <Table pagination={false} columns={columns} dataSource={data} />
    </ExpandableRowContainer>
  );
};
export default VisaCategories;
