import styled from "styled-components";
import { Button, Carousel } from "antd";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const CaraouselContainer = styled(Carousel)`
  height: 90vh;
  .slick-next {
    right: 1% !important;
  }
  .slick-prev {
    left: 1% !important;
  }
  &:hover {
    .slick-arrow {
      opacity: 1 !important;
    }
  }
  .slider-element-1 {
    background: url("../Images/slider-mainbg-001.jpg") center center/cover !important;
    height: 90vh;
  }
  .slider-element-2 {
    background: url("../Images/slider-mainbg-002.jpg") center center/cover !important;
    height: 90vh;
  }
  .content-container {
    width: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
    margin: 50px 0px 0px 150px;
    @media (${deviceQuery.laptopS}) {
      margin: 50px 0px 0px 10px;
    }
    .sub-heading,
    .heading {
      background-color: rgba(255, 255, 255, 0.88);
      text-transform: uppercase;
      z-index: 50;
      letter-spacing: 0px;
      border-color: rgb(137, 138, 156);
      border-style: none;
      color: ${(props) => props.theme.colors.darkBlue};
      white-space: nowrap;
      opacity: 1;
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      transform-origin: 50% 0% 0px;
      text-align: left;
      width: fit-content;
    }
    .sub-heading {
      font-size: 18px;
      line-height: 18px;
      padding: 11px 28px;
      font-weight: 400;
      &.text-animation {
        animation: zoomInOut 1s ease-in-out;
      }
      @media (${deviceQuery.tabletM}) {
        font-size: 16px;
        line-height: 16px;
      }
      @media (${deviceQuery.mobileMM}) {
        font-size: 14px;
        line-height: 14px;
      }
      @media (${deviceQuery.mobileS}) {
        font-size: 12px;
        line-height: 12px;
      }
    }
    .heading {
      font-size: 54px;
      line-height: 55px;
      font-weight: 300;
      padding: 14px 28px;
      .important {
        color: ${(props) => props.theme.colors.blue};
        font-weight: 600;
        line-height: 23px;
      }
      &.text-animation {
        animation: zoomInOutHeading 1.3s ease-in-out;
      }
      @media (${deviceQuery.tabletM}) {
        font-size: 32px;
        line-height: 35px;
      }
      @media (${deviceQuery.mobileMM}) {
        font-size: 22px;
        line-height: 25px;
      }
      @media (${deviceQuery.mobileS}) {
        font-size: 18px;
      }
    }
  }

  @keyframes zoomInOut {
    0% {
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      margin-bottom: 40px;
    }
    50% {
      transform: matrix3d(1.6, 0, 0, 0, 0, 1.6, 0, 0, 0, 0, 1.6, 0, 0, 0, 0, 1);
      margin-bottom: 20px;
    }
    100% {
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      margin-bottom: 0px;
    }
  }

  @keyframes zoomInOutHeading {
    0% {
      opacity: 0;
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
    50% {
      opacity: 0.6;
      transform: matrix3d(1.3, 0, 0, 0, 0, 1.3, 0, 0, 0, 0, 1.3, 0, 0, 0, 0, 1);
    }
    100% {
      opacity: 1;
      transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
    }
  }
  .button-animation {
    animation-name: pos;
    animation-duration: 1s;
    animation-timing-function: ease-in;
    transform-origin: 50% 50% 0px;
    @keyframes pos {
      0% {
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 50, 0, 0.9);
      }
      100% {
        transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
      }
    }
  }
`;

export const StyledArrowButton = styled(Button)`
  background-color: rgba(0, 0, 0, 0.5) !important;
  border-radius: 0px;
  color: ${(props) => props.theme.colors.white} !important;
  height: 40px !important;
  width: 40px !important;
  opacity: 0;
  z-index: 4;
  &:hover {
    background-color: ${(props) => props.theme.colors.black} !important;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.white};
  border-radius: 30px;
  color: ${(props) => props.theme.colors.darkBlue} !important;
  width: 200px;
  height: 52px;
  font-size: 15px;
  font-weight: 600;
  line-height: 25px;
  transition: 0.8s;
  margin-top: 20px;
  text-transform: capitalize;
  transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1);
  transform-origin: 50% 50% 0px;
  &:hover {
    color: ${(props) => props.theme.colors.white} !important;
    background-color: ${(props) => props.theme.colors.blue} !important;
  }
  @media (${deviceQuery.tabletM}) {
    width: 180px;
    height: 48px;
  }
  @media (${deviceQuery.mobileMM}) {
    width: 160px;
    height: 45px;
  }
  @media (${deviceQuery.mobileS}) {
    width: 140px;
    height: 40px;
  }
`;
