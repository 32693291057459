import React from "react";
import { NotFoundContainer } from "./NotFound.styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Constants/Routes";
import { StyledButton } from "../ApplicationForm/Components/OrderInformation/OrderInformation.styles";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    <NotFoundContainer>
      <div className="notfound-404">
        <h1>Oops!</h1>
      </div>
      <h2>404 - Page not found</h2>
      <p>
        The page you are looking for might have been removed had its name
        changed or is temporarily unavailable.
      </p>
      <StyledButton className="button" onClick={() => navigate(ROUTES.HOME)}>
        Go To Home
      </StyledButton>
    </NotFoundContainer>
  );
};

export default NotFound;
