import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const FeeCalculatorContainer = styled.div`
  background-color: ${(props) => props.theme.colors.lightBlue};
  padding: 20px;
  .heading {
    color: ${(props) => props.theme.colors.darkBlue};
    margin-bottom: 20px;
    font-size: 26px;
    line-height: 36px;
    margin-top: 0;
    @media (${deviceQuery.mobileSM}) {
      font-size: 22px;
      line-height: 30px;
    }
  }
  .content {
    margin: 0 0 15px;
    font-weight: 400;
    font-size: 15px;
    line-height: 25px;
    letter-spacing: 0px;
    color: ${(props) => props.theme.colors.gray};
    @media (${deviceQuery.mobileSM}) {
      font-size: 14px;
      line-height: 22px;
    }
  }
  .select-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(186px, 1fr));
    margin-top: 40px;
    gap: 10px;
    row-gap: 20px;
    .number-of-visa {
      display: flex;
      flex-direction: column;
      justify-self: center;
      .label {
        color: ${(props) => props.theme.colors.blue};
        font-size: 16px;
        font-weight: 500;
        margin-top: -10px;
        margin-bottom: 10px;
        @media (${deviceQuery.mobileSM}) {
          font-size: 14px;
        }
      }
    }
    .value-container {
      display: flex;
      align-items: center;
      gap: 10px;
      .value {
        color: ${(props) => props.theme.colors.gray};
        width: 45px;
        text-align: center;
      }
      .incr-button {
        border-radius: 50%;
        background-color: ${(props) => props.theme.colors.white};
        width: 25px;
        height: 25px;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (${deviceQuery.mobileSM}) {
          font-size: 18px;
        }
      }
      .active-incr {
        color: ${(props) => props.theme.colors.blue};
      }
    }
    .select {
      position: relative;
      width: 100%;
      max-width: 200px;
      height: 50px;
      border-radius: 0px;
      background-color: ${(props) => props.theme.colors.white};
    }
    .ant-select {
      width: 100%;
      justify-self: center;
    }
    .ant-select > .ant-select-selector {
      border-radius: 0px;
      height: 50px;
      color: rgba(0, 0, 0, 0.6);
      background: ${(props) => props.theme.colors.white};
    }
    .ant-select-selector > .ant-select-selection-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
      @media (${deviceQuery.mobileSM}) {
        font-size: 12px;
      }
    }

    .ant-select-selection-item {
      font-weight: 400;
      font-size: 14px;
      @media (${deviceQuery.mobileSM}) {
        font-size: 12px;
      }
    }
  }
  .fee-main-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0px;

    .fees-container {
      position: relative;
      font-size: 16px;
      width: 100%;
      display: flex;
      /* justify-content: space-between; */
      align-items: center;
      margin: 10px 0px;
      @media (${deviceQuery.mobileSM}) {
        font-size: 14px;
      }
      .fees-divider {
        width: 85%;
        display: flex;
        align-items: center;
        .fee-text {
          display: flex;
          align-items: center;
          min-width: 100px;
          width: 100%;
        }
        .divider {
          width: 100%;
          height: 1px;
          margin: 0px 10px;
          background-color: ${(props) => props.theme.colors.gray};
        }
      }
      .fee {
        min-width: 100px;
        font-size: 20px;
        font-weight: 600;
        @media (${deviceQuery.mobileSM}) {
          font-size: 18px;
        }
      }
      strike {
        color: ${(props) => props.theme.colors.red};
      }
    }

    .total-fees-container {
      display: flex;
      width: 100%;
      justify-content: end;
      color: ${(props) => props.theme.colors.blue};
      font-weight: 600;
      font-size: 20px;
      @media (${deviceQuery.mobileSM}) {
        font-size: 18px;
      }
    }
    .button {
      width: 45%;
      align-self: center;
    }
  }
`;
