import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const TravelInsuranceContainer = styled.div`
  display: flex;
  max-width: 1172px;
  margin: 40px auto;
  gap: 80px;
  @media (${deviceQuery.tabletM}) {
    flex-direction: column;
    align-items: center;
    margin: 40px 20px;
  }
  .card {
    background: ${(props) => props.theme.colors.white};
    box-shadow: ${(props) => props.theme.shadow.cardShadow};
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 45px;
    padding: 20px 30px;
    border-left: 2px solid ${(props) => props.theme.colors.blue};
    border-right: 2px solid ${(props) => props.theme.colors.blue};
    .previous-price {
      color: ${(props) => props.theme.colors.darkBlue2};
      font-size: 22px;
      line-height: 25px;
      margin-bottom: 8px;
      margin-top: 0;
      font-weight: 700;
      @media (${deviceQuery.mobileSM}) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    .new-price {
      color: ${(props) => props.theme.colors.darkBlue};
      font-size: 25px;
      line-height: 36px;
      margin-bottom: 8px;
      margin-top: 0;
      font-weight: 700;
      .price {
        font-size: 42px;
        font-weight: 900;
        color: ${(props) => props.theme.colors.blue};
        @media (${deviceQuery.mobileSM}) {
          font-size: 28px;
        }
      }
      @media (${deviceQuery.mobileSM}) {
        font-size: 20px;
        line-height: 30px;
      }
    }
    .text {
      width: 100%;
      color: ${(props) => props.theme.colors.gray};
      font-size: 18px;
      text-align: center;
      @media (${deviceQuery.mobileSM}) {
        font-size: 14px;
        line-height: 20px;
      }
    }
    &:hover {
      transform: translateY(-8px);
    }
  }
  .content {
    .sub-heading {
      text-transform: uppercase;
      color: ${(props) => props.theme.colors.blue};
      text-transform: uppercase;
      font-size: 12px;
      line-height: 13px;
      letter-spacing: 1px;
      font-weight: 500;
      margin-bottom: 5px;
      margin-top: 0;
    }
    .content-heading {
      color: ${(props) => props.theme.colors.darkBlue2};
      font-weight: 600;
      font-size: 38px;
      line-height: 46px;
      font-family: "Roboto", sans-serif;
      margin-bottom: 25px;
      margin-top: 0;
      .diff {
        color: ${(props) => props.theme.colors.blue};
      }
      @media (${deviceQuery.mobileSM}) {
        font-size: 28px;
        line-height: 35px;
      }
    }
    .list-item-container {
      display: flex;
      flex-direction: column;
      gap: 12px;
      .list-item {
        display: flex;
        gap: 15px;
        align-items: center;
        svg {
          height: 28px;
          width: 28px;
          color: ${(props) => props.theme.colors.blue};
        }
        .item-content {
          transition: all 0.3s ease 0s;
          font-size: 18px;
          line-height: 25px;
          letter-spacing: 0px;
          font-weight: 500;
          cursor: pointer;
          &:hover {
            color: ${(props) => props.theme.colors.blue};
          }
          @media (${deviceQuery.mobileSM}) {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
`;
