import React from "react";
import { ApplyingStepsContainer } from "./ApplyingSteps.styles";

const ApplyingSteps = ({ heading }) => {
  return (
    <ApplyingStepsContainer>
      <div className="heading-container">
        {/* <h5 className="sub-heading">Follow Our Instruction</h5> */}
        <h2 className="heading">{heading}</h2>
      </div>
      <img src="../Images/visa-steps.png" alt="steps" className="image" />
    </ApplyingStepsContainer>
  );
};

export default ApplyingSteps;
