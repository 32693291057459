import React, { useCallback, useEffect, useState } from "react";
import { FAQsAccordionContainer } from "./FAQsAccordion.styles";
import { Collapse } from "antd";
import { handleFieldError } from "../../../../Utils/Common";
import { useGetAllFaqMutation } from "../../../../Services/Users";

const FAQsAccordion = () => {
  const { Panel } = Collapse;
  const [openPanel, setOpenPanel] = useState(null);
  const [getAllFaq] = useGetAllFaqMutation();
  const [faqData, setFAQData] = useState();
  const onChange = (key) => {
    setOpenPanel(key.length > 0 ? key[0] : null);
  };

  const demoArray = [1, 2, 3, 4, 5, 6];

  const genExtra = (panelKey) => (
    <span>{openPanel === panelKey ? "-" : "+"}</span>
  );
  const getAllFAQFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: 500,
        current: 0,
      };
      const response = await getAllFaq(reqData).unwrap();
      const data = {
        data: response?.data?.data?.map((items, index) => {
          return { ...items, key: index + 1 };
        }),
        total: response?.data?.total,
      };
      setFAQData(data);
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllFaq]);

  useEffect(() => {
    getAllFAQFn();
  }, [getAllFAQFn]);
  return (
    <FAQsAccordionContainer>
      <h5 className="small-heading">WHY CHOOSE US</h5>
      <h2 className="container-heading">
        Do You Have
        <span className="diff"> Questions ?</span>
      </h2>
      <div className="collapse-container">
        <Collapse
          onChange={onChange}
          activeKey={openPanel ? [openPanel.toString()] : []}
          className="collapse"
          accordion
        >
          {faqData?.data?.map((item, i) => (
            <Panel
              showArrow={false}
              header={item?.question}
              key={item?.key}
              extra={genExtra(i.toString())}
            >
              <div>{item?.answer}</div>
            </Panel>
          ))}
        </Collapse>
      </div>
    </FAQsAccordionContainer>
  );
};

export default FAQsAccordion;
