import React from "react";
import { VisaProcessNoteContainer } from "./VisaProcessNote.styles";

const VisaProcessNote = () => {
  return (
    <VisaProcessNoteContainer>
      <h2 className="visa-process-note-heading">
        Notes When Applying For An <span className="diff">eVisa</span>
      </h2>
      <ul>
        <li>
          Every information you provide must be accurate in order not to be
          rejected.
        </li>
        <li>
          Once your eVisa fee has been paid, there is no refund if your
          application is refused.
        </li>
        <li>
          Incomplete applications will be recorded as invalid; however, your
          application will be saved in your account.
        </li>
        <li>
          Check and prepare additional documents before applying to make your
          application smoother.
        </li>
        <li>Check your email frequently so as not to miss any information.</li>
      </ul>
    </VisaProcessNoteContainer>
  );
};

export default VisaProcessNote;
