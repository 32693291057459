import React from "react";
import { ForgotPasswordContainer } from "./ForgotPassword.styles";
import { Divider, Form, Input, message } from "antd";
import { StyledButton } from "../ContactUs/Components/ContactUsForm/ContactUsForm.styles";
import { handleFieldError } from "../../Utils/Common";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Constants/Routes";
import { useSendOtpMutation } from "../../Services/Auth";

const ForgotPassword = () => {
  const [sendOtp, { isLoading }] = useSendOtpMutation();
  const navigate = useNavigate();
  const forgotPasswordFn = async (values) => {
    try {
      await sendOtp(values).unwrap();
      sessionStorage?.setItem("userName", values?.userName);
      navigate(ROUTES.VALIDATEOTP);
      message.success("Otp Sent Successfully");
    } catch (error) {
      handleFieldError(error);
    }
  };

  return (
    <ForgotPasswordContainer>
      <div className="main-container">
        <div className="right-container">
          <div className="card">
            <h3 className="heading">Forgot Password</h3>
            <div className="google-login-container"></div>
            <Divider />
            <Form className="form" onFinish={forgotPasswordFn}>
              <div className="input-container">
                <label>Email</label>
                <Form.Item
                  name="userName"
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "Please input your email!",
                    },
                    {
                      type: "email",
                      message: "Must be valid Email",
                    },
                  ]}
                >
                  <Input type="text" className="input" />
                </Form.Item>
              </div>
              <StyledButton htmlType="submit" loading={isLoading}>
                Generate Otp
              </StyledButton>
            </Form>
          </div>
        </div>
      </div>
    </ForgotPasswordContainer>
  );
};

export default ForgotPassword;
