import { Pagination, Select, Table, Tag, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import {
  useChangeVisaStatusMutation,
  useGetAllVisaMutation,
} from "../../../Services/Visa";
import { handleFieldError, textTransform } from "../../../Utils/Common";
import { FaRegEdit, FaSave } from "react-icons/fa";
import { MdOutlineCancelPresentation } from "react-icons/md";
import { ExpandableRowContainer } from "../Dashboard.styles";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useGetAllCountriesMutation } from "../../../Services/Users";

const Visa = () => {
  const [getAllCountries] = useGetAllCountriesMutation();
  const [getAllVisa] = useGetAllVisaMutation();
  const [userData, setUserData] = useState();
  const [paginate, setPaginate] = useState(false);
  const [status, setStatus] = useState(null);
  const [countryData, setCountryData] = useState();
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [changeVisaStatus] = useChangeVisaStatusMutation();
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.id === editingKey;

  const editStatus = (record) => {
    setEditingKey(record.id);
  };

  const getAllVisaFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: tableParams?.pagination?.pageSize,
        current: tableParams?.pagination?.current - 1,
      };
      const response = await getAllVisa(reqData).unwrap();
      const data = {
        data: response?.data?.data?.map((items, index) => {
          return { ...items, key: index + 1 };
        }),
        total: response?.data?.total,
      };
      setUserData(data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response?.data?.total,
        },
      });
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllVisa, paginate]);

  useEffect(() => {
    getAllVisaFn();
  }, [getAllVisaFn]);

  const getAllCountriesFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: 300,
        current: 0,
      };
      const response = await getAllCountries(reqData).unwrap();

      setCountryData(response?.data?.data);
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllCountries]);

  useEffect(() => {
    getAllCountriesFn();
  }, [getAllCountriesFn]);

  const getCountryName = (id) => {
    try {
      const countryId = Number(id);
      let countryName = "-";
      countryData?.forEach((item) => {
        if (item?.id === countryId) {
          countryName = item?.name;
        }
      });
      return countryName;
    } catch (error) {
      console.error(error);
    }
  };

  const save = async (record) => {
    try {
      const reqData = {
        visaId: record?.id,
        status: status,
      };
      await changeVisaStatus(reqData).unwrap();
      message.success("Status updated successfully");
      setEditingKey("");
    } catch (error) {
      handleFieldError(error);
      setEditingKey("");
    }
  };
  const columns = [
    {
      title: "Name",
      dataIndex: ["firstName", "lastName"],
      render: (text, record) => (
        <div className="name-container">
          {record?.firstName ? record?.firstName + " " + record?.lastName : "-"}
        </div>
      ),
      align: "center",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (country) => <div>{getCountryName(country) ?? "-"}</div>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (email) => <div>{email ?? "-"}</div>,
    },
    {
      title: "Mobile No.",
      dataIndex: "mobileNo",
      key: "mobileNo",
      align: "center",
      render: (mobileNo) => <div>{mobileNo ?? "-"}</div>,
    },
    {
      title: "No. Of Travellers",
      dataIndex: "travellers",
      render: (travellers) => (
        <div className="name-container">{travellers?.length ?? "0"}</div>
      ),
      key: "mobileNo",
      align: "center",
    },
    // {
    //   title: "Status",
    //   key: "status",
    //   dataIndex: ["status", "id"],
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return (
    //       <div
    //         className={
    //           record?.status === "APPROVED"
    //             ? "green"
    //             : record?.status === "DISAPPROVED"
    //             ? "red"
    //             : "blue"
    //         }
    //       >
    //         {editable ? (
    //           <Select
    //             placeholder="Select Status"
    //             className="input status-select"
    //             options={[
    //               { label: "Approve", value: "APPROVED" },
    //               { label: "Reject", value: "DISAPPROVED" },
    //               { label: "In Process", value: "UNDER_REVIEW" },
    //               { label: "Resolved", value: "RESOLVED" },
    //             ]}
    //             onChange={(e) => setStatus(e)}
    //             defaultValue={record?.status ?? "UNDER_REVIEW"}
    //           />
    //         ) : (
    //           <Tag color="green">Success</Tag>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   title: "Action",
    //   dataIndex: ["action", "status", "id", "amount", "createdByUserId"],
    //   width: "10%",
    //   align: "right",
    //   render: (_, record) => {
    //     const editable = isEditing(record);
    //     return editable ? (
    //       <div
    //         className="actionContainer2"
    //         onClick={(e) => e.stopPropagation()}
    //       >
    //         <div
    //           title="save"
    //           onClick={() => save(record)}
    //           style={{
    //             marginRight: 8,
    //           }}
    //         >
    //           <FaSave fontSize={20} />
    //         </div>
    //         <div
    //           title="cancel"
    //           onClick={() => {
    //             setEditingKey("");
    //           }}
    //         >
    //           <MdOutlineCancelPresentation fontSize={20} />
    //         </div>
    //       </div>
    //     ) : (
    //       <div
    //         title="Edit status"
    //         className={`editLogo`}
    //         onClick={(e) => {
    //           e.stopPropagation();
    //           editStatus(record);
    //         }}
    //       >
    //         <FaRegEdit fontSize={20} />
    //       </div>
    //     );
    //   },
    // },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
    });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setUserData([]);
    }
  };

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record?.key}
        dataSource={userData?.data}
        onChange={handleTableChange}
        pagination={false}
        expandable={{
          expandedRowRender: (record) => (
            <ExpandableRow
              data={record}
              changeVisaStatus={changeVisaStatus}
              applicationId={record?.id}
              getAllVisaFn={getAllVisaFn}
            />
          ),
          rowExpandable: (record) => record?.travellers?.length > 0,
          expandIcon: ({ expanded, onExpand, record }) =>
            record?.travellers?.length > 0 &&
            (expanded ? (
              <IoIosArrowUp
                onClick={(e) => onExpand(record, e)}
                className="expand-icon"
              />
            ) : (
              <IoIosArrowDown
                onClick={(e) => onExpand(record, e)}
                className="expand-icon"
              />
            )),
        }}
        expandRowByClick={false}
      />
      {userData?.total > 10 && (
        <Pagination
          total={userData?.total}
          showSizeChanger
          showTotal={(total) => `Total ${total} items`}
          current={tableParams?.pagination?.current}
          pageSize={tableParams?.pagination?.pageSize}
          onChange={(page, pageSize) => {
            setPaginate(!paginate);
            setTableParams({
              pagination: {
                current: page,
                pageSize: pageSize,
              },
            });
          }}
        />
      )}
    </>
  );
};

const ExpandableRow = ({
  data,
  changeVisaStatus,
  applicationId,
  getAllVisaFn,
}) => {
  const [editingKey, setEditingKey] = useState("");
  const [status, setStatus] = useState(null);
  const isEditing = (record) => record.id === editingKey;
  const save = async (record) => {
    try {
      const reqData = {
        passportNumber: record?.passportNumber,
        visaId: applicationId,
        status: status,
      };
      await changeVisaStatus(reqData).unwrap();
      message.success("Status updated successfully");
      setEditingKey("");
      getAllVisaFn();
    } catch (error) {
      handleFieldError(error);
      setEditingKey("");
    }
  };
  const editStatus = (record) => {
    setEditingKey(record.id);
  };
  const columns = [
    {
      title: "Surname",
      dataIndex: "surName",
      key: "surName",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Given Name",
      dataIndex: "givenName",
      key: "givenName",
    },
    {
      title: "DOB",
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
    },
    {
      title: "Passport No.",
      dataIndex: "passportNumber",
      key: "passportNumber",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: ["status", "id"],
      render: (_, record) => {
        const editable = isEditing(record);
        return (
          <div
            className={
              record?.status === "APPROVED"
                ? "green"
                : record?.status === "DISAPPROVED"
                ? "red"
                : "blue"
            }
          >
            {editable ? (
              <Select
                placeholder="Select Status"
                className="input status-select"
                options={[
                  { label: "Approve", value: "APPROVED" },
                  { label: "Reject", value: "DISAPPROVED" },
                  { label: "In Process", value: "UNDER_REVIEW" },
                  { label: "Resolved", value: "RESOLVED" },
                ]}
                onChange={(e) => setStatus(e)}
                defaultValue={record?.status ?? "UNDER_REVIEW"}
              />
            ) : (
              <Tag
                color={
                  record?.status === "APPROVED"
                    ? "green"
                    : record?.status === "UNDER_REVIEW"
                    ? "yellow"
                    : record?.status === "DISAPPROVED"
                    ? "red"
                    : "blue"
                }
              >
                {record?.status ? textTransform(record?.status) : "-"}
              </Tag>
            )}
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: [
        "action",
        "status",
        "id",
        "amount",
        "createdByUserId",
        "passportNumber",
      ],
      width: "10%",
      align: "right",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <div
            className="actionContainer2"
            onClick={(e) => e.stopPropagation()}
          >
            <div
              title="save"
              onClick={() => save(record)}
              style={{
                marginRight: 8,
              }}
            >
              <FaSave fontSize={20} />
            </div>
            <div
              title="cancel"
              onClick={() => {
                setEditingKey("");
              }}
            >
              <MdOutlineCancelPresentation fontSize={20} />
            </div>
          </div>
        ) : (
          <div
            title="Edit status"
            className={`editLogo`}
            onClick={(e) => {
              e.stopPropagation();
              editStatus(record);
            }}
          >
            <FaRegEdit fontSize={20} />
          </div>
        );
      },
    },
  ];
  return (
    <ExpandableRowContainer key={data?.key}>
      <Table
        pagination={false}
        columns={columns}
        dataSource={data?.travellers}
      />
    </ExpandableRowContainer>
  );
};
export default Visa;
