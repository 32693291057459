import React, { useCallback, useEffect, useState } from "react";
import { ApplicationFormContainer } from "./ApplicationForm.styles";
import OrderInformation from "./Components/OrderInformation/OrderInformation";
import { useForm } from "antd/es/form/Form";
import { StyledInput } from "../../Utils/Global.styles";
import { Button } from "antd";
import { handleFieldError, scrollToTop } from "../../Utils/Common";
import moment from "moment";
import { useGetAllCountriesMutation } from "../../Services/Users";
import { ROUTES } from "../../Constants/Routes";
import { StyledButton } from "./Components/OrderInformation/OrderInformation.styles";
import { useNavigate } from "react-router-dom";

const ApplicationForm = () => {
  const [form] = useForm();
  const [destination, setDestination] = useState();
  const [fee, setFee] = useState(null);
  const [noOfTraveller, setNoOfTraveller] = useState(1);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [daysDifference, setDaysDifference] = useState(0);
  const [countryData, setCountryData] = useState();
  const [getAllCountries] = useGetAllCountriesMutation();
  const [step2, setStep2] = useState(false);
  const navigate = useNavigate();
  const getAllCountriesFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: 300,
        current: 0,
      };
      const response = await getAllCountries(reqData).unwrap();
      setCountryData(response?.data?.data);
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllCountries]);

  const getCountryName = (countryId) => {
    try {
      let countryName = "-";
      countryData?.forEach((item) => {
        if (item?.id === countryId) {
          countryName = item?.name;
        }
      });
      return countryName;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllCountriesFn();
  }, [getAllCountriesFn]);

  useEffect(() => {
    scrollToTop(0);
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const newStartDate = moment(startDate, "MMM DD, YYYY", true);
      const newEndDate = moment(endDate, "MMM DD, YYYY", true);

      if (newStartDate.isValid() && newEndDate.isValid()) {
        const difference = newEndDate.diff(newStartDate, "days");
        setDaysDifference(difference);
      } else {
        setDaysDifference(0);
      }
    } else {
      setDaysDifference(0);
    }
  }, [startDate, endDate]);

  return (
    <ApplicationFormContainer>
      <div className="heading">
        <span className="important">eVisa</span> Application Form
      </div>

      {!step2 ? (
        <div className="main-container">
          <div className="left-container">
            <OrderInformation
              form={form}
              setDestination={setDestination}
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              daysDifference={daysDifference}
              setFee={setFee}
              setNoOfTraveller={setNoOfTraveller}
              noOfTraveller={noOfTraveller}
              setStep2={setStep2}
            />
          </div>
          <div className="right-container">
            <div className="summary-card-container">
              <h3 className="card-heading">Order summary</h3>
              <div className="visa-details-container sub-container">
                <div className="same-line-content">
                  <div className="details-container">
                    <h5 className="details-heading">Traveling To</h5>
                    <p className="details">
                      {destination ? getCountryName(destination) : "-"}
                    </p>
                  </div>
                  <div className="details-container">
                    <h5 className="details-heading end-text">Staying Time</h5>
                    <p className="details">
                      <span>
                        {startDate ?? "-"} - {endDate ?? "-"}
                      </span>
                      <span className="end-text">
                        {" "}
                        {startDate && `(${daysDifference} days)`}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="details-container">
                  <h5 className="details-heading">Type of Visa</h5>
                  <p className="details">
                    Tourist eVisa (Single entry for 30 days)
                  </p>
                </div>
              </div>
              <div className="visa-fee-container sub-container">
                <h5 className="details-heading">Visa Service Fee</h5>
                <div className="details">
                  <p className="text">Visa Fee </p>
                  <p className="text">
                    {fee && fee?.visaFee
                      ? `$ ${fee?.visaFee * noOfTraveller}`
                      : "-"}
                  </p>
                </div>
                <div className="details">
                  <p className="text">
                    Normal (Guaranteed within 3 working days)
                  </p>
                  <p className="text">$0.00</p>
                </div>
              </div>
              <div className="total-fee-container sub-container">
                <div className="details">
                  <p className="text">Total Service Fee </p>
                  <p className="text">
                    {fee && (fee?.serviceCharge || fee.serviceCharge === 0)
                      ? `$ ${fee?.serviceCharge}`
                      : "-"}
                  </p>
                </div>
              </div>
              <div className="visa-fee-container sub-container">
                <h5 className="details-heading">Government & Admin Fee</h5>
                <div className="details">
                  <p className="text">Traveler Nationality </p>
                  <p className="text">$0.00</p>
                </div>
              </div>
              <div className="grand-total-container">
                <h5>Total</h5>
                <h5>
                  {fee &&
                  ((fee.visaFee && fee.serviceCharge) ||
                    fee.serviceCharge === 0)
                    ? `$ ${fee.visaFee * noOfTraveller + fee.serviceCharge}`
                    : "-"}
                </h5>
              </div>
              <div className="promo-code-container">
                <StyledInput
                  placeholder="Have a promotion code?"
                  className="input"
                />
                <Button className="promo-btn">Apply</Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="main-container">
          <div className="message message-animate">
            <div className="text">
              Your request has been submitted.
              <br /> Our team will get back to you soon!!
            </div>
            <StyledButton
              onClick={() => navigate(ROUTES.HOME)}
              className="check-button"
            >
              Go to Home page
            </StyledButton>
          </div>
        </div>
      )}
    </ApplicationFormContainer>
  );
};

export default ApplicationForm;
