import React, { useEffect } from "react";
import { ContactUsContainer } from "./ContactUs.styles";
import ContactUsForm from "./Components/ContactUsForm/ContactUsForm";
import { scrollToTop } from "../../Utils/Common";
import { LuMail } from "react-icons/lu";
import { TfiAlarmClock } from "react-icons/tfi";
import { FaLocationDot } from "react-icons/fa6";

const ContactUs = () => {
  useEffect(() => {
    scrollToTop(0);
  }, []);
  return (
    <ContactUsContainer>
      <ContactUsForm />

      <div className="contact-us-container">
        <div className="contact-card mail-container">
          <div className="icon">
            <LuMail />{" "}
          </div>
          <div className="content">
            <h3 className="heading">Email Address</h3>
            <h5 className="contact mail">info@globalcitizenportal.com</h5>
          </div>
        </div>

        <div className="contact-card location-container">
          <div className="icon">
            <FaLocationDot />
          </div>
          <div className="content">
            <h3 className="heading">Our Address</h3>
            <h5 className="contact location">Dubai</h5>
          </div>
        </div>
        <div className="contact-card timing-container">
          <div className="icon">
            {" "}
            <TfiAlarmClock />
          </div>
          <div className="content">
            <h3 className="heading">Open Hours</h3>
            <h5 className="contact phone">Mon To Sat: 8:00 am to 6:00 pm</h5>
            <div className="contact">Sunday Closed.</div>
          </div>
        </div>
      </div>
    </ContactUsContainer>
  );
};

export default ContactUs;
