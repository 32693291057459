import { styled } from "styled-components";
import { deviceQuery } from "../../Utils/MediaSizes";

export const TransactionConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 90vh;
  gap: 25px;
  text-align: center;
  padding: 20px;
  .heading {
    font-weight: 800;
    font-size: 40px;
    color: ${(props) => props.theme.colors.darkBlue};
    @media (${deviceQuery.mobileM}) {
      font-weight: 600;
      font-size: 24px;
    }
  }
  .id {
    font-size: 22px;
    font-weight: 500;
    margin: 0 2rem;
    display: flex;
    word-break: break-all;
    color: ${(props) => props.theme.colors.gray};
    .val {
      color: ${(props) => props.theme.colors.darkBlue};
    }
    @media (${deviceQuery.tabletS}) {
      font-size: 18px;
    }
    @media (${deviceQuery.mobileM}) {
      font-size: 16px;
      flex-direction: column;
    }
  }
  .timer {
    font-size: 24px;
    color: ${(props) => props.theme.colors.blue};
    @media (${deviceQuery.mobileM}) {
      font-size: 20px;
    }
  }
  .button {
    max-width: 400px;
  }
`;
