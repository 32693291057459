import { Button } from "antd";
import styled from "styled-components";
import { deviceQuery } from "../../../Utils/MediaSizes";

export const WidgetHeaderContainer = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  padding: 10px 20px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray2};
  position: ${(props) => props.scroll > 65 && "fixed"};
  top: 0;
  left: 0;
  right: 0;
  z-index: 112;
  @media (${deviceQuery.laptopM}) {
    display: none;
  }
  .main-widget-container {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    width: 1170px;
    .divider {
      height: 60px;
      border: 1px solid ${(props) => props.theme.colors.gray2};
    }
    .cont {
      display: flex;
      gap: 20px;
      align-items: center;
    }
    .logo-container {
      width: 250px;
      height: 45px;
      .logo {
        width: 100%;
        height: 100%;
      }
    }
    .text {
      color: ${(props) => props.theme.colors.gray};
      min-width: 450px;
      text-align: start;
      @media (${deviceQuery.laptopL}) {
        min-width: 300px;
      }
    }
    .social-container {
      display: flex;
      gap: 15px;
      .social-icons {
        display: flex;
        gap: 12px;
        align-items: center;

        svg {
          fill: ${(props) => props.theme.colors.darkBlue};
          line-height: 1px;
        }
        .google {
          height: 22px;
          width: 22px;
        }
      }
      .ant-btn:last-child {
        color: ${(props) => props.theme.colors.white} !important;
        background-color: ${(props) => props.theme.colors.darkBlue} !important;
        &:hover {
          background-color: ${(props) =>
            props.theme.colors.grayBackground} !important;
          border: 1px solid ${(props) => props.theme.colors.buttonBorderColor} !important;
          color: ${(props) => props.theme.colors.darkBlue} !important;
        }
      }
    }
    .phone {
      svg {
        fill: ${(props) => props.theme.colors.blue};
      }
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      color: #1d2143;
      font-size: 18px;
      line-height: 28px;
    }
    .shine {
      position: relative;
      overflow: hidden;

      &::before {
        background: linear-gradient(
          to right,
          fade_out(#fff, 1) 0%,
          fade_out(#fff, 0.7) 100%
        );
        transform: skewX(-25deg);
        width: 50%;
        z-index: 2;
      }

      &:hover,
      &:focus {
        &::before {
          animation: shine 0.85s;
        }
      }

      @keyframes shine {
        100% {
          left: 125%;
        }
      }
    }
    .shine::before {
      content: "";
      position: absolute;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100%;
      background: linear-gradient(90deg, transparent, #fff, transparent);
      transition: 0.5s;
    }
    .shine:hover::before {
      left: 100%;
    }
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.grayBackground};
  border: 1px solid ${(props) => props.theme.colors.buttonBorderColor};
  border-radius: 30px;
  color: ${(props) => props.theme.colors.darkBlue};
  width: 154px;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  transition: 0.4s;

  &:hover {
    color: ${(props) => props.theme.colors.white} !important;
    background-color: ${(props) => props.theme.colors.darkBlue} !important;
  }
`;
