import React from "react";
import { Line } from "react-chartjs-2";

export const BalanceChart = () => {
  const options = {
    maintainAspectRatio: window?.innerWidth > 767,
    responsive: true,
    aspectRatio: 3,
    plugins: {
      legend: {
        position: "top",
      },
    },
    animation: {
      duration: 0,
    },
    layout: {
      padding: {
        top: 10,
        right: 10,
        bottom: 10,
        left: 10,
      },
    },
  };
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "No. of Visas",
        data: [33, 53, 85, 41, 44, 65],
        fill: true,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
      {
        label: "No. of Applications",
        data: [33, 25, 35, 51, 54, 76],
        fill: false,
        borderColor: "#0067ed",
      },
    ],
  };

  return (
    <div className="graph-canva-container">
      <Line data={data} options={options} />
    </div>
  );
};
