import React from "react";
import { CountryDetailsHeaderContainer } from "./CountryDetailsHeader.styles";
import { Breadcrumb } from "antd";
import { ROUTES } from "../../../../Constants/Routes";

const CountryDetailsHeader = ({ country }) => {
  const breadCrumbItems = [
    {
      title: "Home",
      href: `${ROUTES.HOME}`,
    },
    {
      title: "India",
    },
  ];
  return (
    <CountryDetailsHeaderContainer>
      <div className="content-section">
        <h2 className="heading">{country}</h2>
        {/* <div className="sub-heading">Founded In 2019 London,UK</div> */}
        {/* <Breadcrumb items={breadCrumbItems} /> */}
      </div>
    </CountryDetailsHeaderContainer>
  );
};

export default CountryDetailsHeader;
