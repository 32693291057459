import { Button } from "antd";
import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const CheckStatusFormContainer = styled.div`
  padding: 30px 20px;
  height: 100%;
  @media (${deviceQuery.laptopS}) {
    padding: 20px;
  }
  .form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;

    .ant-form-item {
      margin: 0px;
      width: 100%;
      height: 72px;
    }
    .heading {
      font-weight: 600;
      text-align: center;
      font-size: 24px;
      line-height: 24px;
      color: ${(props) => props.theme.colors.darkBlue};
      text-transform: capitalize;
      margin-bottom: 15px;
    }
    .input {
      height: 50px;
      border-radius: 0px;
      background-color: ${(props) => props.theme.colors.white};
    }
    .note {
      color: rgba(0, 0, 0, 0.6);
      border-color: rgba(255, 255, 255, 0.06);
      margin-left: 20px;
    }
    .ant-input {
      &::placeholder {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.6);
        border-color: rgba(255, 255, 255, 0.06);
      }
    }

    .select {
      position: relative;
      width: 100%;
    }
    .ant-select {
      width: 100%;
    }
    .phone-country-select {
      display: flex;
      align-items: start;
      width: 100%;
      .country-select {
        width: 35%;
        .ant-form-item {
          .ant-form-item-explain > .ant-form-item-explain-error {
            margin-top: 10px !important;
            padding: 2px !important;
            font-size: 13px !important;
          }
        }
      }
    }
    .ant-select > .ant-select-selector {
      border-radius: 0px;
      height: 50px;
      color: rgba(0, 0, 0, 0.6);
      background: ${(props) => props.theme.colors.white};
    }
    .ant-select-selector > .ant-select-selection-placeholder {
      font-weight: 400;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.6);
    }

    .ant-select-selection-item {
      font-weight: 400;
      font-size: 14px;
    }
    .ant-picker {
      width: 100%;
      border-radius: 30px;
      background-color: rgba(0, 0, 0, 0.6);
      border-color: rgba(255, 255, 255, 0.06) !important;
      .ant-picker-input {
        padding-left: 26px;
        &::placeholder {
          font-size: 14px;
          font-weight: 400;
          color: ${(props) => props.theme.colors.gray40};
        }
      }
      .ant-picker-suffix {
        position: absolute;
        left: -5px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
  .input-select-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    justify-content: center;
    row-gap: 4px;
    @media (${deviceQuery.laptopS}) {
      width: 100%;
    }
    @media (${deviceQuery.mobileL}) {
      grid-template-columns: 1fr;
    }
  }
  .your-request {
    min-height: 162px;
    grid-column: span 2;
    @media (${deviceQuery.mobileL}) {
      grid-column: span 1;
    }
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: center;
    }
  }
  .text-area {
    border-radius: 0px;
    /* border: none; */
    background-color: ${(props) => props.theme.colors.white};
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.grayBackground};
  border: 1px solid ${(props) => props.theme.colors.darkBlue};
  border-radius: 0px;
  color: ${(props) => props.theme.colors.darkBlue};
  width: 100%;
  height: 50px;
  transition: all 0.5s ease 0s;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
  max-width: 450px;
  span {
    font-size: 16px;
    font-weight: 500;
    font-family: "Roboto", sans-serif;
  }
  &:hover {
    color: ${(props) => props.theme.colors.white} !important;
    background-color: ${(props) => props.theme.colors.darkBlue} !important;
  }
`;
