import React, { useCallback, useEffect, useState } from "react";
import { Step1Container } from "./Step1.styles";
import { DatePicker, Form, Radio, Select } from "antd";
import { StyledInput } from "../../../../../Utils/Global.styles";
import {
  handleFieldError,
  restrictedKeys,
  scrollToTop,
} from "../../../../../Utils/Common";
import { useForm } from "antd/es/form/Form";
import CountrySelect from "../../../../../Common/Components/CountrySelect/CountrySelect";
import moment from "moment";
import en from "react-phone-number-input/locale/en.json";
import { StyledButton } from "../OrderInformation.styles";
import { useGetAllCountriesMutation } from "../../../../../Services/Users";
import { useGetCountryVisaDetailsQuery } from "../../../../../Services/Visa";
import _ from "underscore";

const Step1 = ({
  setStep1,
  setDestination,
  setStartDate,
  setEndDate,
  daysDifference,
  applyVisa,
  isLoading,
  setVisaId,
  setTravelCountryId,
  setFee,
}) => {
  const [form] = useForm();
  const { RangePicker } = DatePicker;
  const [countries, setCountries] = useState([]);
  const [value, setValue] = useState("normal");
  const [getAllCountries] = useGetAllCountriesMutation();
  const [visaCategories, setVisaCategories] = useState();
  const [selectedVisaCategory, setSelectedVisaCategory] = useState();
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const { data: visaDetails } = useGetCountryVisaDetailsQuery(
    selectedCountryId,
    {
      skip: selectedCountryId !== null ? false : true,
    }
  );

  useEffect(() => {
    if (visaDetails?.data?.visaCategories?.length > 0) {
      setVisaCategories(
        visaDetails?.data?.visaCategories?.map((item, i) => {
          return {
            key: i,
            label: item?.name,
            value: item?.id,
          };
        })
      );
    }
  }, [visaDetails, selectedCountryId]);
  const onFinish = async (value) => {
    try {
      const [startDate, endDate] = value.stayingTime.map((date) =>
        moment(date).format("YYYY-MM-DD")
      );
      const reqData = {
        ...value,
        country: value?.countryCode,
        startDate,
        endDate,
      };
      delete reqData.stayingTime;
      const response = await applyVisa(reqData).unwrap();
      setTravelCountryId(value?.travelCountryId);
      setVisaId(response?.data?.id);
      setStep1(true);
      scrollToTop(0);
    } catch (error) {
      handleFieldError(error);
    }
  };
  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 15) {
      form.setFieldValue("mobileNo", inputValue);
    } else {
      const truncatedInput = inputValue.slice(0, 15);
      form.setFieldValue("mobileNo", truncatedInput);
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().endOf("day");
  };

  // useEffect(() => {
  //   setCountries(
  //     Country.getAllCountries()?.map((item, i) => {
  //       return {
  //         key: i,
  //         label: item?.name,
  //         value: item?.name,
  //         isoCode: item?.isoCode,
  //       };
  //     })
  //   );
  // }, []);

  const getAllCountriesFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: 300,
        current: 0,
      };
      const response = await getAllCountries(reqData).unwrap();
      const countriesData = response?.data?.data || [];
      const sortedCountries = _.sortBy(countriesData, (item) => item.name);
      const countriesList = sortedCountries.map((item, i) => ({
        key: i,
        label: item.name,
        value: item.id,
      }));
      setCountries(countriesList);
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllCountries]);

  useEffect(() => {
    getAllCountriesFn();
  }, [getAllCountriesFn]);

  useEffect(() => {
    if (daysDifference > 0) {
      if (daysDifference > 2) {
        setValue("normal");
      } else if (daysDifference > 1) {
        setValue("urgent");
      } else {
        setValue("super urgent");
      }
    }
  }, [daysDifference]);

  useEffect(() => {
    visaDetails?.data?.visaCategories?.forEach((item) => {
      if (selectedVisaCategory === item?.name) {
        setFee(item);
      }
    });
  }, [selectedVisaCategory, setFee, visaDetails?.data?.visaCategories]);

  return (
    <Step1Container>
      <Form form={form} onFinish={onFinish} className="form">
        <div className="input-select-container">
          <Form.Item name="travelingCountryId" style={{ width: "100%" }}>
            <Select
              showSearch
              type="text"
              placeholder="Travelling To"
              className="input"
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              options={countries}
              onChange={(e) => {
                setDestination(e);
                setSelectedCountryId(e);
              }}
            />
          </Form.Item>
          <Form.Item name="stayingTime" style={{ width: "100%" }}>
            <RangePicker
              className="input"
              disabledDate={disabledDate}
              onChange={(dates) => {
                if (dates && dates.length === 2) {
                  setStartDate(moment(dates[0]?.$d).format("MMM DD, YYYY"));
                  setEndDate(moment(dates[1]?.$d).format("MMM DD, YYYY"));
                } else {
                  setStartDate(null);
                  setEndDate(null);
                }
              }}
            />
          </Form.Item>
          <Form.Item
            name="processingTime"
            style={{ width: "100%" }}
            className="processing-container"
          >
            <label className="label-heading">Processing Time</label>
            <Radio.Group onChange={onChange} value={value}>
              <Radio name="time" value="normal">
                Normal (Guranteed within 3 working days)
              </Radio>
              <Radio name="time" value="urgent">
                Urgent (Guranteed within 2 working days)
              </Radio>
              <Radio name="time" value="super urgent">
                {" "}
                Super Urgent (After 1 working day)
              </Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name="lastName"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "",
              },
              {
                max: 50,
                message: "Maximum 50 characters Allowed",
              },
              {
                pattern: new RegExp(/^[A-Za-z\s]+$/),
                message: "Must be a valid name",
              },
            ]}
          >
            <StyledInput type="text" className="input" placeholder="Surname" />
          </Form.Item>
          <Form.Item
            name="firstName"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "",
              },
              {
                max: 50,
                message: "Maximum 50 characters Allowed",
              },
              {
                pattern: new RegExp(/^[A-Za-z\s]+$/),
                message: "Must be a valid name",
              },
            ]}
          >
            <StyledInput
              type="text"
              className="input"
              placeholder="Given Name"
            />
          </Form.Item>
          <Form.Item name="visaCategoryId" style={{ width: "100%" }}>
            <Select
              type="text"
              placeholder="Type of Visa"
              className="select input"
              options={visaCategories}
              onChange={(e) => {
                visaCategories?.forEach((item) => {
                  if (item?.value === e) {
                    setSelectedVisaCategory(item?.label);
                  }
                });
              }}
            />
          </Form.Item>
          <Form.Item
            name="email"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "",
              },
              {
                type: "email",
                message: "Must be valid Email",
              },
            ]}
          >
            <StyledInput
              type="text"
              placeholder="Email Address *"
              className="input"
            />
          </Form.Item>

          <Form.Item name="communicationChannel" style={{ width: "100%" }}>
            <Select
              type="text"
              placeholder="Communication Channel"
              className="select input"
              options={[{ label: "WhatsApp", value: "WHATSAPP" }]}
            />
          </Form.Item>
          <div className="phone-country-select">
            <div className="country-select">
              <CountrySelect size="large" labels={en} isRequired={true} />
            </div>
            <Form.Item
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  min: 6,
                  message: "Minimum 6 digits required",
                },
                {
                  max: 15,
                  message: "Phone Number Can't be more than 15 digits",
                },
              ]}
              name="mobileNo"
            >
              <StyledInput
                type="number"
                placeholder="Mobile Number *"
                className="input"
                onKeyDown={(e) => {
                  if (restrictedKeys.includes(e?.key)) {
                    e.preventDefault();
                    return;
                  }
                }}
                value={form.getFieldValue("mobileNo")}
                onChange={handlePhoneInputChange}
                min={0}
              />
            </Form.Item>
          </div>
        </div>
        <StyledButton className="button" htmlType="submit" loading={isLoading}>
          Continue!
        </StyledButton>
      </Form>
    </Step1Container>
  );
};

export default Step1;
