import React from "react";
import { AboutCompanyContainer } from "./AboutCompany.styles";
import { CiPlay1 } from "react-icons/ci";

const AboutCompany = () => {
  return (
    <AboutCompanyContainer>
      <div className="main-container">
        <div className="image-container">
          <img
            src="../Images/single-img-01.jpg"
            alt="about"
            className="image image-1"
          />
          <img
            src="../Images/single-img-02.jpg"
            alt="about"
            className="image image-2"
          />
          <img
            src="../Images/single-img-03.jpg"
            alt="about"
            className="image image-3"
          />
          <div className="video-button">
            <CiPlay1 />
            Working Since 2019
          </div>
        </div>
        <div className="content-container">
          <div className="heading-section">
            <div className="heading">
              <h5> About Company </h5>
            </div>
            <div className="sub-heading">
              Immigration Services From{" "}
              <strong className="experienced-agent">Experienced Agents.</strong>
            </div>
          </div>
          <div className="content">
            <p>
              Global Citizen Portal, A team of dedicated professionals with 5+
              years of experience. Currently our focus is on Visa Assistance. We
              are excited to deliver visas on e-commerce platforms: Global
              Citizen Portal
            </p>
            <p>
              We are one of the fastest emerging Visa Assistance rendering our
              services worldwide through our market-place. We are a bunch of
              passionate professionals having expertise in respected fields.
            </p>
            <p>
              Foundation was established with a small idea that was incepted in
              the minds of its promoters in the year 2019! We skillfully guide
              applicants for their immigration process to any country they
              aspire to settle.
            </p>
          </div>
          <div className="sub-content"></div>
        </div>
      </div>
    </AboutCompanyContainer>
  );
};

export default AboutCompany;
