import { createSlice } from "@reduxjs/toolkit";
import { rtkBaseQuery } from "../Utils/BaseQuery";
import { createApi } from "@reduxjs/toolkit/query/react";

export const globalSlice = createSlice({
  name: "global",
  initialState: {
    user: {},
    token: "",
    isLogin: false,
  },
  reducers: {
    setToken: (state, action) => {
      return {
        ...state,
        token: action.payload,
      };
    },
    setUser: (state, action) => {
      return {
        ...state,
        user: action.payload.user,
      };
    },
    setIsLogin: (state, action) => {
      return {
        ...state,
        isLogin: action.payload,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { setToken, setUser, setIsLogin } = globalSlice.actions;
export default globalSlice.reducer;

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: rtkBaseQuery,
  endpoints: (builder) => ({
    loginApi: builder.mutation({
      query: (data) => {
        return { url: `oauth/authenticate`, body: data, method: "POST" };
      },
    }),
    register: builder.mutation({
      query: (data) => {
        return { url: `oauth/user/register`, body: data, method: "POST" };
      },
    }),
    sendOtp: builder.mutation({
      query: (data) => {
        return { url: `oauth/sendOtp`, body: data, method: "POST" };
      },
    }),
    validateOtp: builder.mutation({
      query: (data) => {
        return { url: `oauth/validateOtp`, body: data, method: "POST" };
      },
    }),
    resetPassword: builder.mutation({
      query: (data) => {
        return { url: `oauth/resetPassword`, body: data, method: "POST" };
      },
    }),
    logout: builder.mutation({
      query: (data) => {
        return { url: `oauth/logout`, body: data, method: "POST" };
      },
    }),
  }),
});

export const {
  useLoginApiMutation,
  useRegisterMutation,
  useSendOtpMutation,
  useValidateOtpMutation,
  useResetPasswordMutation,
  useLogoutMutation,
} = authApi;
