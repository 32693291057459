import React from "react";
import { CheckStatusHeaderContainer } from "./CheckStatusHeader.styles";
import { Breadcrumb } from "antd";
import { ROUTES } from "../../../../Constants/Routes";

const CheckStatusHeader = () => {
  const breadCrumbItems = [
    {
      title: "Home",
      href: `${ROUTES.HOME}`,
    },
    {
      title: "Check Status",
    },
  ];
  return (
    <CheckStatusHeaderContainer>
      <div className="main-visa-status-container">
        <h2>Check Your Visa Status</h2>
        {/* <Breadcrumb items={breadCrumbItems} /> */}
      </div>
    </CheckStatusHeaderContainer>
  );
};

export default CheckStatusHeader;
