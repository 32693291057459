import React from "react";
import { VisaProcessHeaderContainer } from "./VisaProcessHeader.styles";
import { ROUTES } from "../../../../Constants/Routes";
import { Breadcrumb } from "antd";

const VisaProcessHeader = () => {
  const breadCrumbItems = [
    {
      title: "Home",
      href: `${ROUTES.HOME}`,
    },
    {
      title: "Visa process",
    },
  ];
  return (
    <VisaProcessHeaderContainer>
      <div className="main-visa-process-container">
        <h2 className="heading">Visa Process</h2>
        {/* <Breadcrumb items={breadCrumbItems} /> */}
      </div>
    </VisaProcessHeaderContainer>
  );
};

export default VisaProcessHeader;
