import { Button } from "antd";
import styled from "styled-components";
import { deviceQuery } from "../../../../Utils/MediaSizes";

export const CommonVisaContainer = styled.div`
  background: url("../Images/row-bgimage-1.png") center center/cover;
  background-color: ${(props) => props.theme.colors.lightBlue};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .common-visa-heading {
    text-align: center;
    color: ${(props) => props.theme.colors.darkBlue2};
    font-weight: 600;
    font-size: 35px;
    line-height: 46px;
    margin-bottom: 0px;
    .diff {
      color: ${(props) => props.theme.colors.blue};
    }
    @media (${deviceQuery.mobileSM}) {
      font-size: 28px;
      line-height: 35px;
    }
  }
  .slick-slider {
    max-width: 1170px;
    margin: auto;
    @media (${deviceQuery.laptopML}) {
      max-width: 100vw;
    }
  }
  .card-container {
    padding: 40px;
    display: grid !important;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;
    width: fit-content !important;
    margin: auto;
    @media (${deviceQuery.laptopML}) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (${deviceQuery.tabletM}) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
  .button-container {
    padding-bottom: 60px;
  }
`;

export const StyledButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.grayBackground};
  border: 1px solid ${(props) => props.theme.colors.buttonBorderColor};
  border-radius: 30px;
  color: ${(props) => props.theme.colors.darkBlue};
  width: 195px;
  height: 46px;
  font-size: 14px;
  font-weight: 500;
  transition: 0.4s;
  box-shadow: ${(props) => props.theme.shadow.cardShadow2};
  &:hover {
    color: ${(props) => props.theme.colors.white} !important;
    background-color: ${(props) => props.theme.colors.darkBlue} !important;
  }
`;
