import React from "react";
import { ExploreBannerContainer, StyledButton } from "./ExploreBanner.styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../Constants/Routes";

const ExploreBanner = () => {
  const navigate = useNavigate();
  return (
    <ExploreBannerContainer>
      <div className="heading">Explore The World Right Away</div>
      <div className="content">
        Within a few days, we can help travelers prepare necessary documents and
        obtain an e-visa without going anywhere.
      </div>
      <StyledButton onClick={() => navigate(ROUTES.APPLICATIONFORM)}>
        Apply Visa
      </StyledButton>
    </ExploreBannerContainer>
  );
};

export default ExploreBanner;
