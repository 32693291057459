import React, { useEffect, useState } from "react";
import { TransactionConfirmContainer } from "./TransactionConfirm.styles";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../Constants/Routes";
import { useTransactionSuccessMutation } from "../../Services/Visa";
import { StyledButton } from "../ApplicationForm/Components/OrderInformation/OrderInformation.styles";

const TransactionConfirm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(5);
  const [transactionSuccess] = useTransactionSuccessMutation();
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds]);

  setTimeout(() => {
    navigate(ROUTES.HOME);
  }, 5000);

  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  const transactionSuccessFn = async () => {
    try {
      const response = await transactionSuccess(id).unwrap();
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    transactionSuccessFn();
  }, []);
  return (
    <TransactionConfirmContainer>
      <div className="heading">Your Transaction has been confirmed!</div>
      <div className="id">
        <div>Transaction Id:</div> <div className="val"> {id}</div>
      </div>
      <div className="timer">
        Redirecting to Home in: {minutes}:
        {remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}
      </div>
      <StyledButton
        onClick={() => navigate(ROUTES.HOME)}
        className="button"
        disabled={seconds > 0}
      >
        Go to Home
      </StyledButton>
    </TransactionConfirmContainer>
  );
};

export default TransactionConfirm;
