import { fetchBaseQuery } from "@reduxjs/toolkit/dist/query";
import { config } from "./Config";

export const rtkBaseQuery = fetchBaseQuery({
  baseUrl: config.BASE_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState().commonRed.token;
    if (token) {
      headers.set("Authorization", token);
    }
    return headers;
  },
});
