import React, { useEffect, useState } from "react";
import { StyledOtpInput, VerifyOtpContainer } from "./VerifyOtp.styles";
import { Button, Divider, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Constants/Routes";
import { handleFieldError } from "../../Utils/Common";
import { StyledButton } from "../ContactUs/Components/ContactUsForm/ContactUsForm.styles";
import {
  useSendOtpMutation,
  useValidateOtpMutation,
} from "../../Services/Auth";

const VerifyOTP = () => {
  const [otp, setOtp] = useState();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const navigate = useNavigate();
  const [validateOtp, { isLoading }] = useValidateOtpMutation();
  const [sendOtp, { isLoading: otpLoading }] = useSendOtpMutation();
  const userName = sessionStorage.getItem("userName");
  const validateOtpFn = async () => {
    try {
      const data = {
        otp: otp,
        userName: userName,
      };
      sessionStorage.setItem("otp", otp);
      await validateOtp(data).unwrap();
      navigate(ROUTES.RESETPASSWORD);
      message.success("OTP Verfied Successfully");
    } catch (error) {
      handleFieldError(error);
    }
  };
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const resendOtpFn = async () => {
    try {
      const data = {
        userName: userName,
      };
      sendOtp(data).unwrap();
      message.success("OTP resent successfully");
    } catch (error) {
      handleFieldError(error);
    }
  };

  return (
    <VerifyOtpContainer>
      <div className="main-container">
        <div className="right-container">
          <div className="card">
            <h3 className="heading">Validate OTP</h3>
            {/* <h5 className="sub-heading"></h5> */}
            <div className="google-login-container"></div>
            <Divider />
            <div className="otpContainer">
              <StyledOtpInput
                value={otp}
                containerStyle={{
                  gap: "30px",
                }}
                inputStyle={{
                  // color: "white",
                  background: "#0D1122",
                  border: "none",
                  borderBottom: "1px solid #8A8AA0",
                  borderRadius: 0,
                  width: "20%",
                  backgroundColor: "Transparent",
                }}
                onChange={setOtp}
                numInputs={6}
                inputType="number"
                renderInput={(props) => (
                  <Input type="number" {...props} className="otp-input" />
                )}
              />
              <div className="resend-text">
                {seconds > 0 ? (
                  <>
                    <span className="resend"> Resend OTP in:</span>
                    <span className="timer">
                      {minutes && minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </span>
                  </>
                ) : (
                  <Button
                    className="send-otp"
                    onClick={resendOtpFn}
                    loading={otpLoading}
                  >
                    Resend OTP
                  </Button>
                )}
              </div>
            </div>
            <StyledButton
              className="button"
              onClick={() => validateOtpFn()}
              loading={isLoading}
            >
              Validate Otp
            </StyledButton>
          </div>
        </div>
      </div>
    </VerifyOtpContainer>
  );
};

export default VerifyOTP;
