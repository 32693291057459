import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { StyledButton } from "../../../Screens/ApplicationForm/Components/OrderInformation/OrderInformation.styles";
import { useForm } from "antd/es/form/Form";
import { AddFAQsModal } from "./AddFAQs.styles";
import { useGetFaqQuery } from "../../../Services/Users";

const AddFAQs = ({ handleOk, isModalOpen, handleCancel, editId,form }) => {
  const handleModalCancel = () => {
    handleCancel();
    form.resetFields();
  };
  const { data: visaCategoryDetails } = useGetFaqQuery(editId, {
    skip: editId !== null ? false : true,
  });
  useEffect(() => {
    if (visaCategoryDetails && editId) {
      form.setFieldsValue({
        question: visaCategoryDetails?.data?.question,
        answer: visaCategoryDetails?.data?.answer,
      });
    }
  }, [visaCategoryDetails, form, editId]);

  return (
    <AddFAQsModal
      onCancel={handleModalCancel}
      open={isModalOpen}
      footer={null}
      destroyOnClose={true}
      width={460}
    >
      <div className="heading">{editId ? `Update` : "Add"} FAQ</div>
      <Form className="main-container" onFinish={handleOk} form={form}>
        <Form.Item
          name="question"
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Input placeholder="Question" className="input" />
        </Form.Item>
        <Form.Item
          name="answer"
          style={{ width: "100%" }}
          rules={[
            {
              required: true,
              message: "",
            },
          ]}
        >
          <Input placeholder="Answer" className="input" />
        </Form.Item>
        <StyledButton className="button" htmlType="submit">
          {editId ? "Update" : "Add"}
        </StyledButton>
      </Form>
    </AddFAQsModal>
  );
};
export default AddFAQs;
