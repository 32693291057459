import React, { useEffect, useState } from "react";
import { HeaderContainer } from "./Header.styles";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Constants/Routes";
import { Input } from "antd";
import { FaSearch } from "react-icons/fa";
import { MdKeyboardArrowDown, MdKeyboardArrowRight } from "react-icons/md";

const Header = () => {
  const [inputValue, setInputValue] = useState(false);
  const [activeElement, setActiveElement] = useState(null);
  const [subMenu, setSubMenu] = useState(false);
  const [scroll, setScroll] = useState(window.scrollY);
  const handleScroll = () => setScroll(window.scrollY);
  const params = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    setInputValue(false);
    if (params.pathname.includes("visa-services")) {
      setActiveElement("Visa Services");
    } else if (params.pathname.includes("faq")) {
      setActiveElement("FAQ");
    } else if (params.pathname.includes("about-us")) {
      setActiveElement("About Us");
    } else if (params.pathname.includes("contact-us")) {
      setActiveElement("Contact Us");
    } else {
      setActiveElement("Home");
    }
  }, [params.pathname]);

  const handleElementClick = (element) => {
    setActiveElement(element);
    setInputValue(false);
  };
  return (
    <HeaderContainer scroll={scroll}>
      <div className="main-container">
        <div className="header-element-container">
          <div
            className={`header-element ${
              activeElement === "Home" && "active-element"
            }`}
            onClick={() => {
              navigate(ROUTES.HOME);
            }}
          >
            Home
          </div>
          <div className="sub-menu-main-container">
            <div
              className={`header-element ${
                activeElement === "Visa Services" && "active-element"
              }`}
            >
              Visa Services
            </div>
            <div className="sub-menu-container">
              <div
                className="sub-menu-item"
                onClick={() => {
                  navigate(ROUTES.ALLVISA);
                }}
              >
                Visa Information
              </div>
              <div
                className="sub-menu-item"
                onClick={() => {
                  navigate(ROUTES.CHECKSTATUS);
                }}
              >
                Check Visa Status
              </div>
              <div
                className="sub-menu-item"
                onClick={() => {
                  navigate(ROUTES.VISAPROCESS);
                }}
              >
                Visa Process
              </div>
            </div>
          </div>
          <div
            className={`header-element ${
              activeElement === "About Us" && "active-element"
            }`}
            onClick={() => {
              handleElementClick("About Us");
              navigate(ROUTES.ABOUTUS);
            }}
          >
            About Us
          </div>
          <div
            className={`header-element ${
              activeElement === "FAQ" && "active-element"
            }`}
            onClick={() => {
              navigate(ROUTES.FAQ);
            }}
          >
            FAQ
          </div>
          <div
            className={`header-element ${
              activeElement === "Contact Us" && "active-element"
            }`}
            onClick={() => navigate(ROUTES.CONTACTUS)}
          >
            Contact Us
          </div>
        </div>
        <div className="search-container">
          <Input
            placeholder="Enter Keyword"
            suffix={<FaSearch />}
            className="input"
          />
        </div>
        <div className="hamburger-menu-container">
          <div className="logo-container" onClick={() => navigate(ROUTES.HOME)}>
            <img
              src="/Images/Global_Citizen_Portal_Logo.png"
              alt="logo"
              className="logo"
            />
          </div>
          <div className="menu--right">
            <div className="menuToggle">
              <input
                type="checkbox"
                onChange={(e) => {
                  setInputValue(e?.target?.checked);
                  setSubMenu(false);
                }}
                checked={inputValue}
              />
              <span></span>
              <span></span>
              <span></span>
              <ul className="menuItem">
                <li
                  className={`${activeElement === "Home" && "active-element"}`}
                  onClick={() => navigate(ROUTES.HOME)}
                >
                  Home
                </li>

                <li
                  className={`${
                    activeElement === "Visa Services" && "active-element"
                  }`}
                >
                  <span
                    className="menu-item-content"
                    onClick={() => setSubMenu(!subMenu)}
                  >
                    Visa Services
                    {subMenu ? (
                      <MdKeyboardArrowDown />
                    ) : (
                      <MdKeyboardArrowRight />
                    )}
                  </span>
                  <div
                    className={`sub-menu-item-content ${
                      subMenu && "sub-menu-open"
                    }`}
                  >
                    <div
                      className="sub-menu-item"
                      onClick={() => {
                        navigate(ROUTES.ALLVISA);
                      }}
                    >
                      Visa Information
                    </div>
                    <div
                      className="sub-menu-item"
                      onClick={() => {
                        navigate(ROUTES.CHECKSTATUS);
                      }}
                    >
                      Check Visa Status
                    </div>
                    <div
                      className="sub-menu-item"
                      onClick={() => {
                        navigate(ROUTES.VISAPROCESS);
                      }}
                    >
                      Visa Process
                    </div>
                  </div>
                </li>
                <li
                  className={`${
                    activeElement === "About Us" && "active-element"
                  }`}
                  onClick={() => navigate(ROUTES.ABOUTUS)}
                >
                  About Us
                </li>
                <li
                  className={`${activeElement === "FAQ" && "active-element"}`}
                  onClick={() => navigate(ROUTES.FAQ)}
                >
                  FAQ
                </li>
                <li
                  className={`${
                    activeElement === "Contact Us" && "active-element"
                  }`}
                  onClick={() => navigate(ROUTES.CONTACTUS)}
                >
                  Contact Us
                </li>
                <li
                  className={`${activeElement === "Login" && "active-element"}`}
                  onClick={() => navigate(ROUTES.LOGIN)}
                >
                  Login
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </HeaderContainer>
  );
};

export default Header;
