import React from "react";
import { PortalFooterContainer } from "./PortalFooter.styles";

const PortalFooter = () => {
  return (
    <PortalFooterContainer>
      <div className="content">V.0.0.1</div>
    </PortalFooterContainer>
  );
};

export default PortalFooter;
