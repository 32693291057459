import styled from "styled-components";

export const VisaProcessContainer = styled.div`
  .visa-process-note-main-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px;
  }
`;
