import React, { useEffect, useState } from "react";
import { ProfileContainer } from "./Profile.styles";
import { Form, Input, Select, message } from "antd";
import { handleFieldError, restrictedKeys } from "../../../../Utils/Common";
import CountrySelect from "../../../../Common/Components/CountrySelect/CountrySelect";
import { StyledInput } from "../../../../Utils/Global.styles";
import { useForm } from "antd/es/form/Form";
import { Country } from "country-state-city";
import en from "react-phone-number-input/locale/en.json";
import { StyledButton } from "../../../ContactUs/Components/ContactUsForm/ContactUsForm.styles";
import { useEditUserMutation } from "../../../../Services/Users";

const Profile = ({ userData }) => {
  const [form] = useForm();
  const [countries, setCountries] = useState([]);
  const [editUser] = useEditUserMutation();
  const editProfileFn = async (values) => {
    try {
      const reqData = {
        ...values,
        id: userData?.id,
      };
      await editUser(reqData).unwrap();
      message.success("User Updated Successfully");
    } catch (error) {
      handleFieldError(error);
    }
  };
  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= 15) {
      form.setFieldValue("mobileNo", inputValue);
    } else {
      const truncatedInput = inputValue.slice(0, 15);
      form.setFieldValue("mobileNo", truncatedInput);
    }
  };

  useEffect(() => {
    setCountries(
      Country.getAllCountries()?.map((item, i) => {
        return {
          key: i,
          label: item?.name,
          value: item?.name,
          isoCode: item?.isoCode,
        };
      })
    );
  }, []);

  useEffect(() => {
    if (userData) {
      form.setFieldsValue({
        firstName: userData?.firstName,
        lastName: userData?.lastName,
        email: userData?.email,
        address: userData?.address,
        country: userData?.country,
        countryCode: userData?.countryCode,
        mobileNo: userData?.mobileNo,
      });
    }
  }, [userData]);

  return (
    <ProfileContainer>
      <h3 className="profile-heading">Edit Profile</h3>
      <Form className="form" onFinish={editProfileFn} form={form}>
        <div className="input-select-container">
          <div className="input-container">
            <label>First Name</label>
            <Form.Item
              name="firstName"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input type="text" className="input" />
            </Form.Item>
          </div>
          <div className="input-container">
            <label>Last Name</label>
            <Form.Item
              name="lastName"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input type="text" className="input" />
            </Form.Item>
          </div>
          <div className="input-container">
            <label>Email</label>
            <Form.Item
              name="email"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  type: "email",
                  message: "Must be valid Email",
                },
              ]}
            >
              <Input type="text" className="input" />
            </Form.Item>
          </div>

          <div className="input-container">
            <label>Country</label>
            <Form.Item name="country">
              <Select
                showSearch
                className="input"
                filterOption={(input, option) =>
                  (option?.value ?? "")
                    .toLowerCase()
                    .includes(input.toLowerCase())
                }
                options={countries}
              />
            </Form.Item>
          </div>

          <div className="input-container">
            <label>Address</label>
            <Form.Item
              name="address"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "",
                },
              ]}
            >
              <Input type="text" className="input" />
            </Form.Item>
          </div>

          <div className="input-container">
            <label>Mobile Number</label>
            <div className="phone-country-select">
              <div className="country-select">
                <CountrySelect size="large" labels={en} isRequired={true} />
              </div>
              <Form.Item
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    min: 6,
                    message: "Minimum 6 digits required",
                  },
                  {
                    max: 15,
                    message: "Phone Number Can't be more than 15 digits",
                  },
                ]}
                name="mobileNo"
              >
                <StyledInput
                  type="number"
                  placeholder="Mobile Number *"
                  className="input"
                  // prefix={<MdCall />}
                  onKeyDown={(e) => {
                    if (restrictedKeys.includes(e?.key)) {
                      e.preventDefault();
                      return;
                    }
                  }}
                  value={form.getFieldValue("mobileNo")}
                  onChange={handlePhoneInputChange}
                  min={0}
                />
              </Form.Item>
            </div>
          </div>
        </div>
        <StyledButton htmlType="submit" loading={false}>
          Update
        </StyledButton>
      </Form>
    </ProfileContainer>
  );
};

export default Profile;
