import { Pagination, Table } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useGetAllUsersMutation } from "../../../Services/Users";
import { handleFieldError } from "../../../Utils/Common";

const Users = () => {
  const [getAllUsers] = useGetAllUsersMutation();
  const [userData, setUserData] = useState();
  const [paginate, setPaginate] = useState(false);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const getAllUsersFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: tableParams?.pagination?.pageSize,
        current: tableParams?.pagination?.current - 1,
      };
      const response = await getAllUsers(reqData).unwrap();
      setUserData(response?.data);
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
          total: response?.data?.total,
        },
      });
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllUsers, paginate]);

  useEffect(() => {
    getAllUsersFn();
  }, [getAllUsersFn]);

  const columns = [
    {
      title: "Name",
      dataIndex: ["firstName", "lastName"],
      render: (text, record) => (
        <div className="name-container">
          {record?.firstName ? record?.firstName + " " + record?.lastName : "-"}
        </div>
      ),
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
      align: "center",
      render: (country) => (
        <div className="name-container">{country ?? "-"}</div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
    },
    {
      title: "Mobile No.",
      dataIndex: "mobileNo",
      key: "mobileNo",
      align: "center",
    },
    {
      title: "DOB",
      dataIndex: "dateOfBerth",
      key: "dateOfBerth",
      align: "center",
      render: (dob) => <div className="name-container">{dob ?? "-"}</div>,
    },
  ];

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
    });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setUserData([]);
    }
  };

  return (
    <>
      <Table
        columns={columns}
        rowKey={(record) => record?.login?.uuid}
        dataSource={userData?.data}
        onChange={handleTableChange}
        pagination={false}
      />
      {userData?.total > 10 && (
        <Pagination
          total={userData?.total}
          showSizeChanger
          showTotal={(total) => `Total ${total} items`}
          current={tableParams?.pagination?.current}
          pageSize={tableParams?.pagination?.pageSize}
          onChange={(page, pageSize) => {
            setPaginate(!paginate);
            setTableParams({
              pagination: {
                current: page,
                pageSize: pageSize,
              },
            });
          }}
        />
      )}
    </>
  );
};

export default Users;
