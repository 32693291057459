import React from "react";
import {
  CheckStatusFormContainer,
  StyledButton,
} from "./CheckStatusForm.styles";
import { Form, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import { StyledInput } from "../../../../Utils/Global.styles";
import en from "react-phone-number-input/locale/en.json";
import TextArea from "antd/es/input/TextArea";
import CountrySelect from "../../../../Common/Components/CountrySelect/CountrySelect";
import { useGetVisaStatusByMobileNoMutation } from "../../../../Services/Visa";
import { handleFieldError } from "../../../../Utils/Common";

const CheckStatusForm = ({ setStatus, setResponseData }) => {
  const [form] = useForm();
  const [getVisaStatusByMobileNo, { isLoading }] =
    useGetVisaStatusByMobileNoMutation();
  const onFinish = async (values) => {
    try {
      const response = await getVisaStatusByMobileNo(values).unwrap();
      setResponseData(response);
      setStatus(true);
    } catch (error) {
      handleFieldError(error);
    }
  };

  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 15) {
      form.setFieldValue("mobileNo", inputValue);
    } else {
      const truncatedInput = inputValue.slice(0, 15);
      form.setFieldValue("mobileNo", truncatedInput);
    }
  };

  const restrictedKeys = ["+", "-", "e", "."];

  return (
    <CheckStatusFormContainer>
      <Form
        className="form active"
        onFinish={onFinish}
        form={form}
        validateTrigger={"onBlur"}
      >
        {/* <h3 className="heading">Check Status Online Form</h3> */}
        <div className="input-select-container">
          <Form.Item
            name="name"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "",
              },
              {
                max: 50,
                message: "Maximum 50 characters Allowed",
              },
              {
                pattern: new RegExp(/^[A-Za-z\s]+$/),
                message: "Must be a valid name",
              },
            ]}
          >
            <StyledInput
              type="text"
              placeholder="Your Name *"
              className="input"
              maxLength={50}
              // prefix={<UserOutlined />}
            />
          </Form.Item>
          <Form.Item
            name="email"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "",
              },
              {
                type: "email",
                message: "Must be valid Email",
              },
            ]}
          >
            <StyledInput
              type="text"
              placeholder="Email Address *"
              className="input"
              // prefix={<MdEmail />}
            />
          </Form.Item>
          <Form.Item
            name="passportNumber"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "",
              },
              {
                max: 50,
                message: "Maximum 50 characters Allowed",
              },
            ]}
          >
            <StyledInput
              type="text"
              placeholder="Passport Number *"
              className="input"
              maxLength={50}
              // prefix={<FaPassport />}
            />
          </Form.Item>{" "}
          <Form.Item
            name="orderId"
            style={{ width: "100%" }}
            rules={[
              {
                max: 50,
                message: "Maximum 50 characters Allowed",
              },
            ]}
          >
            <StyledInput
              type="text"
              placeholder="Order Id/ Payment Id"
              className="input"
              maxLength={50}
              // prefix={<MdOutlinePayment />}
            />
          </Form.Item>
          <Form.Item name="communicationChannel" style={{ width: "100%" }}>
            <Select
              type="text"
              placeholder="Communication Channel"
              className="select input"
              options={[{ label: "WhatsApp", value: "WHATSAPP" }]}
            />
          </Form.Item>
          <div className="phone-country-select">
            <div className="country-select">
              <CountrySelect size="large" labels={en} isRequired={true} />
            </div>
            <Form.Item
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: "",
                },
                {
                  min: 6,
                  message: "Minimum 6 digits required",
                },
                {
                  max: 15,
                  message: "Phone Number Can't be more than 15 digits",
                },
              ]}
              name="mobileNo"
            >
              <StyledInput
                type="number"
                placeholder="Mobile Number *"
                className="input"
                // prefix={<MdCall />}
                onKeyDown={(e) => {
                  if (restrictedKeys.includes(e?.key)) {
                    e.preventDefault();
                    return;
                  }
                }}
                value={form.getFieldValue("mobileNo")}
                onChange={handlePhoneInputChange}
                min={0}
              />
            </Form.Item>
          </div>
          <Form.Item
            name="yourRequest"
            style={{ width: "100%" }}
            rules={[
              {
                required: true,
                message: "",
              },
              {
                max: 10000,
                message: "Maximum 10000 characters Allowed",
              },
            ]}
            className="your-request"
          >
            <TextArea
              placeholder="Your Request *"
              className="text-area"
              rows={6}
              maxLength={10000}
            />
          </Form.Item>
        </div>
        <StyledButton className="button" htmlType="submit" loading={isLoading}>
          Submit Request!
        </StyledButton>
      </Form>
    </CheckStatusFormContainer>
  );
};

export default CheckStatusForm;
