import styled from "styled-components";

export const PortalFooterContainer = styled.div`
  height: 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #002140;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    color: ${(props) => props.theme.colors.white};
  }
`;
