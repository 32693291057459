import React from "react";
import { FooterContainer } from "./Footer.styles";
import { FaLocationDot, FaXTwitter } from "react-icons/fa6";
import { FaFacebookF, FaLinkedinIn, FaPhoneAlt } from "react-icons/fa";
import { LuMail } from "react-icons/lu";
import { TiSocialGooglePlus } from "react-icons/ti";
import { Tooltip } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../Constants/Routes";

const Footer = () => {
  const navigate = useNavigate();
  return (
    <FooterContainer>
      <div className="main-container">
        <div className="list-container">
          <div className="about-us-container">
            <div className="heading-container">
              <h3 className="list-heading">About Us</h3>
              <div className="underline" />
            </div>
            <div className="list-item-container">
              <div className="list-item-content">
                Since 2019, we have been the leading provider of visa and
                immigration consulting services in major cities and abroad,
                offering reliable client support.
              </div>
              {/* <div className="list-item">
                <div className="item-dash" />
                <div className="name">Payment Guidelines</div>
              </div>
              <div className="list-item">
                <div className="item-dash" />
                <div className="name">Disclaimer</div>
              </div> */}
              <div className="divider" />
              <div className="social-list-container">
                <Tooltip title="Facebook">
                  <div className="social-item">
                    <FaFacebookF />
                  </div>
                </Tooltip>
                <Tooltip title="Twitter">
                  <div className="social-item">
                    <FaXTwitter />
                  </div>
                </Tooltip>
                <Tooltip title="Google +">
                  <div className="social-item">
                    <TiSocialGooglePlus className="google" />
                  </div>
                </Tooltip>
                <Tooltip title="Linkedin">
                  <div className="social-item">
                    <FaLinkedinIn />
                  </div>
                </Tooltip>
              </div>
            </div>
          </div>
          <div className="right-links-container">
            <div className="visa-services-container">
              <div className="heading-container">
                <h3 className="list-heading">Visa Services</h3>
                <div className="underline" />
              </div>
              <div className="list-item-container">
                <div className="list-item">
                  <div className="item-dash" />
                  <div
                    className="name"
                    onClick={() => navigate(ROUTES.ALLVISA)}
                  >
                    Visa Information
                  </div>
                </div>
                {/* <div className="list-item">
                  <div className="item-dash" />
                  <div className="name">Check Visa Eligibility</div>
                </div> */}
                <div className="list-item">
                  <div className="item-dash" />
                  <div
                    className="name"
                    onClick={() => navigate(ROUTES.CHECKSTATUS)}
                  >
                    Check Visa Status
                  </div>
                </div>
                <div className="list-item">
                  <div className="item-dash" />
                  <div
                    className="name"
                    onClick={() => navigate(ROUTES.VISAPROCESS)}
                  >
                    Visa Process
                  </div>
                </div>
              </div>
            </div>

            <div className="useful-links-container">
              <div className="heading-container">
                <h3 className="list-heading">Useful Links</h3>
                <div className="underline" />
              </div>
              <div className="list-item-container">
                {/* <div className="list-item">
                  <div className="item-dash" />
                  <div className="name">Payment Guidelines</div>
                </div>
                <div className="list-item">
                  <div className="item-dash" />
                  <div className="name">Disclaimer</div>
                </div> */}
                <div className="list-item">
                  <div className="item-dash" />
                  <div className="name" onClick={() => navigate(ROUTES.FAQ)}>
                    FAQ
                  </div>
                </div>
              </div>
            </div>
            <div className="contact-container">
              <div className="heading-container">
                <h3 className="list-heading">Contact Us</h3>
                <div className="underline" />
              </div>
              <div className="list-item-container">
                <div className="list-item contact-list">
                  <FaLocationDot /> Dubai
                </div>
                {/* <div className="list-item contact-list">
                  <FaPhoneAlt />
                  +44 7961773985
                </div> */}
                <div className="list-item contact-list">
                  <LuMail />
                  info@globalcitizenportal.com
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="contact-us-container">
          <div className="contact-card mail-container">
            <div className="icon">
              <LuMail />{" "}
            </div>
            <div className="content">
              <h5 className="contact mail">info@globalcitizenportal.com</h5>
              <div className="text">Drop us a line</div>
            </div>
          </div>
          <div className="contact-card phone-container">
            <div className="icon">
              {" "}
              <FaPhoneAlt />
            </div>
            <div className="content">
              <h5 className="contact phone">+44 1800 1234 2431</h5>
              <div className="text">Call us Now!</div>
            </div>
          </div>
          <div className="contact-card location-container">
            <div className="icon">
              <FaLocationDot />
            </div>
            <div className="content">
              <h5 className="contact location">24, Street, London, UK</h5>
              <div className="text">Get Direction</div>
            </div>
          </div>
        </div> */}
        <div className="bottom-footer-container">
          <div className="pages-container">
            <div className="page" onClick={() => navigate(ROUTES.HOME)}>
              Home
            </div>
            <div className="page-divider" />
            <div className="page" onClick={() => navigate(ROUTES.ABOUTUS)}>
              About Us
            </div>
            <div className="page-divider" />
            <div className="page" onClick={() => navigate(ROUTES.CONTACTUS)}>
              Contact Us
            </div>
          </div>
          <div className="copyright-container">
            Copyright © 2024{" "}
            <span className="important"> Global Citizen Portal</span>. All
            rights reserved.
          </div>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
