import React, { useEffect, useState } from "react";
import { LandingPageContainer, StyledButton } from "./LandingPage.styles";
import { Form, message, Select } from "antd";
import { StyledInput } from "../../Utils/Global.styles";
import CountrySelect from "../../Common/Components/CountrySelect/CountrySelect";
import en from "react-phone-number-input/locale/en.json";
import { handleFieldError, restrictedKeys } from "../../Utils/Common";
import { useForm } from "antd/es/form/Form";
import { FaLongArrowAltRight } from "react-icons/fa";
import { FaGlobeEurope } from "react-icons/fa";
import { FaArrowsAltH } from "react-icons/fa";
import { AiOutlineSafety } from "react-icons/ai";
import emailjs from "@emailjs/browser";
import { Plane } from "../../Assets/Logos/Plane";
import TagManager from "react-gtm-module";
import useGeoLocation from "react-ipgeolocation";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../Constants/Routes";

const LandingPage = () => {
  const [form] = useForm();
  const location = useGeoLocation();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  console.log(location, "check location");
  console.log(location.country);
  const handlePhoneInputChange = (e) => {
    const inputValue = e.target.value;

    if (inputValue.length <= 15) {
      form.setFieldValue("mobileNo", inputValue);
    } else {
      const truncatedInput = inputValue.slice(0, 15);
      form.setFieldValue("mobileNo", truncatedInput);
    }
  };

  const onFinish = async (userData) => {
    try {
      setLoader(true);
      emailjs.init("Gz8bCiInS6AmCWzqV");
      const templateParams = {
        to_email: "info@globalcitizenportal.com",
        from_name: userData.name,
        from_email: userData.email,
        message: userData,
        cc: "data@idigitalise.co.in",
      };

      const response = await emailjs.send(
        "service_b90ca28",
        "template_vtr90em",
        templateParams
      );
      message.success("Request Submitted Successfully");

      setTimeout(() => {
        navigate(ROUTES.LANDINGTHANKYOU);
      }, 2000);
      setTimeout(() => {
        window.location.reload();
      }, 8000);
      setLoader(false);
    } catch (error) {
      handleFieldError(error);
    }
  };

  useEffect(() => {
    const tagManagerArgs = {
      gtmId: "GTM-NWDJG6ZP",
    };
    TagManager.initialize(tagManagerArgs);

    if (window.fbq) {
      window.fbq("init", "2544791289127584");
      window.fbq("track", "PageView");
    }
  }, []);

  useEffect(() => {
    form.setFieldValue("countryCode", location?.country);
  }, [form, location?.country]);

  return (
    <LandingPageContainer>
      <div className="main-container">
        <div className="left-section">
          <div className="background" />
          {/* <div className="content-container">
            <h1>Get Consultation for Tourists & Business Visas</h1>
            <div className="points-container">
              <div className="point">
                <div className="list-type">
                  <FaGlobeEurope />
                </div>
                <div className="list-content">
                  UK's Top Rated Visa Consultancy Agency
                </div>
              </div>
              <div className="point">
                <div className="list-type">
                  <FaArrowsAltH />
                </div>
                <div className="list-content">
                  End-to-End & Personalized Visa Consultation
                </div>
              </div>
              <div className="point">
                <div className="list-type">
                  <AiOutlineSafety />
                </div>
                <div className="list-content">
                  100% Safety & Confidentiality
                </div>
              </div>
            </div>
            <StyledButton className="top-button" htmlType="submit">
              At Global Citizen Portal <FaLongArrowAltRight />
            </StyledButton>
          </div> */}
          {/* <img
            src="../Images/banner-desktop.jpg"
            alt="banner"
            className="desktop-image"
          />
          <img
            src="../Images/banner-mobile.jpg"
            alt="banner"
            className="mobile-image"
          /> */}
          <img
            src="../Images/banner-desktop-portugal.png"
            alt="banner"
            className="desktop-image"
          />
          <img
            src="../Images/banner-mobile-portugal.png"
            alt="banner"
            className="mobile-image"
          />
        </div>
        <div className="right-section">
          <div className="logo">
            <img
              src="../Images/Global_Citizen_Portal_Logo.png"
              alt="Logo"
              className="images"
            />
          </div>
          <Form
            className="form"
            validateTrigger={"onBlur"}
            form={form}
            onFinish={onFinish}
          >
            <h3 className="heading">
              Get Personalized Consultation For Tourists & Business Visas
            </h3>
            <div className="input-select-container">
              <Form.Item
                name="name"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    max: 50,
                    message: "Maximum 50 characters Allowed",
                  },
                  {
                    pattern: new RegExp(/^[A-Za-z\s]+$/),
                    message: "Must be a valid name",
                  },
                ]}
              >
                <StyledInput
                  type="text"
                  placeholder="Full Name"
                  className="input"
                  maxLength={50}
                />
              </Form.Item>
              <Form.Item
                name="email"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                  {
                    type: "email",
                    message: "Must be valid Email",
                  },
                ]}
              >
                <StyledInput
                  type="text"
                  placeholder="Email Address"
                  className="input"
                />
              </Form.Item>{" "}
              <div className="phone-country-select">
                <div className="country-select">
                  <CountrySelect size="large" labels={en} isRequired={true} />
                </div>
                <Form.Item
                  style={{ width: "100%" }}
                  rules={[
                    {
                      required: true,
                      message: "",
                    },
                    {
                      min: 6,
                      message: "Minimum 6 digits required",
                    },
                    {
                      max: 15,
                      message: "Phone Number Can't be more than 15 digits",
                    },
                  ]}
                  name="mobileNo"
                >
                  <StyledInput
                    type="number"
                    placeholder="Phone Number"
                    className="input"
                    onKeyDown={(e) => {
                      if (restrictedKeys.includes(e?.key)) {
                        e.preventDefault();
                        return;
                      }
                    }}
                    value={form.getFieldValue("mobileNo")}
                    onChange={handlePhoneInputChange}
                    min={0}
                  />
                </Form.Item>
              </div>
              <Form.Item
                name="question"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: "",
                  },
                ]}
                className="help-with"
              >
                <Select
                  type="text"
                  placeholder="Want Consultation For?"
                  className="select input"
                  options={[
                    {
                      label: "Portugal's Work Permit Visa",
                      value: "Portugal's_Work_Permit_Visa",
                    },
                    {
                      label: "Potugal's Seasonal Work Visa",
                      value: "Potugal's_Seasonal_Work_Visa",
                    },
                    {
                      label: "Portugal's Golden Visa",
                      value: "Portugal's_Golden_Visa",
                    },
                    {
                      label: "Portugal's Digital Nomad Visa",
                      value: "Portugal's_Digital_Nomad_Visa",
                    },
                  ]}
                />
              </Form.Item>
            </div>
            <StyledButton
              className="button"
              htmlType="submit"
              loading={loader}
              id="ukSubmitButton"
            >
              Submit Form To Get In Touch
            </StyledButton>
            <div className="points-container">
              <div className="point">
                <div className="list-type">
                  <Plane />
                </div>
                <div className="list-content">
                  Europe's Top Rated Visa Consultancy Agency
                </div>
              </div>
              <div className="point">
                <div className="list-type">
                  <Plane />
                </div>
                <div className="list-content">
                  End-to-End & Personalized Visa Consultation
                </div>
              </div>
              <div className="point">
                <div className="list-type">
                  <Plane />
                </div>
                <div className="list-content">
                  100% Safety & Confidentiality
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="footer">
        <div className="content">Copyright © 2024 Global Citizen Portal</div>
      </div>
    </LandingPageContainer>
  );
};

export default LandingPage;
