import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Input, Select, Space } from "antd";
import { StyledButton } from "../../../Screens/ApplicationForm/Components/OrderInformation/OrderInformation.styles";
import { AddVisaCategoryModal } from "./AddVisaCategories.styles";
import { handleFieldError, restrictedKeys } from "../../../Utils/Common";
import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { useGetAllCountriesMutation } from "../../../Services/Users";
import { useGetVisaCategoryDetailsQuery } from "../../../Services/Visa";

const AddVisaCategory = ({
  handleOk,
  isModalOpen,
  handleCancel,
  editId,
  newCategoriesDetail,
  form,
}) => {
  const [countries, setCountries] = useState([]);
  const [getAllCountries] = useGetAllCountriesMutation();

  const handleModalCancel = () => {
    handleCancel();
    form.resetFields();
  };

  const { data: visaCategoryDetails } = useGetVisaCategoryDetailsQuery(editId, {
    skip: editId !== null ? false : true,
  });

  const getAllCountriesFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: 300,
        current: 0,
      };
      const response = await getAllCountries(reqData).unwrap();
      let countriesList = response?.data?.data?.map((item, i) => ({
        key: i,
        label: item?.name,
        value: item?.id,
      }));
      if (
        editId === null &&
        newCategoriesDetail &&
        newCategoriesDetail.length > 0
      ) {
        const countryIdsToExclude = newCategoriesDetail.map(
          (item) => item.countryId
        );
        countriesList = countriesList.filter(
          (country) => !countryIdsToExclude.includes(country.value)
        );
      }

      setCountries(countriesList);
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllCountries, editId, newCategoriesDetail]);

  useEffect(() => {
    getAllCountriesFn();
  }, [getAllCountriesFn]);

  useEffect(() => {
    if (editId !== null && visaCategoryDetails) {
      form.setFieldsValue({
        countryId: visaCategoryDetails?.data?.countryId,
      });

      const visaCategories = visaCategoryDetails?.data?.visaCategories?.map(
        (category, index) => ({
          name: category?.name,
          description: category?.description,
          visaFee: category?.visaFee,
          serviceCharge: category?.serviceCharge,
          processDuration: category?.processDuration,
          validity: category?.validity,
          requiredDocument: category?.requiredDocument?.map((document) => ({
            document,
          })),
          eligibilityCriteria:
            category?.eligibilityCriteria?.length > 0
              ? category?.eligibilityCriteria?.map((criteria) => ({
                  criteria,
                }))
              : [""],
        })
      );
      form.setFieldsValue({ visaCategories });
    }
  }, [visaCategoryDetails, form, editId]);

  return (
    <AddVisaCategoryModal
      onCancel={handleModalCancel}
      open={isModalOpen}
      footer={null}
      destroyOnClose={true}
      width={640}
      style={{ top: 20 }}
    >
      <div className="heading">{editId ? `Update` : "Add"} Visa Category</div>
      <Form className="main-container" onFinish={handleOk} form={form}>
        <Form.Item
          name="countryId"
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Please select a country" }]}
        >
          <Select
            showSearch
            placeholder="Select Country"
            filterOption={(input, option) =>
              (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
            }
            options={countries}
          />
        </Form.Item>

        <Form.List name="visaCategories" initialValue={[{}]}>
          {(fields, { add: addVisaCategory, remove: removeVisaCategory }) => (
            <>
              {fields.map((field, visaCategoryIndex) => (
                <div key={field.key} style={{ width: "100%" }}>
                  <div className="grid-container">
                    <Form.Item
                      name={[field.name, `name`]}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter visa category name",
                        },
                      ]}
                    >
                      <Input placeholder="Visa Category" className="input" />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, `description`]}
                      style={{ width: "100%" }}
                      rules={[
                        { required: true, message: "Please enter description" },
                      ]}
                    >
                      <Input placeholder="Description" className="input" />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, `visaFee`]}
                      style={{ width: "100%" }}
                      rules={[{ required: true, message: "Please enter fee" }]}
                    >
                      <Input
                        placeholder="Fee"
                        className="input"
                        type="number"
                        onKeyDown={(e) => {
                          if (restrictedKeys.includes(e?.key)) {
                            e.preventDefault();
                          }
                        }}
                        max={100000}
                        maxLength={6}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, `serviceCharge`]}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter Service Charge",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Service Charge"
                        className="input"
                        type="number"
                        onKeyDown={(e) => {
                          if (restrictedKeys.includes(e?.key)) {
                            e.preventDefault();
                          }
                        }}
                        max={100000}
                        maxLength={6}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, `processDuration`]}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter processing time",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Processing Time"
                        className="input"
                        type="number"
                        onKeyDown={(e) => {
                          if (restrictedKeys.includes(e?.key)) {
                            e.preventDefault();
                          }
                        }}
                        max={100000}
                        maxLength={6}
                      />
                    </Form.Item>
                    <Form.Item
                      name={[field.name, `validity`]}
                      style={{ width: "100%" }}
                      rules={[
                        {
                          required: true,
                          message: "Please enter visa validity",
                        },
                      ]}
                    >
                      <Input
                        placeholder="Visa Validity"
                        className="input"
                        type="number"
                        onKeyDown={(e) => {
                          if (restrictedKeys.includes(e?.key)) {
                            e.preventDefault();
                          }
                        }}
                        max={100000}
                        maxLength={6}
                      />
                    </Form.Item>
                    <Form.List
                      name={[field.name, `requiredDocument`]}
                      initialValue={[{}]}
                    >
                      {(
                        documents,
                        { add: addDocument, remove: removeDocument }
                      ) => (
                        <div className="document-grid-container">
                          {documents.map((docField, docIndex) => (
                            <Space key={docField.key} align="baseline">
                              <Form.Item
                                name={[docField.name, "document"]}
                                style={{ flex: 1 }}
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter document name",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={`Document ${docIndex + 1} Name`}
                                />
                              </Form.Item>
                              {docIndex === documents.length - 1 && (
                                <PlusCircleOutlined
                                  onClick={() => addDocument()}
                                />
                              )}
                              {documents.length > 1 && (
                                <MinusCircleOutlined
                                  onClick={() =>
                                    removeDocument(docIndex, visaCategoryIndex)
                                  }
                                />
                              )}
                            </Space>
                          ))}
                        </div>
                      )}
                    </Form.List>
                    <Form.List
                      name={[field.name, `eligibilityCriteria`]}
                      initialValue={[{}]}
                    >
                      {(
                        documents,
                        { add: addCriteria, remove: removeCriteria }
                      ) => (
                        <div className="document-grid-container">
                          {documents.map((docField, docIndex) => (
                            <Space key={docField.key} align="baseline">
                              <Form.Item
                                name={[docField.name, "criteria"]}
                                style={{ flex: 1 }}
                                rules={[
                                  {
                                    required: true,
                                    message:
                                      "Please enter Eligibility Criteria",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder={`Eligibility Criteria ${
                                    docIndex + 1
                                  }`}
                                />
                              </Form.Item>
                              {docIndex === documents.length - 1 && (
                                <PlusCircleOutlined
                                  onClick={() => addCriteria()}
                                />
                              )}
                              {documents.length > 1 && (
                                <MinusCircleOutlined
                                  onClick={() =>
                                    removeCriteria(docIndex, visaCategoryIndex)
                                  }
                                />
                              )}
                            </Space>
                          ))}
                        </div>
                      )}
                    </Form.List>
                  </div>
                  <div className="button-container">
                    {fields.length > 1 && (
                      <Button
                        className="remove-btn"
                        onClick={() => removeVisaCategory(visaCategoryIndex)}
                      >
                        Remove
                      </Button>
                    )}
                    {visaCategoryIndex === fields.length - 1 && (
                      <Form.Item>
                        <Button
                          type="dashed"
                          className="add-btn"
                          onClick={() => addVisaCategory()}
                        >
                          Add Category
                        </Button>
                      </Form.Item>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </Form.List>

        <StyledButton className="button" htmlType="submit">
          {editId ? "Update" : "Add"}
        </StyledButton>
      </Form>
    </AddVisaCategoryModal>
  );
};
export default AddVisaCategory;
