export const ROUTES = {
  HOME: "/",
  ALLVISA: "/visa-services/all-visa",
  CHECKSTATUS: "/visa-services/check-visa-status",
  VISAPROCESS: "/visa-services/visa-process",
  FAQ: "/faq",
  ABOUTUS: "/about-us",
  CONTACTUS: "/contact-us",
  COUNTRYDETAILS: "/country-details",
  APPLICATIONFORM: "/application-form",
  LOGIN: "/auth/login",
  SIGNUP: "/auth/sign-up",
  SIGNUP2: "/auth/sign-up2",
  FORGOTPASSWORD: "/auth/forgot-password",
  VALIDATEOTP: "/auth/validate-otp",
  RESETPASSWORD: "/auth/reset-password",
  DASHBOARD: "/admin/dashboard",
  APPLICATION: "/admin/application",
  USERS: "/admin/users",
  VISAS: "/admin/visas",
  COUNTRIES: "/admin/countries",
  VISACATEGORIES: "/admin/visa-categories",
  TRAVELINSURANCE: "/admin/travel-insurance",
  ADMINCONTACTUS: "/admin/contact-us",
  FAQs: "/admin/FAQs",
  ORDERS: "/user/orders",
  PROFILE: "/user/profile",
  TRANSACTIONCONFIRM: "/transaction/success",
  TRANSACTIONFAILED: "/transaction/cancel",
  LANDINGPAGE: "/landing-page/portuguese-visa",
  LANDINGPAGEUK: "/landing-page/uk-visa",
  LANDINGTHANKYOU: "/landing-page/thank-you",
};
