import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Spin } from "antd";
import { ROUTES } from "../Constants/Routes";
import HomePage from "../Screens/HomePage/HomePage";
import AllVisa from "../Screens/AllVisa/AllVisa";
import CheckStatus from "../Screens/CheckStatus/CheckStatus";
import VisaProcess from "../Screens/VisaProcess/VisaProcess";
import ContactUs from "../Screens/ContactUs/ContactUs";
import FAQs from "../Screens/FAQs/FAQs";
import AboutUs from "../Screens/AboutUs/AboutUs";
import CountryDetails from "../Screens/CountryDetails/CountryDetails";
import ApplicationForm from "../Screens/ApplicationForm/ApplicationForm";
import Login from "../Screens/Login/Login";
import Dashboard from "../Screens/Dashboard/Dashboard";
import ResetPassword from "../Screens/ResetPassword/ResetPassword";
import ForgotPassword from "../Screens/ForgotPassword/ForgotPassword";
import VerifyOTP from "../Screens/VerifyOtp/VerifyOtp";
import { PublicRoute } from "../Utils/PublicRoute";
import { PrivateRoute } from "../Utils/PrivateRoute";
import TransactionConfirm from "../Screens/TransactionConfirm/TransactionConfirm";
import TransactionFailed from "../Screens/TransactionFailed/TransactionFailed";
import NotFound from "../Screens/NotFound/NotFound";
import Signup2 from "../Screens/Signup2/Signup2";
import LandingPage from "../Screens/LandingPage/LandingPage";
import LandingPageUK from "../Screens/LandingPageUK/LandingPage";
import LandingThanks from "../Common/Components/LandingThanks/LandingThanks";

const AppRoutes = () => {
  return (
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <Spin size="large" />
        </div>
      }
    >
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path={ROUTES.HOME} element={<HomePage />} />
        <Route path={ROUTES.ALLVISA} element={<AllVisa />} />
        <Route path={ROUTES.CHECKSTATUS} element={<CheckStatus />} />
        <Route path={ROUTES.VISAPROCESS} element={<VisaProcess />} />
        <Route path={ROUTES.CONTACTUS} element={<ContactUs />} />
        <Route path={ROUTES.FAQ} element={<FAQs />} />
        <Route path={ROUTES.ABOUTUS} element={<AboutUs />} />
        <Route
          path={`${ROUTES.COUNTRYDETAILS}/:country/:countryId`}
          element={<CountryDetails />}
        />
        <Route path={ROUTES.APPLICATIONFORM} element={<ApplicationForm />} />
        <Route
          path={`${ROUTES.TRANSACTIONCONFIRM}/:id`}
          element={<TransactionConfirm />}
        />
        <Route
          path={`${ROUTES.TRANSACTIONFAILED}/:id`}
          element={<TransactionFailed />}
        />
        <Route element={<PrivateRoute />}>
          <Route path={ROUTES.DASHBOARD} element={<Dashboard />} />
          <Route path={ROUTES.APPLICATION} element={<Dashboard />} />
          <Route path={ROUTES.USERS} element={<Dashboard />} />
          <Route path={ROUTES.VISAS} element={<Dashboard />} />
          <Route path={ROUTES.ORDERS} element={<Dashboard />} />
          <Route path={ROUTES.PROFILE} element={<Dashboard />} />
          <Route path={ROUTES.ADMINCONTACTUS} element={<Dashboard />} />
          <Route path={ROUTES.COUNTRIES} element={<Dashboard />} />
          <Route path={ROUTES.VISACATEGORIES} element={<Dashboard />} />
          <Route path={ROUTES.TRAVELINSURANCE} element={<Dashboard />} />
          <Route path={ROUTES.FAQs} element={<Dashboard />} />
        </Route>
        <Route element={<PublicRoute />}>
          <Route path={ROUTES.LOGIN} element={<Login />} />
          {/* <Route path={ROUTES.SIGNUP} element={<Signup />} /> */}
          <Route path={ROUTES.SIGNUP} element={<Signup2 />} />
          <Route path={ROUTES.FORGOTPASSWORD} element={<ForgotPassword />} />
          <Route path={ROUTES.VALIDATEOTP} element={<VerifyOTP />} />
          <Route path={ROUTES.RESETPASSWORD} element={<ResetPassword />} />
          <Route path={ROUTES.LANDINGPAGE} element={<LandingPage />} />
          <Route path={ROUTES.LANDINGPAGEUK} element={<LandingPageUK />} />
          <Route path={ROUTES.LANDINGTHANKYOU} element={<LandingThanks />} />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
