import React, { useCallback, useEffect, useState } from "react";
import { CommonVisaContainer, StyledButton } from "./CommonVisa.styles";
import { Carousel } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../Constants/Routes";
import CommonVisasCard from "../../../../Common/Cards/CommonVisasCard/CommonVisasCard";
import { useGetAllCountriesMutation } from "../../../../Services/Users";
import { handleFieldError } from "../../../../Utils/Common";

const CommonVisa = () => {
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [getAllCountries] = useGetAllCountriesMutation();

  const getAllCountriesFn = useCallback(async () => {
    try {
      const reqData = {
        pageSize: 300,
        current: 0,
      };
      const response = await getAllCountries(reqData).unwrap();
      setCountries(response?.data?.data);
    } catch (error) {
      handleFieldError(error);
    }
  }, [getAllCountries]);

  useEffect(() => {
    getAllCountriesFn();
  }, [getAllCountriesFn]);
  return (
    <CommonVisaContainer>
      <h2 className="common-visa-heading">
        Most Common <span className="diff">Visas</span>
      </h2>
      <Carousel autoplay={true} autoplaySpeed={5000} dots={false}>
        <div className="card-container">
          {countries?.map((item, i) => {
            if (i < 3) {
              return <CommonVisasCard item={item} />;
            }
          })}
        </div>
        <div className="card-container">
          {countries?.map((item, i) => {
            if (i > 2 && i < 6) {
              return <CommonVisasCard item={item} />;
            }
          })}
        </div>
      </Carousel>
      <div className="button-container">
        <StyledButton onClick={() => navigate(ROUTES.ALLVISA)}>
          View More Country!
        </StyledButton>
      </div>
    </CommonVisaContainer>
  );
};

export default CommonVisa;
