import styled from "styled-components";

export const CommonVisasCardContainer = styled.div`
  height: 100%;
  width: 280px;
  background: ${(props) => props.theme.colors.white};
  box-shadow: ${(props) => props.theme.shadow.cardShadow2};
  border-radius: 14px;
  cursor: pointer;
  &:hover {
    transform: translateY(-8px);
  }
  .image-container {
    .card-image {
      height: 100% !important;
      width: 100% !important;
      border-top-right-radius: 14px;
      border-top-left-radius: 14px;
    }
  }
  .card-text {
    text-align: center;
    font-weight: 700;
    font-size: 16px;
    line-height: 150%;
    color: ${(props) => props.theme.colors.black};
    padding: 4px 0px 8px 0px;
  }
`;
