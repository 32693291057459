import styled from "styled-components";

export const VisaCategoriesContainer = styled.div`
  .ant-table-expanded-row .ant-table-cell {
    vertical-align: top;
  }
  .ant-table-cell > ul {
    margin: 0;
    li {
      text-align: left;
    }
  }
`;
